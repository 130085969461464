








































import { Component, Vue } from 'vue-property-decorator';
import { DataStateChangeEventArgs } from "@syncfusion/ej2-vue-grids";
import { Predicate } from '@syncfusion/ej2-data';
import { ClickEventArgs } from '@syncfusion/ej2-vue-navigations';
import CFMGridPage from '@/scripts/CFMGridPage'

import * as swal from '@/helpers/swal'
import * as blockui from '@/helpers/block-ui'

import { CFMDTOSparePart, CFMModelSparePartView } from '@/scripts/services/ClientApiAuto'
import SparePartDialog from './SparePartDialog.vue';
import SparePartDetailDialog from './SparePartDetailDialog.vue'

@Component<SparePart>({
    components: {
        'spare-part-dialog': SparePartDialog,
        'spare-part-detail-dialog':SparePartDetailDialog
    }
})
export default class SparePart extends CFMGridPage<CFMModelSparePartView> {
    entityType = "SparePart";
    sorts:{field:string,direction:string}[] = [{ field:"Id", direction:"Descending" }];    
    entityState: DataStateChangeEventArgs = {where:[new Predicate('isActive', 'equal', true, false)]};
    groupOptions= { columns: ['Name'] };

    isDetailDialogActive = false;

    async created(){
        this.toolbar = [
            {
                text: 'Excel Export', tooltipText: 'Excel Export', prefixIcon: 'e-excelexport', id: 'ExcelExport'
            }, 
            {
                text: 'CSV Export', tooltipText: 'CSV Export', prefixIcon: 'e-csvexport', id: 'CSVExport'
            }, 
            {
                text: 'PDF Export', tooltipText: 'PDF Export', prefixIcon: 'e-pdfexport', id: 'PDFExport'
            }, 
            {
                text: this.translate('add'), tooltipText: this.translate('add'), prefixIcon: 'e-add', id: 'Add',
            }, 
            {
                text: this.translate('edit'), tooltipText: this.translate('edit'), prefixIcon: 'e-edit', id: 'Edit',
            }, 
            {
                text: this.translate('hide'), tooltipText: this.translate('hide'), prefixIcon: 'e-eye-slash', id: 'Hide',
            },
            {
                text: this.translate('show'), tooltipText: this.translate('show'), prefixIcon: 'e-preview', id: 'Show',
            },
            {
                text: this.translate('task_id'), tooltipText: this.translate('task_id'), prefixIcon: 'e-icon-grightarrow', id: 'TaskId',
            }
        ];
    }
    async mounted() {
        this.dataStateChange(this.state);
    }

    async dataStateChange(state: DataStateChangeEventArgs) {
        this.state = state;
        const isFilter = !!state && !!state.action && (state.action.requestType === 'filterchoicerequest' || <string>state.action.requestType === 'filtersearchbegin');
        if (isFilter) {
            let data = await this.getData(state, isFilter);
            state.dataSource && state.dataSource(data);
        } else {
            const data = await this.getData(state, isFilter);
            this.groupOptions.columns.push('Name');
            this.gridDataSource = data.result;
            return this.gridDataSource;
        }
    }

    refreshGrid(){
        this.dataStateChange(this.state);
    }

    rowDataBound(args: any){
        if (args.data['StockCount'] <= 100) {
            if (Number(args.data['StockCount']) == 0) {
                args.row.classList.add('bold-red-bg');
                return;
            }
            else if(args.data['StockCount'] <= args.data['CriticalStockCount']){
                args.row.classList.add('red-bg');
            }
            else if(args.data['StockCount'] <= 50){
                args.row.classList.add('orange-bg');
            }
            else{
                return;
            }
        } 
        return;
    }


    async add(){
        this.selectedId = null;
        this.isDialogActive = true;
        await this.$nextTick();
        (<SparePartDialog>this.$refs.sparePartDialog).showDialog();
    }

    async edit(id: number){
        this.selectedId = id;
        this.isDialogActive = true;
        await this.$nextTick();
        (<SparePartDialog>this.$refs.sparePartDialog).showDialog();
    }

    async delete(data: CFMDTOSparePart){
        const response = await this.apiClient.sparePart.sparePartDelete({id: data.Id});
        if (response.data.IsSuccess) {
            toastr.success(this.translate('delete_successfully'));
            this.refreshGrid();
        } else {
            const errorMessage = response.data.Message ? response.data.Message : "Error";
            toastr.error(errorMessage);
        }
        blockui.unBlockPage();
    }

    recordDoubleClick(args:any){
        this.edit(args.rowData.Id);
    }

    async toolbarClick(args: ClickEventArgs){
        var selectedRecords = (<any>this.$refs.grid).getSelectedRecords();
        if (args.item.id === 'Edit') {
            if(selectedRecords.length > 0){
                this.edit(selectedRecords[0].Id);
            }
        }
        else if(args.item.id === 'Add'){
               this.add();
        }
        else if(args.item.id === 'Delete'){
            if(selectedRecords.length > 0){
                this.delete(selectedRecords[0]);
            }
        }
        else if(args.item.id === 'Hide'){ 
            this.entityState.where?.push(new Predicate('StockCount','greaterthan',0,false))
            this.dataStateChange(this.state);           
        }
        else if(args.item.id === 'Show'){ 
            // TODO:entity state ile yönetilmeli
            window.location.reload()
            // if (this.entityState.where) {
            //     for (let index = 0; index < this.entityState.where.length; index++) {
            //         const element = this.entityState.where[index];
            //         if (element.field == 'StockCount') {
            //             this.entityState.where.pop();
            //         }
            //     }
            // }
            // this.dataStateChange(this.state);           
        }
        else if(args.item.id === 'TaskId'){ 
            alert('Task Id Button');
        }
      this.toolbarClickBase(args);
    }

    async goToDetail(id:number){
        this.selectedId = id;
        this.isDetailDialogActive = true;
        await this.$nextTick();
        (<SparePartDetailDialog>this.$refs.sparePartDetailDialog).showDialog();
    }
    commandTemplate() {
        const parentComponent = this;
        return {
            template: Vue.component("columnTemplate", {
                template: `
                    <div>
                        <button @click="openDetail" :title="translate('detail')" class="e-lib e-btn e-control e-icon-btn">
                            <span class="e-btn-icon e-icons e-preview-icon"></span>
                        </button>
                    </div>
                    `,
                methods: {
                    openDetail() {
                        const self = <any>this;
                        parentComponent.goToDetail(self.data.Id);
                    },
                },
            }),
        };
    }
}
