



















































































































import CFMDialog from '@/scripts/CFMDialog'
import { Component, Prop } from 'vue-property-decorator';
import * as swal from '@/helpers/swal'
import * as blockui from '@/helpers/block-ui'
import HistoryStep from './HistoryStep.vue'
import HistoryStep2 from './HistoryStep2.vue'
import { mixinz } from '@/scripts/global/mixinz';
import CommentStep from './CommentStep.vue'

import { CFMDTOSparePart } from '@/scripts/services/ClientApiAuto'
import SparePartDialog from './SparePartDialog.vue';

@Component<SparePartDetailDialog>({
    mixins: [mixinz],
    components: {
        'spare-part-dialog': SparePartDialog,
        'history-step': HistoryStep2,
        'comment-step': CommentStep,
    },
})

export default class SparePartDetailDialog extends CFMDialog {
    sparePart: CFMDTOSparePart = {
        Type:
            {
                Name:'', 
                Category:{Name:''},
                Kind:{Name:''},
                SubCategory: { Name:''}
            },
        Brand:{Name:''},
        Model:{Name:''},
        SparePartWarehouses: []
        }
    stockCount = 0
    async created(){
        let response = await this.$globalApiClient.sparePart.sparePartGetDetails({id: <number>this.id});
        this.sparePart = response.data;
    }

    async updateStock(){
        let formData = {
            SparePartWarehouses: this.sparePart.SparePartWarehouses ? this.sparePart.SparePartWarehouses  : [],
        };
        const response = await this.$globalApiClient.sparePart.updateSparePartStock(formData.SparePartWarehouses);
        if (response.data.IsSuccess) {
            toastr.success(this.translate('successfully_update_stock'));
            this.saveSucceeded();
            this.close();
        } else {
            const errorMessage = response.data.Message ? response.data.Message : "Error";
            toastr.error(errorMessage);
        }
        blockui.unBlockPage();
    }
    async transferStock(){
        this.close();
        this.$router.push(
            {
                name:'SparePartTransfer', 
                query:{
                    sparePartId: String(this.id)
                }
        });
    }
}
