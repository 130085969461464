import * as api from '../../scripts/services/api';
import * as dateFormat from '../../helpers/dateFormat';
// initial state
const state = {
  procedureTypes: [],
  formStatuses: [],
  formProcessStatuses: [],
  formQuestionTypes: [],
  formList: [],
  formListForFilter: [],
  formProcessList: [],
  questionTypes: [],
  formSections: [],
  formSectionList: [],
  formQuestionTypeComparisonOperatorList: [],
  formStatusList: [],
};

// getters
const getters = {
  // #region dropdown
  getProcedureTypes: (state) => state.procedureTypes.map((x) => ({ Id: x.Value, Name: x.Text })),
  getFormStatuses: (state) => state.formStatuses.map((x) => ({ Id: x.Value, Name: x.Text })),
  getFormProcessStatuses: (state) => state.formProcessStatuses.map((x) => ({ Id: x.Value, Name: x.Text })),
  getFormQuestionTypes: (state) => state.formQuestionTypes.map((x) => ({ Id: x.Value, Name: x.Text })),
  getFormSections: (state) => state.formSections.map((x) => ({ Id: x.Value, Name: x.Text })),
  // #endregion dropdown
};

// actions
const actions = {
  // #region dropdown
  async getProcedureTypes({ commit }) {
    await api.getProcedureTypes().then((res) => {
      commit('setProcedureTypes', res.body);
    });
  },
  async getFormStatuses({ commit }) {
    await api.getFormStatuses().then((res) => {
      commit('setFormStatuses', res.body);
    });
  },
  async getFormProcessStatuses({ commit }) {
    await api.getFormProcessStatuses().then((res) => {
      commit('setFormProcessStatuses', res.body);
    });
  },
  async getFormQuestionTypes({ commit }) {
    await api.getFormQuestionTypes().then((res) => {
      commit('setFormQuestionTypes', res.body);
    });
  },
  async getFormSections({ commit }, procedureTypeId) {
    await api.getFormSections({ procedureTypeId }).then((res) => {
      commit('setFormSections', res.body);
    });
  },
  // #endregion dropdown

  // #region list
  async getFormListForFilter({ commit }, data) {
    await api.getFormListForFilter(data).then((res) => {
      commit('setFormListForFilter', res.body);
    });
  },
  async getFormList({ commit }, data) {
    await api.getFormList(data).then((res) => {
      commit('setFormList', res.body);
    });
  },
  async getFormProcessList({ commit }, data) {
    await api.searchFormProcess(data).then((res) => {
      commit('setFormProcessList', res.body.Data);
    });
  },
  async getFormSectionList({ commit }, procedureTypeId) {
    return await api.getFormSectionList({ procedureTypeId }).then((res) => {
      commit('setFormSectionList', res.body);
    });
  },
  async getFormQuestionTypeComparisonOperators({ commit }) {
    await api.getFormQuestionTypeComparisonOperatorList().then((res) => {
      commit('setFormQuestionTypeComparisonOperators', res.body);
    });
  },
  async getFormStatusList({ commit }) {
    await api.getFormStatusList().then((res) => {
      commit('setFormStatusList', res.body.Data);
    });
  },
  // #endregion list

  async getPrintItem({ state, commit, rootState }, { formId, self }) {
    let selectedForm = null;
    let operandTypes = [];
    let campusLogoResponse = null;
    let approvalsInformation = null;
    let allFeedBacks = null;
    let hasFormFlag = false;
    let invalidAnswerCount = 0;
    const promises = [
      api.getFormWithProcess({
        workOrderId: self.workOrderId,
        formId,
        isIncludeAnswerCondition: true,
        isIncludePoint: true,
      }),
      api.getOperandTypeList(),
      api.getCampusLogo(),
      api.getWorkOrderApprovalsInformation(
        formId,
        self.workOrderId,
      )
    ];
    const responses = await Promise.all(promises);
		selectedForm = responses[0].body; // Metin => responses[0].body.Data;
		operandTypes = responses[1].body; // responses[1].body.Data;
    campusLogoResponse = responses[2].body;
    approvalsInformation = responses[3].body.Data;

    allFeedBacks = selectedForm && selectedForm.FormProcesses && selectedForm.FormProcesses.length > 0 && selectedForm.FormProcesses[0].WorkOrderHtmlContents ? selectedForm.FormProcesses[0].WorkOrderHtmlContents : [];
    invalidAnswerCount = selectedForm && selectedForm.FormProcesses && selectedForm.FormProcesses.length > 0 ? selectedForm.FormProcesses[0].InvalidAnswerCount : 0;
    hasFormFlag = allFeedBacks?.filter((feedack) => {
      return feedack.Question == null
    }).length > 0 ? true : false;
    //console.log(formId, self.workOrderId);
    
    const frmQuestionType = rootState.enums.formQuestionType;

    const pdfTable = (params) => {
      const header = params.header.map((i) => ({
        fillColor: '#eeeeee',
        text: i.title,
        style: 'tableHeader',
        alignment: 'center',
      }));
      const body = params.body.map((i) => ({ text: i.value, style: 'tableField' }));
      return [
        {
          style: 'table',
          table: {
            headerRows: 1,
            widths: params.widths,
            body: [
              header,
              body,
            ],
          },
          layout: tableLayout,
        },
      ];
    };

    const pdfFormInformation = () => {
      return [
        {
          style: 'table',
          table: {
            widths: [50, 214, 214],
            body: [
              [
                {
                  style: 'tableField',
                  rowSpan: 3,
                  innerHeight: 50,
                  stack: [
                    {
                      image: campusLogoResponse.Data,
                      fit: [50, 50],
                      style: 'questionImg',
                    },
                  ],
                },
                {
                  text: `${self.translate('form_type')}: ${selectedForm.ProcedureType.Name}`,
                },
                {
                  text: `${self.translate('version')}: ${selectedForm.Version}`,
                },
              ],
              [
                '',
                {
                  text: `${self.translate('form_code')}: ${selectedForm.Code}`,
                },
                {
                  text: `${self.translate('name')}: ${selectedForm.Name}`,
                },
              ],
              [
                '',
                {
                  text: `${self.translate('form_description')}: ${selectedForm.Description ?? "-"}`,
                  //text: `${self.translate('form_description')}: ${selectedForm.Description === '' ? '-' : selectedForm.Description}`,
                },
                {
                  text: `${self.translate('form_started_date')}: ${selectedForm != null && selectedForm.ModifiedDate != null ? 
                    dateFormat.viewDateTimeFormat(selectedForm.ModifiedDate) : 
                    (selectedForm != null && selectedForm.ModifiedDate == null && selectedForm.RevisionDate != null) ? 
                      dateFormat.viewDateTimeFormat(selectedForm.RevisionDate) : 
                      ((selectedForm != null && selectedForm.ModifiedDate == null && selectedForm.RevisionDate == null && selectedForm.CreatedDate != null) ? 
                        dateFormat.viewDateTimeFormat(selectedForm.CreatedDate) : 
                        '-')}`,
                  // text: `${self.translate('form_started_date')}: ${(selectedForm.FormProcesses.length > 0 && selectedForm.FormProcesses[0].CreatedDate != undefined) ? 
                  //   dateFormat.viewDateTimeFormat(selectedForm.FormProcesses[0].CreatedDate) : 
                  //   '-'}`,
                },
              ],
              [
                {
                  text: `${self.translate('work_order_number')}: ${self.workOrderId}`
                },
                {
                  text: `${self.translate('number_of_flag')}: ${selectedForm.FormProcesses.length > 0 ? selectedForm.FormProcesses[0].WorkOrderHtmlContents.length : 0}`,
                },
                {
                  //text: `${self.translate('number_of_abnormal_answers')}: ${selectedForm.FormProcesses.length > 0 ? selectedForm.FormProcesses[0].InvalidAnswerCount : 0}`
                  text: `${self.translate('number_of_abnormal_answers')}: ${invalidAnswerCount}`
                },
              ],
            ]
          },
        },
      ];
    };

    const pdfStaffVendorInformation = () => { 
      let widths = [];
      let body = [];
      const colCount = 2 + approvalsInformation.ApprovalVendors.length;
      const colWidth = 460 / colCount;

      for (let i = 0; i < colCount; i++) {
        widths.push(colWidth);
      }
      console.log(widths);

      // header row
      let headerRow = [];
      // first row
      let nameSurnameRoleNameRow = [];
      
      // second row
      let dateRow = [];

      // third row
      let signatureRow = [];

      // first column
      headerRow.push('İşi Yapan Kişi (Staff)');
      nameSurnameRoleNameRow.push({
        text: `${approvalsInformation.ApprovalStaff.NameSurname} - ${approvalsInformation.ApprovalStaff.RoleName}`,
      });
      dateRow.push({
        text: (approvalsInformation.ApprovalStaff.ApprovalDate != null ? `${dateFormat.viewDateTimeFormat(approvalsInformation.ApprovalStaff.ApprovalDate)}` : `-`),
      });
      signatureRow.push('');
      
      // middle columns
      
      approvalsInformation.ApprovalVendors.forEach((val, index)=>{
        headerRow.push(`İşi Yapan Kişi (Vendor) (${index + 1})`);
        nameSurnameRoleNameRow.push({
          text: `${val.NameSurname} - ${val.VendorName}`,
        });
        dateRow.push({
          text: (val.ApprovalDate != null ? `${dateFormat.viewDateTimeFormat(val.ApprovalDate)}` : `-`),
        });
        signatureRow.push({
          innerHeight: 100,
          border: [true, true, true, true],
          stack: (val.VendorLogo != "" ? 
            [
              {
                image: val.VendorLogo,
                fit: [50, 100],
                style: 'questionImg',
              },
              {
                text: '',
                style: 'emptySpace',
              },
            ] : [
              
              {
                text: '',
                style: 'emptySpace2x',
              },
          ]
        )});
      });

      // last column
      headerRow.push('İşi Onaylayan Kişi (Staff)');
      nameSurnameRoleNameRow.push({
        text: `${approvalsInformation.ApprovedStaff.NameSurname} - ${approvalsInformation.ApprovedStaff.RoleName}`,
      });
      dateRow.push({
        text: (approvalsInformation.ApprovedStaff.ApprovalDate != null ? `${dateFormat.viewDateTimeFormat(approvalsInformation.ApprovedStaff.ApprovalDate)}` : `-`),
      });
      signatureRow.push('');
      
      body.push(headerRow);
      body.push(nameSurnameRoleNameRow);
      body.push(dateRow);
      body.push(signatureRow);
      return[
        {
          id: 'signatureSection',
          style: 'table',
          table: {
            widths: widths,
            body: body
          },
        },
      ];
      /*return[
          {
            style: 'table',
            table: {
              widths: [100, 180, 180],
              body: [
                [
                  'İşi Yapan Kişi (Staff)',
                  'İşi Yapan Kişi (Vendor)',
                  'İşi Onaylayan Kişi (Staff)',
                ],
                [
                  {
                    text: `${approvalsInformation.ApprovalStaff.NameSurname} - ${approvalsInformation.ApprovalStaff.RoleName}`,
                  },
                  {
                    text: `${approvalsInformation.ApprovalVendor.NameSurname} - ${approvalsInformation.ApprovalVendor.VendorName}`,
                  },
                  {
                    text: `${approvalsInformation.ApprovedStaff.NameSurname} - ${approvalsInformation.ApprovedStaff.RoleName}`,
                  },
                ],
                [
                  {
                    text: (approvalsInformation.ApprovalStaff.ApprovalDate != null ? `${dateFormat.viewDateTimeFormat(approvalsInformation.ApprovalStaff.ApprovalDate)}` : `-`),
                  },
                  {
                    text: (approvalsInformation.ApprovalVendor.ApprovalDate != null ? `${dateFormat.viewDateTimeFormat(approvalsInformation.ApprovalVendor.ApprovalDate)}` : `-`),
                  },
                  {
                    text: (approvalsInformation.ApprovedStaff.ApprovalDate != null ? `${dateFormat.viewDateTimeFormat(approvalsInformation.ApprovedStaff.ApprovalDate)}` : `-`),
                  },
                ],
                [
                  '',
                  {
                    innerHeight: 100,
                    border: [true, true, true, true],
                    stack: (approvalsInformation.ApprovalVendor.VendorLogo != "" ? 
                      [
                        {
                          image: approvalsInformation.ApprovalVendor.VendorLogo,
                          fit: [50, 100],
                          style: 'questionImg',
                        },
                        {
                          text: '',
                          style: 'emptySpace',
                        },
                      ] : [
                        
                        {
                          text: '',
                          style: 'emptySpace',
                        },
                    ]
                  )},
                  '',
                ],
              ]
            },
          },
        ];*/
    };

    const pdfTitle = (title, className, hasFlag) => [
      {
        text: [ `${title}` + ' ',
          hasFlag ?
          {
            text: '' + ' ',
            style: className || 'symbol10'
          } :
          {
            text: '',
            style: '' //className || 'header'
          }
        ],
        style: className || 'header',
      },
      {
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 5,
            x2: 585 - 2 * 40,
            y2: 5,
            lineWidth: 1,
            lineColor: '#576472',
          },
        ],
      },
      {
        text: '',
        style: 'emptySpace',
      },
    ];

    const pdfQuestionBr = () => [
      {
        canvas: [
          {
            type: 'line',
            x1: 10,
            y1: 5,
            x2: 585 - 2 * 40,
            y2: 5,
            lineWidth: 1,
            lineColor: '#ddd',
          },
        ],
      },
      {
        text: '',
        style: 'emptySpace',
      },
    ];

    const pdfQuestionTitle = (title, className, isExpectedValue) => [
      {
        text: title,
        style: className || 'questionTitle',
        decoration: isExpectedValue ? 'underline' : '',
      },

    ];

    let tableLayout = {
      hLineWidth(i, node) {
        return i === 0 || i === node.table.body.length ? 2 : 1;
      },
      vLineWidth(i, node) {
        return i === 0 || i === node.table.widths.length ? 2 : 1;
      },
      hLineColor(i, node) {
        return i === 0 || i === node.table.body.length
          ? '#576472'
          : '#576472';
      },
      vLineColor(i, node) {
        return i === 0 || i === node.table.widths.length
          ? '#576472'
          : '#576472';
      },
    };

    const _questionGroups = selectedForm.QuestionGroups;
    // files
    await Promise.all(_questionGroups.map(async (i) => {
      await Promise.all(i.Questions.map(async (i) => {
        if (i.Answer && i.Answer.fileId) {
          const img = await api.getFile({ id: i.Answer.fileId, isContainsData: true });
          i.Answer.fileSrc = `data:${img.body.Data.ContentType};base64,${img.body.Data.Base64}`;
        }
        if (i.ImageId) {
          const img = await api.getFile({ id: i.ImageId, isContainsData: true });
          i.imgSrc = `data:${img.body.Data.ContentType};base64,${img.body.Data.Base64}`;
        }
        return i;
      }));
    }));
    const formSectionNodes = [];
    let frmSections = null;
    await api.getFormSectionList({ procedureTypeId: selectedForm.ProcedureTypeId }).then((res) => {
      frmSections = res.body; // Metin => res.body.Data;
    });
    const answerConditionRecursiveMode = function (answerCondition, index) {
      const operandType = operandTypes.find((operandType) => operandType.Id === answerCondition.OperandTypeId);
      const array = [];
      if (answerCondition && answerCondition.AnswerConditionRules && answerCondition.AnswerConditionRules.length > 0) {
        for (const answerConditionRule of answerCondition.AnswerConditionRules) {
          if (answerConditionRule.ComparisonOperator.IsSecondValueNeeded) {
            array.push(`${answerConditionRule.ComparisonOperator.Name} ${answerConditionRule.FirstValue}, ${answerConditionRule.SecondValue}`);
          } else {
            array.push(`${answerConditionRule.ComparisonOperator.Name} ${answerConditionRule.FirstValue}`);
          }
        }
      }
      if (answerCondition && answerCondition.Children && answerCondition.Children.length > 0) {
        for (const child of answerCondition.Children) {
          array.push(answerConditionRecursiveMode(child, index + 1));
        }
      }

      let str = `\n${' '.repeat(index)}(`;
      str += array.join(` ${operandType.Name} `);
      return index - 1 > -1 ? `${str})` + `\n${' '.repeat(index - 1)}` : `${str})`;
    };
    const getQuestionById = function (id) {
      if (selectedForm && selectedForm.QuestionGroups) {
        for (const questionGroup of selectedForm.QuestionGroups) {
          if (questionGroup && questionGroup.Questions) {
            for (const question of questionGroup.Questions) {
              if (question.Id == id) {
                return question;
              }
            }
          }
        }
      }
      return null;
    };
    const getQuestionsCurrentProcess = function (currentQuestion) {
      if (currentQuestion && currentQuestion.QuestionProcess && currentQuestion.QuestionProcess.length > 0) {
        return currentQuestion.QuestionProcess[0];
      }
      return null;
    };
    const getQuestionsCurrentSelection = function (id) {
      const currentQuestion = getQuestionById(id);
      if (currentQuestion) {
        if (currentQuestion.AnswerChanged) {
          return currentQuestion.NewAnswer;
        } if (currentQuestion.AnswerExist) {
          return currentQuestion.Answer;
        } if (getQuestionsCurrentProcess(currentQuestion) && getQuestionsCurrentProcess(currentQuestion).AnswerProcesses) {
          const currentAnswerProcesses = getQuestionsCurrentProcess(currentQuestion).AnswerProcesses;
          if (currentQuestion.QuestionTypeId == frmQuestionType.multipleChoice) {
            const currentAnswer = currentAnswerProcesses[0];
            if (currentAnswer) {
              currentQuestion.Answer = currentAnswer.SelectionId;
              currentQuestion.AnswerExist = true;
              return currentAnswer.SelectionId;
            }
          } else if (currentQuestion.QuestionTypeId.QuestionTypeId == frmQuestionType.checkBox) {
            const currentAnswer = currentAnswerProcesses.map((x) => x.SelectionId);
            currentQuestion.Answer = currentAnswer;
            currentQuestion.AnswerExist = true;
            return currentAnswer;
          } else if (currentQuestion.QuestionTypeId == frmQuestionType.imageUpload || currentQuestion.QuestionTypeId == frmQuestionType.fileUpload) {
            let currentAnswer = currentAnswerProcesses.find((x) => x.FileId > 0);
            if (currentAnswer) {
              currentAnswer = currentAnswer.File;
              const file = {
                fileId: currentAnswer.Id,
                title: currentAnswer.Name,
                type: currentAnswer.ContentType,
                isActive: true,
                status: self.fileStatuses.uploaded,
                isLocal: false,
              };
              currentQuestion.Answer = file;
              currentQuestion.AnswerExist = true;
              return file;
            }
          } else {
            const currentAnswer = currentAnswerProcesses.find((x) => x.Value);
            if (currentAnswer) {
              currentQuestion.Answer = currentAnswer.Value;
              currentQuestion.AnswerExist = true;
              return currentAnswer.Value;
            }
          }
        } else {
          if (currentQuestion.QuestionTypeId == frmQuestionType.multipleChoice) {
            return {};
          } if (currentQuestion.QuestionTypeId == frmQuestionType.checkBox) {
            return [];
          } if (currentQuestion.QuestionTypeId == frmQuestionType.imageUpload || currentQuestion.QuestionTypeId == frmQuestionType.fileUpload) {
            return {};
          }
          return '';
        }
      }
      return null;
    };

    frmSections.forEach((formSection, index) => {
      const formSectionNode = [];
      formSectionNode.push(pdfTitle(formSection.Name, 'header'));
      const questionGroups = _questionGroups.filter((questionGroup) => questionGroup.FormSectionId == formSection.Id).sort((v1, v2) => v1.Order - v2.Order).map((v) => v);
      const questionGroupNodes = [];
      questionGroups.forEach((i, index) => {
        const questionGroupNode = [];
        const questions = i.Questions.sort((v1, v2) => v1.Order - v2.Order).map((v) => v);

        questionGroupNode.push({
          text: '',
          style: 'emptySpace',
        });
        questionGroupNode.push(pdfTitle(i.Name, 'subHeader'));

        const questionNodes = [];
        questions.forEach((question, index) => {
          getQuestionsCurrentSelection(question.Id);
          const questionNode = {
            id: 'question',
            style: 'question',
            stack: [],
          };
          // region selections
          const selections = [];
          if (question.Selections && question.Selections.length > 0) {
            selections.push({
              text: '',
              style: 'emptySpace',
            });
          }

          question.Selections.map((selection) => {
            selections.push({
              columns: [
                {
                  width: 20,
                  alignment: 'center',
                  stack: [
                    {
                      canvas: [selection.Id === question.Answer || (typeof question.Answer === 'object'
                                                && question.Answer.includes(selection.Id)) ? {
                          type: 'ellipse',
                          x: 24,
                          y: 4,
                          color: '#576472',
                          fillOpacity: 1,
                          r1: 6,
                          r2: 6,
                        } : {
                          type: 'rect',
                          x: 20,
                          y: 0,
                          w: 10,
                          h: 10,
                          lineWidth: 1,
                          lineColor: '#9e9e9e',
                          r: 100,
                        }],
                    },
                  ],
                },
                {
                  width: '*',
                  stack: [
                    ...pdfQuestionTitle(selection.Name, 'questionOption', selection.IsExpectedValue),
                  ],
                },
              ],
            });
            selections.push({
              text: '',
              style: 'emptySpace',
            });
          });
          // endregion selections

          // region question content
          if (question.ImageId) {
            questionNode.stack.push({
              columns: [
                {
                  width: '*',
                  stack: [
                    {
                      style: 'questionAnswer',
                      // QuestionText
                      text: [`${index + 1}.${question.Name}   `,
                        // MandatoryField Icon
                        question.IsRequired ? {
                          text: '' + '  ',
                          style: 'customSymbol',
                        } : '',
                        // Flag Icon
                        allFeedBacks.filter((feedBack) => {
                          return (feedBack.Question?.Id ?? 0) == question.Id; // feedBack.Question.Id == question.Id;
                        }).length > 0 ?
                        {
                          text: ' ' + '' + ' ',
                          style: 'symbol'
                        } : '',
                        // Correct or Wrong Icon
                        question.QuestionProcess && question.QuestionProcess.length > 0 ? question.QuestionProcess && question.QuestionProcess.length > 0 && (question.QuestionProcess[0] && question.QuestionProcess.length > 0 && question.QuestionProcess[0].IsValid) ? {
                          text: '',
                          style: 'symbol',
                        } : { text: '', style: 'symbol' } : '',
                      ],
                    },
                    ...pdfQuestionTitle(question.Description, 'questionDescription'),
                    ...selections,
                  ],
                },
                {
                  width: 200,
                  alignment: 'center',
                  stack: [
                    {
                      image: question.imgSrc,
                      fit: [100, 100],
                      style: 'questionImg',
                    },
                    {
                      text: '',
                      style: 'emptySpace',
                    },
                  ],
                },
              ],
            });
          } else {
            questionNode.stack.push({
              columns: [
                {
                  stack: [
                    {
                      style: 'questionAnswer',
                      // QuestionText
                      text: [`${index + 1}.${question.Name}   `,
                        // MandatoryField Icon
                        question.IsRequired ? {
                          text: '' + '  ',
                          style: 'customSymbol',
                        } : '',
                        // Flag Icon
                        allFeedBacks.filter((feedBack) => {
                          return (feedBack.Question?.Id ?? 0) == question.Id; // feedBack.Question.Id == question.Id;
                        }).length > 0 ?
                        {
                          text: ' ' + '' + ' ',
                          style: 'symbol'
                        } : '',
                        // Correct or Wrong Icon
                        question.QuestionProcess && question.QuestionProcess.length > 0 && (question.QuestionProcess[0] && question.QuestionProcess.length > 0
                                                    && question.QuestionProcess[0].IsValid !== undefined) ? (question.QuestionProcess[0].IsValid === true ? {
                            text: '',
                            style: 'symbol',
                          }
                            : question.QuestionProcess[0].IsValid === false ? {
                              text: '',
                              style: 'symbol',
                            } : '') : '',
                      ],
                    },
                  ],
                }],

            });
            questionNode.stack.push(pdfQuestionTitle(question.Description, 'questionDescription'));
            questionNode.stack.push(selections);
          }
          // endregion question content

          // region answer
          if (question.Answer) {
            if (question.Answer.fileId) {
              questionNode.stack.push({
                image: question.Answer.fileSrc,
                fit: [100, 100],
                style: 'questionImg',
              });
            }
            questionNode.stack.push(pdfQuestionTitle(`${question.Answer.title ? question.Answer.title : ''}`, 'questionAnswer'));
            questionNode.stack.push({
              text: '',
              style: 'emptySpace',
            });
            questionNode.stack.push(pdfQuestionTitle(`${typeof question.Answer !== 'object' && typeof question.Answer !== 'number' ? question.Answer : ''}`, 'questionAnswer'));
            questionNode.stack.push({
              text: '',
              style: 'emptySpace',
            });

            questionNode.stack.push(pdfQuestionTitle(question.AnswerCondition ? answerConditionRecursiveMode(question.AnswerCondition, 0) : '', 'questionAnswer'));
          } else if (question.QuestionTypeId != frmQuestionType.multipleChoice && question.QuestionTypeId != frmQuestionType.checkBox) {
            questionNode.stack.push({
              text: '',
              style: 'emptySpace',
            });
            questionNode.stack.push(pdfQuestionTitle(question.AnswerCondition ? answerConditionRecursiveMode(question.AnswerCondition, 0) : '', 'questionAnswer'));
          }

          // endregion answer

          // region question tag
          if (question.QuestionProcess && question.QuestionProcess.length > 0) {
            questionNode.stack.push({
              text: '',
              style: 'emptySpace',
            });

            const createDate = question.QuestionProcess[question.QuestionProcess.length - 1].CreatedDate; // Metin => question.QuestionProcess[question.QuestionProcess.length - 1].CreatedDate.format();
            const createdUser = question.QuestionProcess[question.QuestionProcess.length - 1].CreatedUser;
            questionNode.stack.push(pdfQuestionTitle(createDate ? dateFormat.viewDateTimeFormat(createDate) : '', 'questionAnswer'));
            questionNode.stack.push(pdfQuestionTitle(createdUser ? createdUser.NameSurname : '', 'questionAnswer'));
          }
          // endregion question tag

          questionNode.stack.push(pdfQuestionBr());

          questionNodes.push(questionNode);
        });

        questionGroupNode.push({
          /* id: 'question',
                    style: 'question', */
          stack: [
            questionNodes,
          ],
        });

        questionGroupNode.push({
          text: '',
          style: 'emptySpace',
        });

        questionGroupNodes.push({
          id: 'questionGroup',
          style: 'questionGroup',
          stack: [
            ...questionGroupNode,
          ],
        });
      });
      formSectionNode.push(questionGroupNodes);
      formSectionNodes.push({
        id: 'formSection',
        style: 'formSection',
        stack: formSectionNode,
      });
    });

    const printObj = { 
      content: [
        ...pdfTitle(selectedForm.Name, null, hasFormFlag),

        // Form Information Table
        ...pdfFormInformation(),

        ...formSectionNodes,

        // Staff - Vendor Information Table
        ...pdfStaffVendorInformation(),
      ],
      /*   footer: {
                   columns: [
                       {
                           text: 'Vendor',
                       },
                       {width: 1, canvas: [{type: 'line', x1: 0, y1: 0, x2: 0, y2: 40, lineWidth: 1}]},
                       {
                           text: 'Master'
                       },
                       {width: 1, canvas: [{type: 'line', x1: 0, y1: 0, x2: 0, y2: 40, lineWidth: 1}]},
                       {
                           text: 'Observer'
                       }
                   ],
                   margin: [20, 0, 20, 30],
               }, */
      header(currentPage, pageCount) {
        return {
          text: `${currentPage.toString()} / ${pageCount}`,
          alignment: 'right',
          margin: [0, 20, 20, 0],
          fontSize: 7,
        };
      },
      styles: {
        header: {
          fontSize: 10,
          bold: true,
          margin: [0, 0, 0, 2],
          color: '#243951',
        },
        questionTitle: {
          fontSize: 8,
          bold: true,
          margin: [10, 0, 0, 2],
          color: '#243951',
        },
        questionDescription: {
          fontSize: 6,
          bold: false,
          margin: [10, 0, 0, 2],
          color: '#243951',
        },
        questionOption: {
          fontSize: 6,
          bold: false,
          margin: [13, 0, 0, 2],
          color: '#243951',
        },
        questionAnswer: {
          fontSize: 7,
          bold: false,
          margin: [10, 0, 0, 2],
          color: '#243951',
        },
        attachments: {
          fontSize: 7,
          bold: true,
          margin: [0, 6, 0, 0],
          color: '#243951',
        },
        questionGroup: {
          margin: [10, 0, 0, 0],
        },
        subHeader: {
          fontSize: 10,
          bold: true,
          margin: [0, 0, 0, 5],
          color: '#243951',
        },
        emptySpace: {
          margin: [0, 0, 0, 2],
        },
        emptySpace2x: {
          margin: [20, 0, 0, 20],
        },
        emptySpace3x: {
          margin: [30, 0, 0, 30],
        },
        emptySpace4x: {
          margin: [40, 0, 0, 40],
        },
        emptySpace5x: {
          margin: [50, 0, 0, 50],
        },
        table: {
          margin: [0, 5, 0, 15],
          fontSize: 7
        },
        tableHeader: {
          bold: true,
          fontSize: 7,
          color: '#243951',
        },
        tableField: {
          bold: false,
          fontSize: 6,
          color: '#576472',
        },
        timelineStatus: {
          bold: true,
          fontSize: 6,
          color: '#fff',
        },
        timelineStatusDate: {
          bold: true,
          fontSize: 6,
          color: '#fff',
        },
        questionImg: {
          margin: [0, 0, 0, 0],
        },
        symbol: {
          font: 'FontAwesome',
          fontSize: 6 ,
        },
        symbol10: {
          font: 'FontAwesome',
          fontSize: 10 ,
        },
        customSymbol: {
          font: 'FontAwesome',
          fontSize: 6,
        },
        symbolRegular: {
          font: 'FontAwesomeRegular',
        },
      },
      pageSize: 'A4',
      pageBreakBefore(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
        if (currentNode.id === 'question' /* || currentNode.id === 'formSection' */) {
          return currentNode.pageNumbers.length > 1;
        } if (currentNode.id === 'questionGroup') {
          const question = followingNodesOnPage.find((node) => node.id == 'question');
          return question ? (question.pageNumbers.length > 1) : false;
        }
        if (currentNode.id === 'signatureSection') {
          return currentNode.pageNumbers.length > 1;
        }
        return false;
      },
    };

    return new Promise((resolve, reject) => {
      try {
        pdfMake.createPdf(printObj).getBase64((data) => {
          resolve(data);
        });
      } catch (e) {
        reject(e);
      }
    });
  },
};

// mutations
const mutations = {
  // #region dropdown
  setProcedureTypes(state, procedureTypes) {
    state.procedureTypes = procedureTypes;
  },
  setFormStatuses(state, formStatuses) {
    state.formStatuses = formStatuses;
  },
  setFormProcessStatuses(state, formProcessStatuses) {
    state.formProcessStatuses = formProcessStatuses;
  },
  setFormQuestionTypes(state, formQuestionTypes) {
    state.formQuestionTypes = formQuestionTypes;
  },
  setFormSections(state, formSections) {
    state.formSections = formSections;
  },
  // #endregion dropdown

  // #region list
  setFormListForFilter(state, formList) {
    state.formListForFilter = formList;
  },
  setFormList(state, formList) {
    state.formList = formList;
  },
  setFormProcessList(state, formProcessList) {
    state.formProcessList = formProcessList;
  },
  setFormSectionList(state, formSectionList) {
    state.formSectionList = formSectionList;
  },
  setFormQuestionTypeComparisonOperators(state, formQuestionTypeComparisonOperatorList) {
    state.formQuestionTypeComparisonOperatorList = formQuestionTypeComparisonOperatorList;
  },
  setFormStatusList(state, formStatusList) {
    state.formStatusList = formStatusList;
  },
  // #endregion list
};

export default {
  state,
  getters,
  actions,
  mutations,
};
