

































































































































































































































































































import CFMDialog from '@/scripts/CFMDialog'
import {Component, Vue} from 'vue-property-decorator';
import upload from '@/components/shared/upload.vue';

import * as blockui from '@/helpers/block-ui'

import {required} from "vuelidate/lib/validators";

import {CFMDTOSparePart, CFMDTOSparePartWarehouse } from '@/scripts/services/ClientApiAuto';
import CFMMultiselect from '@/components/partials/CFMMultiselect.vue';
import { mixinz } from '@/scripts/global/mixinz';

@Component<SparePartDialog>({
    mixins: [mixinz],
    components: {
            'cfm-multiselect': CFMMultiselect,
            'upload': upload
    },
    validations() {
        return {
          sparePart:{
              Id: {required},
              SerialNumber: {required},
              SupplyDayCount:{required},
              UnitPrice:{required},
              UnitPriceCurrency:{required},
              CriticalStockCount: {required}
          },
          selectedSparePartType:{
            Id: {required},
            KindId: {required},
            CategoryId:{required},
            SubCategoryId: {required}
          },
          selectedModel: {
            ModelId: {required}
          },
          selectedBrand: {
            BrandId: {required}
          },
          selectedUnit: {
            UnitId: {required}
          }
        }
    },
})
export default class SparePartDialog extends CFMDialog {
    drag: boolean = false;
    isUploadBusy: boolean = false;
    stockCount: number = 0;
    
    sparePart: CFMDTOSparePart = {Id: 0};
    wareHouseList : {Name:String, Id:Number}[] = [];
    brandList : {Name:String, Id:Number}[] = [];
    modelList : {Name:String, Id:Number}[] = [];
    spCategoryList : {Name:String, Id:Number}[] = [];
    spSubCategoryList : {Name:String, Id:Number}[] = [];
    spTypeList : {Name:String, Id:Number}[] = [];
    spKindList : {Name:String, Id:Number}[] = [];
    unitList : {Name:String, Id:Number}[] = [];

    selectedWareHouse: CFMDTOSparePart = {Id: 0};
    selectedSparePartType: CFMDTOSparePart = {Id: 0};
    selectedSparePartKind: CFMDTOSparePart = {Id: 0};
    selectedCategory: CFMDTOSparePart = {Id: 0};
    selectedSubCategory: CFMDTOSparePart = {Id: 0};
    selectedModel: CFMDTOSparePart = {Id: 0};
    selectedBrand: CFMDTOSparePart = {Id: 0};
    selectedUnit: CFMDTOSparePart = {Id: 0};

    multiSelectProps = {
        "close-on-select": false,
        "clear-on-select": false,
        "preserve-search": true,
    }

  get selectOptions() {
        return {
            searchable: true,
            placeholder: this.translate('please_select'),
            "select-label": this.translate('select_label'),
            "deselect-label": this.translate('deselect_label'),
            "selected-label": this.translate('selected_label'),
            "show-no-results": false,
        }
  }

    get selectedWareHouses() {
      if (this.sparePart.SparePartWarehouses?.length == 0) {
          return null;
      } else {
          var a = this.sparePart.SparePartWarehouses?.map(x => {
              let selectedObj = this.wareHouseList.find(y => x.WarehouseId == y.Id);
              if (selectedObj) {
                  return selectedObj;
              } else {
                  return {};
              }
          });
      }
    }

    async created(){
      const promises = [
        this.getWareHoueses(),
        this.getBrands(),
        this.getModels(0),
        this.getSparePartCategories(),
        this.getSparePartSubCategories(),
        this.getSparePartTypes(),
        this.getSparePartKinds(),
        this.getUnits(),
      ];
      if(this.isUpdate){
        blockui.blockModal(this);
        const res = await this.apiClient.sparePart.sparePartGetById({id: <number>this.id});
        this.sparePart = res.data;
        if (this.sparePart.SparePartWarehouses) {
            if (this.sparePart.SparePartWarehouses.length>0) {
                for (let index = 0; index < this.sparePart.SparePartWarehouses.length; index++) {
                    if (this.sparePart.SparePartWarehouses[index].StockCount) {
                        this.stockCount = this.sparePart.SparePartWarehouses[index].StockCount;
                    }
                }
            }
        }
        this.selectedBrand = res.data;
        this.selectedModel = res.data;
        this.selectedUnit = res.data;
        if(this.sparePart.TypeId){
            this.getSparePartTypeById(this.sparePart.TypeId);
        }
        if (this.sparePart.Model) {
            this.selectedModel['Model'] = this.sparePart.Model;
            if (this.sparePart.Brand) {
                this.selectedBrand['Brand'] = this.sparePart.Brand;
                if (this.sparePart.Unit) {
                    this.selectedUnit['Unit'] = this.sparePart.Unit;
                }
            }
        }
      }
      blockui.unBlockModal();
    }

    onBrandChanged(args:any){
        this.getModels(args.BrandId);
    }

    removeWareHouse(warehouse: CFMDTOSparePartWarehouse) {
      if(!this.sparePart.SparePartWarehouses) {return;}
        let capIndex = this.sparePart.SparePartWarehouses.findIndex(x => x.WarehouseId == warehouse.Id);
        this.sparePart.SparePartWarehouses.splice(capIndex, 1);
    }

    selectWareHouse(warehouse: CFMDTOSparePartWarehouse) {
      if (!this.sparePart.SparePartWarehouses) {
          Vue.set(this.sparePart, 'SparePartWarehouses', []);
      }
      if (!warehouse.WarehouseId) {
        warehouse.WarehouseId = warehouse.Id;
        // @ts-ignore
        warehouse['Warehouse'] = {Name:warehouse.Name,Id:warehouse.Id, StockCount: this.stockCount}
      }
      (<CFMDTOSparePartWarehouse[]>this.sparePart.SparePartWarehouses).push(warehouse);
    }

    async getWareHoueses(){
        const response = await this.apiClient.warehouse.warehouseGetWareHouses();
        this.wareHouseList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }

    async getBrands(){
        const response = await this.apiClient.brand.brandGetBrands();
        this.brandList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
    async getModels(brandId: number){
        const response = await this.apiClient.model.modelGetModels({brandId:brandId});
        this.modelList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
    async getSparePartCategories(){
        const response = await this.apiClient.sparePartCategory.sparePartCategoryGetCategories();
        this.spCategoryList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
    async getSparePartSubCategories(){
        const response = await this.apiClient.sparePartSubCategory.sparePartSubCategoryGetCategories();
        this.spSubCategoryList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
    async getSparePartTypes(){
        const response = await this.apiClient.sparePartType.sparePartTypeGetTypes();
        this.spTypeList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
    async getSparePartKinds(){
        const response = await this.apiClient.sparePartKind.sparePartKindGetKinds();
        this.spKindList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }
    async getUnits(){
        const response = await this.apiClient.unit.unitGetUnits();
        this.unitList = response.data.map(x=>({Name:<string>x.Text, Id:<number>x.Value}));
    }

    async getSparePartTypeById(id: number){
        const response = await this.apiClient.sparePartType.sparePartTypeGetById({id: id});
        this.selectedSparePartType = response.data;
        this.selectedSparePartType['Type'] = response.data;
    }

    async onTypeChanged(args: any){
        this.getSparePartTypeById(args.Id);
    }

    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
          return;
      }
        // @ts-ignore
        for (let index = 0; index < this.sparePart.SparePartWarehouses.length; index++) {
            // @ts-ignore
            const element = this.sparePart.SparePartWarehouses[index];
            element.StockCount = this.stockCount;
            // @ts-ignore
            if (element.Id == element.WarehouseId) {
            // @ts-ignore
                this.sparePart.SparePartWarehouses[index].Id = 0;
            // @ts-ignore
            }
        }
      let formData = {
        Id: this.sparePart.Id,
        BrandId: this.selectedBrand.BrandId,
        ModelId: this.selectedModel.ModelId,
        SerialNumber: this.sparePart.SerialNumber,
        CategoryId: this.selectedSparePartType.CategoryId,
        SubCategoryId: this.selectedSparePartType.SubCategoryId,
        TypeId: this.selectedSparePartType.Id,
        KindId: this.selectedSparePartType.KindId,
        SupplyDayCount: this.sparePart.SupplyDayCount,
        UnitPrice: this.sparePart.UnitPrice,
        UnitPriceCurrency: this.sparePart.UnitPriceCurrency,
        UnitId: this.selectedUnit.UnitId,
        CriticalStockCount: this.sparePart.CriticalStockCount,
        SparePartWarehouses: this.sparePart.SparePartWarehouses ? this.sparePart.SparePartWarehouses  : [],
        Description:this.sparePart.Description
      };
      blockui.blockModal(this);
      try{
        const response  = await this.apiClient.sparePart.sparePartSave(formData);
        if (response.data.IsSuccess) {
          toastr.success(this.translate('save_successfully'));
          this.saveSucceeded();
          this.close();
        } else {
          const errorMessage = response.data.Message ? response.data.Message : "Error";
          toastr.error(errorMessage);
        }
      }
      catch(e){
        console.log("err",e)
        toastr.error(e);
      }
      finally{
        blockui.unBlockModal();
      }
    }
}
