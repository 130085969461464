<template>
    <div id="m_aside_left" class="m-grid__item m-aside-left m-aside-left--skin-dark otomatica-sidebar">
        <!-- BEGIN: Aside Menu -->
        <div id="m_ver_menu" class="m-aside-menu  m-aside-menu--skin-dark m-aside-menu--submenu-skin-dark"
             m-menu-vertical="1" m-menu-scrollable="1" m-menu-dropdown-timeout="500">
            <div class="sidebar-search">
                <div class="m-input-icon m-input-icon--right">
                    <input type="text" class="form-control m-input" :placeholder="this.translate('search')">
                    <span class="m-input-icon__icon m-input-icon__icon--right">
						<span>
							<i class="fa fa-search"></i>
						</span>
					</span>
                </div>
                <a href="javascript:;" class="remove-button"><i class="la la-remove"></i></a>
                <div id="search_result"></div>
            </div>
            <ul class="m-menu__nav  m-menu__nav--dropdown-submenu-arrow ">
                <!--Dashboard beginning-->
                <li id="dashboard_parent_menu" class="m-menu__item  m-menu__item--submenu" aria-haspopup="true"
                    m-menu-submenu-toggle="hover">
                    <a href="javascript:;" class="m-menu__link m-menu__toggle">
                        <i class="m-menu__link-icon fa fa-chart-line"></i>
                        <span class="m-menu__link-text" v-lang.dashboard></span>
                        <i class="m-menu__ver-arrow la la-angle-right"></i>
                    </a>
                    <div class="m-menu__submenu ">
                        <span class="m-menu__arrow"></span>
                        <ul class="m-menu__subnav">
                            <li id="executive_dashboards_menu" class="m-menu__item " aria-haspopup="true"
                                m-menu-submenu-toggle="hover">
                                <a href="#/Home" class="m-menu__link">
                                    <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                    <span class="m-menu__link-text" v-lang.executive_dashboards></span>
                                </a>
                            </li>
                            <!--<li id="topologic_view_menu" class="m-menu__item " aria-haspopup="true"
                                m-menu-submenu-toggle="hover">
                                <a href="#/TopologicView" class="m-menu__link">
                                    <i class="m-menu__link-bullet m-menu__link-bullet&#45;&#45;dot"><span></span></i>
                                    <span class="m-menu__link-text" v-lang.topologic_view></span>
                                </a>
                            </li>-->
                        </ul>
                    </div>
                </li>
                <!--Dashboard end-->
                <!--Work Order beginning-->
                <li id="work_orders_parent_menu" class="m-menu__item  m-menu__item--submenu" aria-haspopup="true"
                    m-menu-submenu-toggle="hover">
                    <a href="javascript:;" class="m-menu__link m-menu__toggle">
                        <i class="m-menu__link-icon b-fa b-fa-tasks"></i>
                        <span class="m-menu__link-text" v-lang.tasks></span>
                        <i class="m-menu__ver-arrow la la-angle-right"></i>
                    </a>
                    <div class="m-menu__submenu ">
                        <span class="m-menu__arrow"></span>
                        <ul class="m-menu__subnav">
                            <li id="my_work_orders_menu" class="m-menu__item " aria-haspopup="true"
                                m-menu-submenu-toggle="hover">
                                <a href="#/MyWorkOrders" class="m-menu__link">
                                    <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                    <span class="m-menu__link-text" v-lang.my_tasks></span>
                                </a>
                            </li>
                            <li id="work_orders_menu" class="m-menu__item " aria-haspopup="true"
                                m-menu-submenu-toggle="hover">
                                <a href="#/WorkOrder" class="m-menu__link">
                                    <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                    <span class="m-menu__link-text" v-lang.work_orders></span>
                                </a>
                            </li>
                            <li id="incidents_menu" class="m-menu__item " aria-haspopup="true"
                                m-menu-submenu-toggle="hover">
                                <a href="#/Incident" class="m-menu__link">
                                    <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                    <span class="m-menu__link-text" v-lang.incidents></span>
                                </a>
                            </li>
<!--                            <li id="pending_approvals_menu" class="m-menu__item " aria-haspopup="true"-->
<!--                                m-menu-submenu-toggle="hover">-->
<!--                                <a href="#/PendingApprovals" class="m-menu__link">-->
<!--                                    <i class="m-menu__link-bullet m-menu__link-bullet&#45;&#45;dot"><span></span></i>-->
<!--                                    <span class="m-menu__link-text" v-lang.work_orders_pending_approval></span>-->
<!--                                </a>-->
<!--                            </li>-->
                            <li id="pending_approval_work_order_menu" class="m-menu__item " aria-haspopup="true"
                                m-menu-submenu-toggle="hover">
                                <a href="#/PendingApprovalWorkOrder" class="m-menu__link">
                                    <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                    <span class="m-menu__link-text" v-lang.work_orders_pending_approval></span>
                                </a>
                            </li>
                            <!--<li id="cases_menu" class="m-menu__item " aria-haspopup="true" m-menu-submenu-toggle="hover">
                                              <a href="javascript:;" class="m-menu__link">
                                                  <i class="m-menu__link-bullet m-menu__link-bullet&#45;&#45;dot"><span></span></i>
                                                  <span class="m-menu__link-text" v-lang.cases></span>
                                              </a>
                                          </li>
                                          <li id="alarms_menu" class="m-menu__item " aria-haspopup="true" m-menu-submenu-toggle="hover">
                                              <a href="javascript:;" class="m-menu__link">
                                                  <i class="m-menu__link-bullet m-menu__link-bullet&#45;&#45;dot"><span></span></i>
                                                  <span class="m-menu__link-text" v-lang.alarms></span>
                                              </a>
                                          </li>-->
                        </ul>
                    </div>
                </li>
                <!--Work Order end-->
                <!--Work beginning-->
                <li id="works_parent_menu" class="m-menu__item  m-menu__item--submenu" aria-haspopup="true"
                    m-menu-submenu-toggle="hover">
                    <a href="javascript:;" class="m-menu__link m-menu__toggle">
                        <i class="m-menu__link-icon fa fa-list"></i>
                        <span class="m-menu__link-text" v-lang.works></span>
                        <i class="m-menu__ver-arrow la la-angle-right"></i>
                    </a>
                    <div class="m-menu__submenu ">
                        <span class="m-menu__arrow"></span>
                        <ul class="m-menu__subnav">
                            <li id="work_menu" class="m-menu__item " aria-haspopup="true" m-menu-submenu-toggle="hover">
                                <a href="#/Work" class="m-menu__link">
                                    <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                    <span class="m-menu__link-text" v-lang.works></span>
                                </a>
                            </li>
                            <li id="raci_matrix_menu" class="m-menu__item " aria-haspopup="true"
                                m-menu-submenu-toggle="hover">
                                <a href="#/RaciMatrix" class="m-menu__link">
                                    <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                    <span class="m-menu__link-text" v-lang.raci_matrix></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
                <!--Work end-->
                <!--Training beginning-->
                <li id="trainings_parent_menu" class="m-menu__item  m-menu__item--submenu" aria-haspopup="true"
                    m-menu-submenu-toggle="hover">
                    <a href="javascript:;" class="m-menu__link m-menu__toggle">
                        <i class="m-menu__link-icon fa fa-book-open"></i>
                        <span class="m-menu__link-text" v-lang.trainings></span>
                        <i class="m-menu__ver-arrow la la-angle-right"></i>
                    </a>
                    <div class="m-menu__submenu ">
                        <span class="m-menu__arrow"></span>
                        <ul class="m-menu__subnav">
                            <li id="role_training_menu" class="m-menu__item " aria-haspopup="true"
                                m-menu-submenu-toggle="hover">
                                <a href="#/RoleTraining" class="m-menu__link">
                                    <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                    <span class="m-menu__link-text" v-lang.role_trainings></span>
                                </a>
                            </li>
                            <li id="training_tracking_parent_menu" class="m-menu__item  m-menu__item--submenu"
                                aria-haspopup="true"
                                m-menu-submenu-toggle="hover">
                                <a href="javascript:;" class="m-menu__link m-menu__toggle">
                                    <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                    <span class="m-menu__link-text" v-lang.training_tracking></span>
                                    <i class="m-menu__ver-arrow la la-angle-right"></i>
                                </a>
                                <div class="m-menu__submenu ">
                                    <span class="m-menu__arrow"></span>
                                    <ul class="m-menu__subnav">
                                        <li id="training_tracking_menu" class="m-menu__item "
                                            aria-haspopup="true">
                                            <a href="#/TrainingTracking" class="m-menu__link ">
                                                <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                <span class="m-menu__link-text"
                                                        v-lang.training_tracking_by_training></span>
                                            </a>
                                        </li>
                                        <li id="user_training_tracking_menu" class="m-menu__item "
                                            aria-haspopup="true">
                                            <a href="#/UserTrainingTracking" class="m-menu__link ">
                                                <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                <span class="m-menu__link-text" v-lang.user_training_tracking></span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </li>
                <!--Training end-->
                <!--Maintenance & Operations beginning-->
                <li id="maintenance_and_operations_parent_menu" class="m-menu__item  m-menu__item--submenu"
                    aria-haspopup="true"
                    m-menu-submenu-toggle="hover">
                    <a href="javascript:;" class="m-menu__link m-menu__toggle">
                        <i class="m-menu__link-icon fa fa-wrench"></i>
                        <span class="m-menu__link-text" v-lang.maintenance_and_operations></span>
                        <i class="m-menu__ver-arrow la la-angle-right"></i>
                    </a>
                    <div class="m-menu__submenu ">
                        <span class="m-menu__arrow"></span>
                        <ul class="m-menu__subnav">
                            <li id="preventive_maintenance_schedule_menu" class="m-menu__item " aria-haspopup="true"
                                m-menu-submenu-toggle="hover">
                                <a href="#/MaintenancePlan" class="m-menu__link">
                                    <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                    <span class="m-menu__link-text" v-lang.preventive_maintenance_schedule></span>
                                </a>
                            </li>
                            <li id="maintenance_plan_calendar_menu" class="m-menu__item " aria-haspopup="true"
                                m-menu-submenu-toggle="hover">
                                <a href="#/MaintenancePlanCalendar" class="m-menu__link">
                                    <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                    <span class="m-menu__link-text" v-lang.maintenance_plan_calendar></span>
                                </a>
                            </li>
                            <li id="mass_work_order_menu" class="m-menu__item " aria-haspopup="true"
                                m-menu-submenu-toggle="hover">
                                <a href="#/MassMaintenancePlan" class="m-menu__link">
                                    <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                    <span class="m-menu__link-text" v-lang.mass_work_order></span>
                                </a>
                            </li>
                            <li id="pending_maintenance_plans_for_assign_menu" class="m-menu__item " aria-haspopup="true"
                                m-menu-submenu-toggle="hover">
                                <a href="#/PendingMaintenancePlansForAssign" class="m-menu__link">
                                    <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                    <span class="m-menu__link-text" v-lang.pending_maintenance_plans_for_assign></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
                <!--Maintenance & Operations end-->
                <!--Organization beginning-->
                <li id="organization_parent_menu" class="m-menu__item  m-menu__item--submenu" aria-haspopup="true"
                    m-menu-submenu-toggle="hover">
                    <a href="javascript:;" class="m-menu__link m-menu__toggle">
                        <i class="m-menu__link-icon fa fa-sitemap"></i>
                        <span class="m-menu__link-text" v-lang.organization></span>
                        <i class="m-menu__ver-arrow la la-angle-right"></i>
                    </a>
                    <div class="m-menu__submenu ">
                        <span class="m-menu__arrow"></span>
                        <ul class="m-menu__subnav">
                            <li id="organizational_scheme_menu" class="m-menu__item " aria-haspopup="true"
                                m-menu-submenu-toggle="hover">
                                <a href="#/OrganizationScheme" class="m-menu__link">
                                    <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                    <span class="m-menu__link-text" v-lang.organization_scheme></span>
                                </a>
                            </li>
                            <li id="shift_calendar_menu" class="m-menu__item " aria-haspopup="true"
                                m-menu-submenu-toggle="hover">
                                <a href="#/ShiftCalendar" class="m-menu__link ">
                                    <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                    <span class="m-menu__link-text" v-lang.shift_calendar></span>
                                </a>
                            </li>
                            <li id="staff_list_menu" class="m-menu__item " aria-haspopup="true"
                                m-menu-submenu-toggle="hover">
                                <a href="#/User" class="m-menu__link">
                                    <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                    <span class="m-menu__link-text" v-lang.staff_list></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
                <!--Organization end-->
                <!--Assets beginning-->
                <li id="assets_parent_menu" class="m-menu__item  m-menu__item--submenu" aria-haspopup="true"
                    m-menu-submenu-toggle="hover">
                    <a href="javascript:;" class="m-menu__link m-menu__toggle">
                        <i class="m-menu__link-icon b-fa b-fa-warehouse"></i>
                        <span class="m-menu__link-text" v-lang.assets></span>
                        <i class="m-menu__ver-arrow la la-angle-right"></i>
                    </a>
                    <div class="m-menu__submenu ">
                        <span class="m-menu__arrow"></span>
                        <ul class="m-menu__subnav">
                            <li id="buildings_menu" class="m-menu__item " aria-haspopup="true"
                                m-menu-submenu-toggle="hover">
                                <a href="#/Building" class="m-menu__link">
                                    <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                    <span class="m-menu__link-text" v-lang.buildings></span>
                                </a>
                            </li>
                            <li id="room_capacity_equipment_menu" class="m-menu__item " aria-haspopup="true">
                                <a href="#/RoomCapacityEquipment" class="m-menu__link ">
                                    <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                    <span class="m-menu__link-text" v-lang.room_capacity_equipment></span>
                                </a>
                            </li>
                            <li id="inventory_menu" class="m-menu__item " aria-haspopup="true"
                                m-menu-submenu-toggle="hover">
                                <a href="#/InventoryList" class="m-menu__link">
                                    <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                    <span class="m-menu__link-text" v-lang.inventory></span>
                                </a>
                            </li>
                            <li id="tool_menu" class="m-menu__item " aria-haspopup="true" m-menu-submenu-toggle="hover">
                                <a href="#/Tool" class="m-menu__link">
                                    <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                    <span class="m-menu__link-text" v-lang.tool></span>
                                </a>
                            </li>
                            <li id="tool_calendar_menu" class="m-menu__item " aria-haspopup="true"
                                m-menu-submenu-toggle="hover">
                                <a href="#/ToolCalendar" class="m-menu__link">
                                    <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                    <span class="m-menu__link-text" v-lang.tool_calendar></span>
                                </a>
                            </li>
                             <li id="spare_part_menu" class="m-menu__item " aria-haspopup="true"
                                m-menu-submenu-toggle="hover">
                                <a href="#/SparePart" class="m-menu__link">
                                    <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                    <span class="m-menu__link-text" v-lang.spare_parts></span>
                                </a>
                            </li>
                            <li id="discard_product_menu" class="m-menu__item " aria-haspopup="true"
                                m-menu-submenu-toggle="hover">
                                <a href="#/DiscardProduct" class="m-menu__link m-menu__toggle">
                                    <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                    <span class="m-menu__link-text" v-lang.discard_product></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
                <!--Assets end-->
                <!--Library beginning-->
                <li id="library_parent_menu" class="m-menu__item  m-menu__item--submenu" aria-haspopup="true"
                    m-menu-submenu-toggle="hover">
                    <a href="javascript:;" class="m-menu__link m-menu__toggle">
                        <i class="m-menu__link-icon fa fa-file-alt"></i>
                        <span class="m-menu__link-text" v-lang.library></span>
                        <i class="m-menu__ver-arrow la la-angle-right"></i>
                    </a>
                    <div class="m-menu__submenu ">
                        <span class="m-menu__arrow"></span>
                        <ul class="m-menu__subnav">
                            <li id="procedure_menu" class="m-menu__item " aria-haspopup="true">
                                <a href="#/Procedure" class="m-menu__link ">
                                    <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                    <span class="m-menu__link-text" v-lang.procedures></span>
                                </a>
                            </li>
                            <li id="forms_menu" class="m-menu__item " aria-haspopup="true">
                                <a href="#/Forms" class="m-menu__link ">
                                    <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                    <span class="m-menu__link-text" v-lang.forms></span>
                                </a>
                            </li>
                            <li id="form_answers_menu" class="m-menu__item " aria-haspopup="true">
                                <a href="#/FormAnswers" class="m-menu__link ">
                                    <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                    <span class="m-menu__link-text" v-lang.form_answers></span>
                                </a>
                            </li>
                            <li id="contract_list_menu" class="m-menu__item " aria-haspopup="true">
                                <a href="#/ContractList" class="m-menu__link ">
                                    <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                    <span class="m-menu__link-text" v-lang.contract_list></span>
                                </a>
                            </li>
                            <!--<li id="t_and_c_reports_parent_menu" class="m-menu__item  m-menu__item&#45;&#45;submenu" aria-haspopup="true"
                                                  m-menu-submenu-toggle="hover">
                                              <a href="javascript:;" class="m-menu__link m-menu__toggle">
                                                  <i class="m-menu__link-bullet m-menu__link-bullet&#45;&#45;dot"><span></span></i>
                                                  <span class="m-menu__link-text" v-lang.t_and_c_reports></span>
                                                  <i class="m-menu__ver-arrow la la-angle-right"></i>
                                              </a>
                                              <div class="m-menu__submenu ">
                                                  <span class="m-menu__arrow"></span>
                                                  <ul class="m-menu__subnav">
                                                      <li id="l1_menu" class="m-menu__item " aria-haspopup="true">
                                                          <a href="javascript:;" class="m-menu__link ">
                                                              <i class="m-menu__link-bullet m-menu__link-bullet&#45;&#45;dot"><span></span></i>
                                                              <span class="m-menu__link-text" v-lang.l1></span>
                                                          </a>
                                                      </li>
                                                      <li id="l2_menu" class="m-menu__item " aria-haspopup="true">
                                                          <a href="javascript:;" class="m-menu__link ">
                                                              <i class="m-menu__link-bullet m-menu__link-bullet&#45;&#45;dot"><span></span></i>
                                                              <span class="m-menu__link-text" v-lang.l2></span>
                                                          </a>
                                                      </li>
                                                      <li id="l3_menu" class="m-menu__item " aria-haspopup="true">
                                                          <a href="javascript:;" class="m-menu__link ">
                                                              <i class="m-menu__link-bullet m-menu__link-bullet&#45;&#45;dot"><span></span></i>
                                                              <span class="m-menu__link-text" v-lang.l3></span>
                                                          </a>
                                                      </li>
                                                      <li id="l4_parent_menu" class="m-menu__item  m-menu__item&#45;&#45;submenu" aria-haspopup="true"
                                                              m-menu-submenu-toggle="hover">
                                                          <a href="javascript:;" class="m-menu__link m-menu__toggle">
                                                              <i class="m-menu__link-bullet m-menu__link-bullet&#45;&#45;dot"><span></span></i>
                                                              <span class="m-menu__link-text" v-lang.l4></span>
                                                              <i class="m-menu__ver-arrow la la-angle-right"></i>
                                                          </a>
                                                          <div class="m-menu__submenu ">
                                                              <span class="m-menu__arrow"></span>
                                                              <ul class="m-menu__subnav">
                                                                  <li id="electrical_menu" class="m-menu__item " aria-haspopup="true">
                                                                      <a href="javascript:;" class="m-menu__link ">
                                                                          <i class="m-menu__link-bullet m-menu__link-bullet&#45;&#45;dot"><span></span></i>
                                                                          <span class="m-menu__link-text" v-lang.electrical></span>
                                                                      </a>
                                                                  </li>
                                                                  <li id="mechanical_parent_menu" class="m-menu__item  m-menu__item&#45;&#45;submenu"
                                                                          aria-haspopup="true"
                                                                          m-menu-submenu-toggle="hover">
                                                                      <a href="javascript:;" class="m-menu__link m-menu__toggle">
                                                                          <i class="m-menu__link-bullet m-menu__link-bullet&#45;&#45;dot"><span></span></i>
                                                                          <span class="m-menu__link-text" v-lang.mechanical></span>
                                                                          <i class="m-menu__ver-arrow la la-angle-right"></i>
                                                                      </a>
                                                                      <div class="m-menu__submenu ">
                                                                          <span class="m-menu__arrow"></span>
                                                                          <ul class="m-menu__subnav">
                                                                              <li id="air_to_air_menu" class="m-menu__item " aria-haspopup="true">
                                                                                  <a href="javascript:;" class="m-menu__link ">
                                                                                      <i class="m-menu__link-bullet m-menu__link-bullet&#45;&#45;dot"><span></span></i>
                                                                                      <span class="m-menu__link-text" v-lang.air_to_air></span>
                                                                                  </a>
                                                                              </li>
                                                                              <li id="chiller_menu" class="m-menu__item " aria-haspopup="true">
                                                                                  <a href="javascript:;" class="m-menu__link ">
                                                                                      <i class="m-menu__link-bullet m-menu__link-bullet&#45;&#45;dot"><span></span></i>
                                                                                      <span class="m-menu__link-text" v-lang.chiller></span>
                                                                                  </a>
                                                                              </li>
                                                                          </ul>
                                                                      </div>
                                                                  </li>
                                                              </ul>
                                                          </div>
                                                      </li>
                                                      <li id="l5_menu" class="m-menu__item " aria-haspopup="true">
                                                          <a href="javascript:;" class="m-menu__link ">
                                                              <i class="m-menu__link-bullet m-menu__link-bullet&#45;&#45;dot"><span></span></i>
                                                              <span class="m-menu__link-text" v-lang.l5></span>
                                                          </a>
                                                      </li>
                                                  </ul>
                                              </div>
                                          </li>-->
                        </ul>
                    </div>
                </li>
                <!--Library end-->
                <!--Settings beginning-->
                <li id="settings_parent_menu" class="m-menu__item  m-menu__item--submenu" aria-haspopup="true"
                    m-menu-submenu-toggle="hover">
                    <a href="javascript:;" class="m-menu__link m-menu__toggle">
                        <i class="m-menu__link-icon fa fa-cogs"></i>
                        <span class="m-menu__link-text" v-lang.settings></span>
                        <i class="m-menu__ver-arrow la la-angle-right"></i>
                    </a>
                    <div class="m-menu__submenu ">
                        <span class="m-menu__arrow"></span>
                        <ul class="m-menu__subnav">
                            <li id="staffing_parent_menu" class="m-menu__item  m-menu__item--submenu"
                                aria-haspopup="true"
                                m-menu-submenu-toggle="hover">
                                <a href="javascript:;" class="m-menu__link m-menu__toggle">
                                    <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                    <span class="m-menu__link-text" v-lang.staffing></span>
                                    <i class="m-menu__ver-arrow la la-angle-right"></i>
                                </a>
                                <div class="m-menu__submenu ">
                                    <span class="m-menu__arrow"></span>
                                    <ul class="m-menu__subnav">
                                        <li id="training_parent_menu" class="m-menu__item  m-menu__item--submenu"
                                            aria-haspopup="true"
                                            m-menu-submenu-toggle="hover">
                                            <a href="javascript:;" class="m-menu__link m-menu__toggle">
                                                <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                <span class="m-menu__link-text" v-lang.training></span>
                                                <i class="m-menu__ver-arrow la la-angle-right"></i>
                                            </a>
                                            <div class="m-menu__submenu ">
                                                <span class="m-menu__arrow"></span>
                                                <ul class="m-menu__subnav">
                                                    <li id="training_type_menu" class="m-menu__item "
                                                        aria-haspopup="true">
                                                        <a href="#/TrainingType" class="m-menu__link ">
                                                            <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                            <span class="m-menu__link-text" v-lang.training_type></span>
                                                        </a>
                                                    </li>
                                                    <li id="training_menu" class="m-menu__item " aria-haspopup="true">
                                                        <a href="#/Training" class="m-menu__link ">
                                                            <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                            <span class="m-menu__link-text" v-lang.training></span>
                                                        </a>
                                                    </li>
                                                    <li id="training_assessment_menu" class="m-menu__item "
                                                        aria-haspopup="true">
                                                        <a href="#/TrainingAssessment" class="m-menu__link ">
                                                            <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                            <span class="m-menu__link-text"
                                                                  v-lang.training_assessment></span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li id="qualification_parent_menu" class="m-menu__item  m-menu__item--submenu"
                                            aria-haspopup="true"
                                            m-menu-submenu-toggle="hover">
                                            <a href="javascript:;" class="m-menu__link m-menu__toggle">
                                                <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                <span class="m-menu__link-text" v-lang.qualification></span>
                                                <i class="m-menu__ver-arrow la la-angle-right"></i>
                                            </a>
                                            <div class="m-menu__submenu ">
                                                <span class="m-menu__arrow"></span>
                                                <ul class="m-menu__subnav">
                                                    <li id="qualification_type_menu" class="m-menu__item "
                                                        aria-haspopup="true">
                                                        <a href="#/QualificationType" class="m-menu__link ">
                                                            <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                            <span class="m-menu__link-text"
                                                                  v-lang.qualification_type></span>
                                                        </a>
                                                    </li>
                                                    <li id="qualification_menu" class="m-menu__item "
                                                        aria-haspopup="true">
                                                        <a href="#/Qualification" class="m-menu__link ">
                                                            <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                            <span class="m-menu__link-text" v-lang.qualification></span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li id="user_role_menu" class="m-menu__item " aria-haspopup="true"
                                            m-menu-submenu-toggle="hover">
                                            <a href="#/UserRole" class="m-menu__link">
                                                <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                <span class="m-menu__link-text" v-lang.user_role></span>
                                            </a>
                                        </li>
                                        <li id="job_menu" class="m-menu__item " aria-haspopup="true"
                                            m-menu-submenu-toggle="hover">
                                            <a href="#/Job" class="m-menu__link">
                                                <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                <span class="m-menu__link-text" v-lang.job></span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li id="work_parent_menu" class="m-menu__item  m-menu__item--submenu" aria-haspopup="true"
                                m-menu-submenu-toggle="hover">
                                <a href="javascript:;" class="m-menu__link m-menu__toggle">
                                    <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                    <span class="m-menu__link-text" v-lang.work></span>
                                    <i class="m-menu__ver-arrow la la-angle-right"></i>
                                </a>
                                <div class="m-menu__submenu ">
                                    <span class="m-menu__arrow"></span>
                                    <ul class="m-menu__subnav">
                                        <li id="work_group_menu" class="m-menu__item " aria-haspopup="true"
                                            m-menu-submenu-toggle="hover">
                                            <a href="#/WorkGroup" class="m-menu__link">
                                                <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                <span class="m-menu__link-text" v-lang.work_group></span>
                                            </a>
                                        </li>
                                        <li id="qaqc_menu" class="m-menu__item " aria-haspopup="true"
                                            m-menu-submenu-toggle="hover">
                                            <a href="#/QAQC" class="m-menu__link">
                                                <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                <span class="m-menu__link-text" v-lang.qaqc></span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li id="work_order_parent_menu" class="m-menu__item  m-menu__item--submenu"
                                aria-haspopup="true"
                                m-menu-submenu-toggle="hover">
                                <a href="javascript:;" class="m-menu__link m-menu__toggle">
                                    <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                    <span class="m-menu__link-text" v-lang.work_order></span>
                                    <i class="m-menu__ver-arrow la la-angle-right"></i>
                                </a>
                                <div class="m-menu__submenu ">
                                    <span class="m-menu__arrow"></span>
                                    <ul class="m-menu__subnav">
                                        <li id="work_order_type_menu" class="m-menu__item " aria-haspopup="true"
                                            m-menu-submenu-toggle="hover">
                                            <a href="#/WorkOrderType" class="m-menu__link">
                                                <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                <span class="m-menu__link-text" v-lang.work_order_type></span>
                                            </a>
                                        </li>
                                        <li id="work_order_status_description_menu" class="m-menu__item "
                                            aria-haspopup="true"
                                            m-menu-submenu-toggle="hover">
                                            <a href="#/WorkOrderStatusDescription" class="m-menu__link">
                                                <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                <span class="m-menu__link-text"
                                                      v-lang.work_order_status_description></span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li id="constraints_menu" class="m-menu__item " aria-haspopup="true"
                                m-menu-submenu-toggle="hover">
                                <a href="#/Constraint" class="m-menu__link">
                                    <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                    <span class="m-menu__link-text" v-lang.constraints></span>
                                </a>
                            </li>
                            
                            <li id="settings_assets_parent_menu" class="m-menu__item  m-menu__item--submenu"
                                aria-haspopup="true"
                                m-menu-submenu-toggle="hover">
                                <a href="javascript:;" class="m-menu__link m-menu__toggle">
                                    <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                    <span class="m-menu__link-text" v-lang.assets></span>
                                    <i class="m-menu__ver-arrow la la-angle-right"></i>
                                </a>
                                <div class="m-menu__submenu ">
                                    <span class="m-menu__arrow"></span>
                                    <ul class="m-menu__subnav">
                                        <li id="location_parent_menu" class="m-menu__item  m-menu__item--submenu"
                                            aria-haspopup="true"
                                            m-menu-submenu-toggle="hover">
                                            <a href="javascript:;" class="m-menu__link m-menu__toggle">
                                                <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                <span class="m-menu__link-text" v-lang.location></span>
                                                <i class="m-menu__ver-arrow la la-angle-right"></i>
                                            </a>
                                            <div class="m-menu__submenu ">
                                                <span class="m-menu__arrow"></span>
                                                <ul class="m-menu__subnav">
                                                    <li id="continent_menu" class="m-menu__item " aria-haspopup="true">
                                                        <a href="#/Continent" class="m-menu__link ">
                                                            <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                            <span class="m-menu__link-text" v-lang.continent></span>
                                                        </a>
                                                    </li>
                                                    <li id="country_menu" class="m-menu__item " aria-haspopup="true">
                                                        <a href="#/Country" class="m-menu__link ">
                                                            <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                            <span class="m-menu__link-text" v-lang.country></span>
                                                        </a>
                                                    </li>
                                                    <li id="city_menu" class="m-menu__item " aria-haspopup="true">
                                                        <a href="#/City" class="m-menu__link ">
                                                            <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                            <span class="m-menu__link-text" v-lang.city></span>
                                                        </a>
                                                    </li>
                                                    <li id="district_menu" class="m-menu__item " aria-haspopup="true">
                                                        <a href="#/District" class="m-menu__link ">
                                                            <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                            <span class="m-menu__link-text" v-lang.district></span>
                                                        </a>
                                                    </li>
                                                    <li id="campus_menu" class="m-menu__item " aria-haspopup="true">
                                                        <a href="#/Campus" class="m-menu__link ">
                                                            <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                            <span class="m-menu__link-text" v-lang.campus></span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li id="room_parent_menu" class="m-menu__item  m-menu__item--submenu"
                                            aria-haspopup="true"
                                            m-menu-submenu-toggle="hover">
                                            <a href="javascript:;" class="m-menu__link m-menu__toggle">
                                                <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                <span class="m-menu__link-text" v-lang.room></span>
                                                <i class="m-menu__ver-arrow la la-angle-right"></i>
                                            </a>
                                            <div class="m-menu__submenu ">
                                                <span class="m-menu__arrow"></span>
                                                <ul class="m-menu__subnav">
                                                    <li id="room_type_menu" class="m-menu__item " aria-haspopup="true">
                                                        <a href="#/RoomType" class="m-menu__link ">
                                                            <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                            <span class="m-menu__link-text" v-lang.room_type></span>
                                                        </a>
                                                    </li>
                                                    <li id="sub_type_menu" class="m-menu__item " aria-haspopup="true">
                                                        <a href="#/RoomSubType" class="m-menu__link ">
                                                            <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                            <span class="m-menu__link-text" v-lang.room_sub_type></span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li id="device_parent_menu" class="m-menu__item  m-menu__item--submenu"
                                            aria-haspopup="true"
                                            m-menu-submenu-toggle="hover">
                                            <a href="javascript:;" class="m-menu__link m-menu__toggle">
                                                <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                <span class="m-menu__link-text" v-lang.capacity_equipment></span>
                                                <i class="m-menu__ver-arrow la la-angle-right"></i>
                                            </a>
                                            <div class="m-menu__submenu ">
                                                <span class="m-menu__arrow"></span>
                                                <ul class="m-menu__subnav">
                                                    <li id="brand_menu" class="m-menu__item " aria-haspopup="true">
                                                        <a href="#/Brand" class="m-menu__link ">
                                                            <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                            <span class="m-menu__link-text" v-lang.brand></span>
                                                        </a>
                                                    </li>
                                                    <li id="model_menu" class="m-menu__item " aria-haspopup="true">
                                                        <a href="#/Model" class="m-menu__link ">
                                                            <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                            <span class="m-menu__link-text" v-lang.model></span>
                                                        </a>
                                                    </li>
                                                    <li id="product_family_menu" class="m-menu__item "
                                                        aria-haspopup="true">
                                                        <a href="#/ProductFamily" class="m-menu__link ">
                                                            <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                            <span class="m-menu__link-text"
                                                                  v-lang.product_family></span>
                                                        </a>
                                                    </li>
                                                    <li id="infrastructure_menu" class="m-menu__item "
                                                        aria-haspopup="true">
                                                        <a href="#/Infrastructure" class="m-menu__link ">
                                                            <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                            <span class="m-menu__link-text"
                                                                  v-lang.infrastructure></span>
                                                        </a>
                                                    </li>
                                                    <li id="infrastructure_group_menu" class="m-menu__item "
                                                        aria-haspopup="true">
                                                        <a href="#/InfrastructureGroup" class="m-menu__link ">
                                                            <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                            <span class="m-menu__link-text"
                                                                  v-lang.infrastructure_group></span>
                                                        </a>
                                                    </li>
                                                    <li id="infrastructure_type_menu" class="m-menu__item "
                                                        aria-haspopup="true">
                                                        <a href="#/InfrastructureType" class="m-menu__link ">
                                                            <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                            <span class="m-menu__link-text"
                                                                  v-lang.infrastructure_type></span>
                                                        </a>
                                                    </li>
                                                    <li id="capacity_type_menu" class="m-menu__item "
                                                        aria-haspopup="true">
                                                        <a href="#/CapacityType" class="m-menu__link ">
                                                            <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                            <span class="m-menu__link-text" v-lang.capacity_type></span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li id="shift_parent_menu" class="m-menu__item  m-menu__item--submenu"
                                            aria-haspopup="true"
                                            m-menu-submenu-toggle="hover">
                                            <a href="javascript:;" class="m-menu__link m-menu__toggle">
                                                <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                <span class="m-menu__link-text" v-lang.shift></span>
                                                <i class="m-menu__ver-arrow la la-angle-right"></i>
                                            </a>
                                            <div class="m-menu__submenu ">
                                                <span class="m-menu__arrow"></span>
                                                <ul class="m-menu__subnav">
                                                    <li id="shift_type_menu" class="m-menu__item " aria-haspopup="true">
                                                        <a href="#/ShiftType" class="m-menu__link ">
                                                            <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                            <span class="m-menu__link-text" v-lang.shift_type></span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li id="maintenance_parent_menu" class="m-menu__item  m-menu__item--submenu"
                                            aria-haspopup="true"
                                            m-menu-submenu-toggle="hover">
                                            <a href="javascript:;" class="m-menu__link m-menu__toggle">
                                                <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                <span class="m-menu__link-text" v-lang.maintenance></span>
                                                <i class="m-menu__ver-arrow la la-angle-right"></i>
                                            </a>
                                            <div class="m-menu__submenu ">
                                                <span class="m-menu__arrow"></span>
                                                <ul class="m-menu__subnav">
                                                    <li id="maintenance_period_menu" class="m-menu__item "
                                                        aria-haspopup="true"
                                                        m-menu-submenu-toggle="hover">
                                                        <a href="#/MaintenancePeriod"
                                                           class="m-menu__link m-menu__toggle">
                                                            <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                            <span class="m-menu__link-text"
                                                                  v-lang.maintenance_period></span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li id="tool_parent_menu" class="m-menu__item  m-menu__item--submenu"
                                            aria-haspopup="true"
                                            m-menu-submenu-toggle="hover">
                                            <a href="javascript:;" class="m-menu__link m-menu__toggle">
                                                <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                <span class="m-menu__link-text" v-lang.tool></span>
                                                <i class="m-menu__ver-arrow la la-angle-right"></i>
                                            </a>
                                            <div class="m-menu__submenu ">
                                                <span class="m-menu__arrow"></span>
                                                <ul class="m-menu__subnav">
                                                    <li id="tool_type_menu" class="m-menu__item " aria-haspopup="true"
                                                        m-menu-submenu-toggle="hover">
                                                        <a href="#/ToolType" class="m-menu__link m-menu__toggle">
                                                            <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                            <span class="m-menu__link-text" v-lang.tool_type></span>
                                                        </a>
                                                    </li>
                                                    <li id="tool_sub_type_menu" class="m-menu__item "
                                                        aria-haspopup="true"
                                                        m-menu-submenu-toggle="hover">
                                                        <a href="#/ToolSubType" class="m-menu__link m-menu__toggle">
                                                            <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                            <span class="m-menu__link-text" v-lang.tool_sub_type></span>
                                                        </a>
                                                    </li>
                                                    <li id="warehouse_menu" class="m-menu__item " aria-haspopup="true"
                                                        m-menu-submenu-toggle="hover">
                                                        <a href="#/Warehouse" class="m-menu__link m-menu__toggle">
                                                            <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                            <span class="m-menu__link-text" v-lang.warehouse></span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li id="spare_part_parent_menu" class="m-menu__item  m-menu__item--submenu"
                                            aria-haspopup="true"
                                            m-menu-submenu-toggle="hover">
                                            <a href="javascript:;" class="m-menu__link m-menu__toggle">
                                                <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                <span class="m-menu__link-text" v-lang.spare_part></span>
                                                <i class="m-menu__ver-arrow la la-angle-right"></i>
                                            </a>
                                            <div class="m-menu__submenu ">
                                                <span class="m-menu__arrow"></span>
                                                <ul class="m-menu__subnav">
                                                    <li id="spare_part_category_menu" class="m-menu__item " aria-haspopup="true"
                                                        m-menu-submenu-toggle="hover">
                                                        <a href="#/SparePartCategory" class="m-menu__link m-menu__toggle">
                                                            <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                            <span class="m-menu__link-text" v-lang.spare_part_category></span>
                                                        </a>
                                                    </li>
                                                    <li id="spare_part_sub_category_menu" class="m-menu__item " aria-haspopup="true"
                                                        m-menu-submenu-toggle="hover">
                                                        <a href="#/SparePartSubCategory" class="m-menu__link m-menu__toggle">
                                                            <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                            <span class="m-menu__link-text" v-lang.spare_part_sub_category></span>
                                                        </a>
                                                    </li>
                                                    <li id="spare_part_kind_menu" class="m-menu__item " aria-haspopup="true"
                                                        m-menu-submenu-toggle="hover">
                                                        <a href="#/SparePartKind" class="m-menu__link m-menu__toggle">
                                                            <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                            <span class="m-menu__link-text" v-lang.spare_part_kind></span>
                                                        </a>
                                                    </li>
                                                    <li id="spare_part_type_menu" class="m-menu__item " aria-haspopup="true"
                                                        m-menu-submenu-toggle="hover">
                                                        <a href="#/SparePartType" class="m-menu__link m-menu__toggle">
                                                            <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                            <span class="m-menu__link-text" v-lang.spare_part_type></span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li id="point_parent_menu" class="m-menu__item  m-menu__item--submenu"
                                            aria-haspopup="true"
                                            m-menu-submenu-toggle="hover">
                                            <a href="javascript:;" class="m-menu__link m-menu__toggle">
                                                <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                <span class="m-menu__link-text" v-lang.point></span>
                                                <i class="m-menu__ver-arrow la la-angle-right"></i>
                                            </a>
                                            <div class="m-menu__submenu ">
                                                <span class="m-menu__arrow"></span>
                                                <ul class="m-menu__subnav">
                                                    <li id="point_definition_menu" class="m-menu__item "
                                                        aria-haspopup="true"
                                                        m-menu-submenu-toggle="hover">
                                                        <a href="#/PointDefinition" class="m-menu__link m-menu__toggle">
                                                            <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                            <span class="m-menu__link-text" v-lang.point_definition></span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                         <!--Unit-->
                                         <li id="unit_parent_menu" class="m-menu__item  m-menu__item--submenu"
                                            aria-haspopup="true"
                                            m-menu-submenu-toggle="hover">
                                            <a href="javascript:;" class="m-menu__link m-menu__toggle">
                                                <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                <span class="m-menu__link-text" v-lang.unit></span>
                                                <i class="m-menu__ver-arrow la la-angle-right"></i>
                                            </a>
                                            <div class="m-menu__submenu ">
                                                <span class="m-menu__arrow"></span>
                                                <ul class="m-menu__subnav">
                                                    <!--Unit Definition-->
                                                    <li id="unit_definition_menu" class="m-menu__item "
                                                        aria-haspopup="true"
                                                        m-menu-submenu-toggle="hover">
                                                        <a href="#/UnitDefinition" class="m-menu__link m-menu__toggle">
                                                            <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                            <span class="m-menu__link-text" v-lang.unit_definition></span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <!--DeviceType-->
                                        <li id="device_type_parent_menu" class="m-menu__item  m-menu__item--submenu"
                                            aria-haspopup="true"
                                            m-menu-submenu-toggle="hover">
                                            <a href="javascript:;" class="m-menu__link m-menu__toggle">
                                                <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                <span class="m-menu__link-text" v-lang.device></span>
                                                <i class="m-menu__ver-arrow la la-angle-right"></i>
                                            </a>
                                            <div class="m-menu__submenu ">
                                                <span class="m-menu__arrow"></span>
                                                <ul class="m-menu__subnav">
                                                    <!--Unit Definition-->
                                                    <li id="device_type_menu" class="m-menu__item "
                                                        aria-haspopup="true"
                                                        m-menu-submenu-toggle="hover">
                                                        <a href="#/DeviceType" class="m-menu__link m-menu__toggle">
                                                            <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                                            <span class="m-menu__link-text" v-lang.device_type></span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li id="vendors_menu" class="m-menu__item " aria-haspopup="true"
                                m-menu-submenu-toggle="hover">
                                <a href="#/Vendor" class="m-menu__link m-menu__toggle">
                                    <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                    <span class="m-menu__link-text" v-lang.vendors></span>
                                </a>
                            </li>
                            <li id="schedule_menu" class="m-menu__item " aria-haspopup="true"
                                m-menu-submenu-toggle="hover">
                                <a href="#/Schedule" class="m-menu__link m-menu__toggle">
                                    <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                    <span class="m-menu__link-text" v-lang.schedule></span>
                                </a>
                            </li>
                            <!--<li id="integrations_menu" class="m-menu__item  m-menu__item&#45;&#45;submenu" aria-haspopup="true"
                                                  m-menu-submenu-toggle="hover">
                                              <a href="javascript:;" class="m-menu__link m-menu__toggle">
                                                  <i class="m-menu__link-bullet m-menu__link-bullet&#45;&#45;dot"><span></span></i>
                                                  <span class="m-menu__link-text" v-lang.integrations></span>
                                                  <i class="m-menu__ver-arrow la la-angle-right"></i>
                                              </a>
                                              <div class="m-menu__submenu ">
                                                  <span class="m-menu__arrow"></span>
                                                  <ul class="m-menu__subnav">
                                                      <li id="maximo_menu" class="m-menu__item " aria-haspopup="true">
                                                          <a href="javascript:;" class="m-menu__link ">
                                                              <i class="m-menu__link-bullet m-menu__link-bullet&#45;&#45;dot"><span></span></i>
                                                              <span class="m-menu__link-text" v-lang.maximo></span>
                                                          </a>
                                                      </li>
                                                  </ul>
                                              </div>
                                          </li>-->
                            <li id="minimum_staff_menu" class="m-menu__item " aria-haspopup="true"
                                m-menu-submenu-toggle="hover">
                                <a href="#/MinimumStaff" class="m-menu__link">
                                    <i class="m-menu__link-bullet m-menu__link-bullet--dot"><span></span></i>
                                    <span class="m-menu__link-text" v-lang.minimum_staff_calculation></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
                <!--Settings end-->
            </ul>
        </div>
        <!-- END: Aside Menu -->
    </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    $('.sidebar-search input').on('keyup', function () {
      const value = $(this).val().toLocaleLowerCase(localStorage.getItem('vue-lang'));
      const list = [];
      if (value.length > 0) {
        $('.sidebar-search .remove-button').show();
      } else {
        $('.sidebar-search .remove-button').hide();
      }
      if (value !== '') {
        $('#m_ver_menu .m-menu__link').each(function () {
          if ($(this).text().toLocaleLowerCase(localStorage.getItem('vue-lang')).search(value) > -1 && $(this).attr('href') !== 'javascript:;') {
            list.push({
              url: $(this).attr('href'),
              title: $(this).text(),
            });
          }
        });
        $('#search_result').html('<ul></ul>');
        list.map((item) => {
          const menuItem = `<li><a href="${item.url}">${item.title}</a></li>`;
          $('#search_result ul').append(menuItem);
        });
      } else {
        $('#search_result').html('');
      }
    });
  },
};
</script>
