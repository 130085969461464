export default {
  home_page: 'Anasayfa',
  title: 'Başlık',
  inventory: 'Envanter',
  inventory_list: 'Envanter Listesi',
  add_inventory: 'Envanter Ekle',
  login: 'Giriş',
  logout: 'Çıkış',
  quick_actions: 'Hızlı Eylemler',
  shortcuts: 'Kısayollar',
  generate_report: 'Rapor Hazırla',
  add_new_event: 'Yeni Olay Ekle',
  create_new_task: 'Yeni Görev Ata',
  completed_tasks: 'Tamamlanan Görevler',
  save: 'Kaydet',
  clear: 'Temizle',
  save_continue: 'Kaydet & Devam Et',
  location: 'Lokasyon',
  building_floor: 'Bina & Kat',
  room_capacity_equipment: 'Oda & Kapasite Ekipmanı',
  room_capacity_eq: 'Oda ve Kapasite Ek.',
  back: 'Geri',
  submit: 'Gönder',
  not_found_page: 'Sayfa bulunamadı',
  sign_in: 'Giriş Yap',
  cancel: 'Vazgeç',
  apply: 'Uygula',
  forgotten_password: 'Şifremi Unuttum',
  email: 'E-Posta',
  parameters: 'Parametreler',
  continent: 'Kıta',
  country: 'Ülke',
  city: 'Şehir',
  district: 'İlçe',
  continents: 'Kıtalar',
  countries: 'Ülkeler',
  cities: 'Şehirler',
  districts: 'İlçeler',
  add_continent: 'Kıta Ekle',
  edit_continent: 'Kıta Düzenle',
  add_country: 'Ülke Ekle',
  edit_country: 'Ülke Düzenle',
  add_cities: 'Şehir Ekle',
  edit_cities: 'Şehir Düzenle',
  add_district: 'İlçe Ekle',
  edit_district: 'İlçe Düzenle',
  please_select: 'Lütfen Seçiniz',
  deleted: 'Silindi',
  are_you_sure: 'Emin misiniz?',
  are_you_sure_delete: 'Silmek istediğinize emin misiniz?',
  yes: 'Evet',
  no: 'Hayır',
  sort: 'Sırala',
  please_select_continent: 'Lütfen Kıta Seçiniz',
  please_select_country: 'Lütfen Ülke Seçiniz',
  please_select_city: 'Lütfen Şehir Seçiniz',
  please_select_district: 'Lütfen İlçe Seçiniz',
  please_select_building: 'Lütfen Bina Seçiniz',
  please_select_floor: 'Lütfen Kat Seçiniz',
  please_select_room: 'Lütfen Oda Seçiniz',
  please_select_training_type: 'Lütfen Eğitim Türü Seçiniz',
  please_select_capacity_equipment: 'Lütfen Kapasite Ekipmanı Seçiniz',
  please_select_work_category: 'Lütfen İş Kategorisi Seçiniz',
  please_select_capacity_type: 'Lütfen Kapasite Tipi Seçiniz',
  code: 'Kod',
  devices: 'Cihazlar',
  selected_location: 'Seçili Lokasyon',
  language: 'Dil',
  locations: 'Lokasyonlar',
  building: 'Bina',
  floor: 'Kat',
  add_building: 'Bina Ekle',
  edit_building: 'Bina Düzenle',
  building_name: 'Bina Adı',
  floor_count: 'Kat Sayısı',
  floor_name: 'Kat İsmi',
  add_new_floor:'Kat Ekle',
  reset: 'Sıfırla',
  add_room: 'Oda Ekle',
  edit_room: 'Oda Düzenle',
  room_name: 'Oda İsmi',
  device: 'Cihaz',
  add_device: 'Cihaz Ekle',
  edit_device: 'Cihaz Düzenle',
  device_name: 'Cihaz İsmi',
  device_count: 'Cihaz Adedi',
  device_types: 'Cihaz Tipleri',
  device_type: 'Cihaz Tipi',
  add_device_type: 'Cihaz Tipi Ekle',
  edit_device_type: 'Cihaz Tipi Düzenle',
  filter: 'Filtrele',
  show_more: 'Daha Fazla',
  show_less: 'Daha Az',
  more: 'Daha Fazla',
  area_size: 'Alan Büyüklüğü (m²)',
  room: 'Oda',
  rooms: 'Odalar',
  room_type: 'Oda Tipi',
  room_sub_type: 'Oda Alt Tipi',
  create_new: 'Yeni Oluştur',
  user_roles: 'Personel Rolleri',
  user_role: 'Personel Rolü',
  add_user_role: 'Personel Rolü Ekle',
  edit_user_role: 'Personel Rolü Düzenle',
  role_groups: 'Rol Grupları',
  brands: 'Markalar',
  brand: 'Marka',
  add_brand: 'Marka Ekle',
  edit_brand: 'Marka Düzenle',
  training_types: 'Eğitim Türleri',
  training_type: 'Eğitim Türü',
  add_training_type: 'Eğitim Türü Ekle',
  edit_training_type: 'Eğitim Türü Düzenle',
  trainings: 'Eğitimler',
  training: 'Eğitim',
  add_training: 'Eğitim Ekle',
  edit_training: 'Eğitim Düzenle',
  issue_date: 'Veriliş Tarihi',
  expire_date: 'Geçerlilik Tarihi',
  capacity_equipments: 'Kapasite Ekipmanları',
  capacity: 'Kapasite',
  equipments: 'Ekipmanları',
  capacity_equipment: 'Kapasite Ekipmanı',
  c_equipment: 'K.Ekipmanı',
  add_capacity_equipment: 'Kapasite Ekipmanı Ekle',
  edit_capacity_equipment: 'Kapasite Ekipmanı Düzenle',
  capacity_equipment_name: 'Kapasite Ekipmanı İsmi',
  capacity_equipment_count: 'Kapasite Ekipmanı Adedi',
  users: 'Personeller',
  user: 'Personel',
  add_user: 'Personel Ekle',
  edit_user: 'Personel Düzenle',
  roles: 'Roller',
  month_0: 'Ocak',
  month_1: 'Şubat',
  month_2: 'Mart',
  month_3: 'Nisan',
  month_4: 'Mayıs',
  month_5: 'Haziran',
  month_6: 'Temmuz',
  month_7: 'Ağustos',
  month_8: 'Eylül',
  month_9: 'Ekim',
  month_10: 'Kasım',
  month_11: 'Aralık',
  models: 'Modeller',
  model: 'Model',
  add_model: 'Model Ekle',
  edit_model: 'Model Düzenle',
  please_select_brand: 'Lütfen Marka Seçiniz',
  work_order_types: 'İş Emri Tipleri',
  work_order_type: 'İş Emri Tipi',
  add_work_order_type: 'İş Emri Tipi Ekle',
  edit_work_order_type: 'İş Emri Tipi Düzenle',
  work_categories: 'İş Kategorileri',
  work_category: 'İş Kategorisi',
  add_work_category: 'İş Kategorisi Ekle',
  edit_work_category: 'İş Kategorisi Düzenle',
  jobs: 'Meslekler',
  job: 'Meslek',
  add_job: 'Meslek Ekle',
  edit_job: 'Meslek Düzenle',
  qualifications: 'Yetkinlikler',
  qualification: 'Yetkinlik',
  add_qualification: 'Yetkinlik Ekle',
  edit_qualification: 'Yetkinlik Düzenle',
  qualification_types: 'Yetkinlik Tipleri',
  qualification_type: 'Yetkinlik Tipi',
  add_qualification_type: 'Yetkinlik Tipi Ekle',
  edit_qualification_type: 'Yetkinlik Tipi Düzenle',
  please_select_qualification_type: 'Lütfen Yetkinlik Tipi Seçiniz',
  infrastructures: 'Altyapılar',
  infrastructure: 'Altyapı',
  add_infrastructure: 'Altyapı Ekle',
  edit_infrastructure: 'Altyapı Düzenle',
  infrastructure_types: 'Altyapı Tipleri',
  infrastructure_type: 'Altyapı Tipi',
  add_infrastructure_type: 'Altyapı Tipi Ekle',
  edit_infrastructure_type: 'Altyapı Tipi Düzenle',
  please_select_infrastructure: 'Lütfen Altyapı Seçiniz',
  capacity_types: 'Kapasite Tipleri',
  capacity_type: 'Kapasite Tipi',
  add_capacity_type: 'Kapasite Tipi Ekle',
  edit_capacity_type: 'Kapasite Tipi Düzenle',
  please_select_infrastructure_type: 'Lütfen Altyapı Tipi Seçiniz',
  product_families: 'Ürün Aileleri',
  product_family: 'Ürün Ailesi',
  add_product_family: 'Ürün Ailesi Ekle',
  edit_product_family: 'Ürün Ailesi Düznle',
  shift: 'Vardiya',
  shift_calendar: 'Vardiya Takvimi',
  shift_types: 'Vardiya Tipleri',
  shift_type: 'Vardiya Tipi',
  add_shift_type: 'Vardiya Tipi Ekle',
  edit_shift_type: 'Vardiya Tipi Düzenle',
  start_time: 'Başlangıç Zamanı',
  end_time: 'Bitiş Zamanı',
  color: 'Renk',
  name: 'Ad',
  surname: 'Soyad',
  password: 'Parola',
  phone_number: 'Telefon Numarası',
  source_count: 'Kaynak Sayısı',
  output_count: 'Çıkış Sayısı',
  source: 'Kaynak',
  device_field_code: "Cihaz Saha Kodu",
  output: 'Çıkış',
  serial_number: 'Seri Numarası',
  production_year: 'Üretim Yılı',
  date_of_installation: 'Kurulum Tarihi',
  end_of_guarantee_date: 'Garanti Bitiş Tarihi',
  next: 'Sonraki',
  update_calendar: 'Takvimi Güncelle',
  vendors: 'Vendorlar',
  vendor: 'Vendor',
  upload_vendor_signature: 'Vendor İmzası Yükle',
  add_vendor: 'Vendor Ekle',
  edit_vendor: 'Vendor Düzenle',
  user_types: 'Personel Tipleri',
  user_type: 'Personel Tipi',
  add_user_type: 'Personel Tipi Ekle',
  edit_user_type: 'Personel Tipi Düzenle',
  staff: 'Personel',
  please_select_room_type: 'Lütfen Oda Tipi Seçiniz',
  please_select_room_sub_type: 'Lütfen Oda Alt Tipi Seçiniz',
  shift_calendar_updated: 'Vardiya takvimi güncellendi.',
  organizations: 'Organizasyonlar',
  organization: 'Organizasyon',
  add_organization: 'Organizasyon Ekle',
  edit_organization: 'Organizasyon Düzenle',
  loading: 'Yükleniyor',
  first_step: '1. Adım',
  second_step: '2. Adım',
  third_step: '3. Adım',
  shift_calendar_error: 'Başangıç tarihi geçmiş vardiyalara personel eklenemz.',
  maintenance_plan: 'Bakım Planlama',
  maintenance_plans: 'Bakım Planlamaları',
  add_maintenance_plan: 'Bakım Planlama Ekle',
  edit_maintenance_plan: 'Bakım Planlama Düzenle',
  maintenance_count: 'Bakım Adedi',
  maintenance_duration: 'Bakım Süresi',
  start_date: 'Başlangıç Tarihi',
  end_date: 'Bitiş Tarihi',
  notes: 'Notlar',
  time_spent_minutes: 'Harcanan Süre (Dakika)',
  timeline: 'Geçmiş',
  maintenance_type: 'Bakım Türü',
  shift_exist_error_message: 'Bu vardiya tanımlı',
  shift_not_selected_error_message: 'lütfen bir vardiya seçin',
  maintenance: 'Bakım',
  plans: 'Planlamaları',
  maintenance_periods: 'Bakım Periyotları',
  maintenance_period: 'Bakım Periyodu',
  add_maintenance_period: 'Bakım Periyodu Ekle',
  edit_maintenance_period: 'Bakım Periyodu Düzenle',
  time: 'Zaman',
  unit: 'Birim',
  unit_price_currency:'Para Birimi',
  unit_price: 'Birim Fiyatı',
  units: 'Birimler',
  unit_definition: 'Birim Tanımı',
  add_unit: 'Birim Ekle',
  edit_unit: 'Birim Düzenle',
  unit_type: 'Birim Tipi',
  unit_sign: 'Sembol',
  room_types: 'Oda Tipleri',
  add_room_type: 'Oda Tipi Ekle',
  edit_room_type: 'Oda Tipi Düzenle',
  room_sub_types: 'Oda Alt Tipleri',
  add_room_sub_type: 'Oda Alt Tipi Ekle',
  edit_room_sub_type: 'Oda Alt Tipi Düzenle',
  works: 'İşler',
  work: 'İş',
  add_work: 'İş Ekle',
  edit_work: 'İş Düzenle',
  work_count: 'İş Adedi',
  please_select_date_range: 'Lütfen Tarih Aralığı Seçiniz',
  today: 'Bugün',
  tomorrow: 'Yarın',
  this_week: 'Bu Hafta',
  two_week: 'İki Hafta',
  this_month: 'Bu Ay',
  this_year: 'Bu Yıl',
  custom_range: 'Özel',
  search: 'Ara',
  please_select_campus: 'Lütfen Kampüs Seçiniz',
  campuses: 'Kampüsler',
  campus: 'Kampüs',
  add_campus: 'Kampüs Ekle',
  edit_campus: 'Kampüs Düzenle',
  maintenance_plan_calendar: 'Bakım Planlama Takvimi',
  please_select_job: 'Lütfen Meslek Seçiniz',
  please_select_role: 'Lütfen Rol Seçiniz',
  please_select_user_type: 'Lütfen Tipi Seçiniz',
  please_select_vendor: 'Lütfen Vendor Seçiniz',
  please_select_organization: 'Lütfen Organizasyon Seçiniz',
  please_select_qualification: 'Lütfen Yetkinlik Seçiniz',
  please_select_training: 'Lütfen Eğitim Seçiniz',
  please_select_order_status: 'Lütfen Durum Seçiniz',
  edit: 'Düzenle',
  delete: 'Sil',
  dashboard: 'Dashboard',
  executive_dashboards: 'Yönetici Göstergeleri',
  calendar: 'Takvim',
  topologic_view: 'Topolojik Görünüm',
  work_order: 'İş Emri',
  work_orders: 'İş Emirleri',
  orders: 'Emirleri',
  cases: 'Olaylar',
  alarms: 'Alarmlar',
  maintenance_and_operations: 'Bakım ve Operasyonlar',
  preventive_maintenance_schedule: 'Önleyici Bakım Listesi',
  daily_checks: 'Günlük Kontroller',
  organizational_chart: 'Organizasyon Şeması',
  staff_list: 'Personel Listesi',
  roles_and_responsibilities: 'Roller ve Sorumluluklar',
  assets: 'Varlıklar',
  buildings: 'Binalar',
  library: 'Kütüphane',
  procedures: 'Prosedürler',
  mop: 'MoP',
  sop: 'SoP',
  eop: 'EoP',
  t_and_c_reports: 'T&C Raporları',
  l1: 'L1',
  l2: 'L2',
  l3: 'L3',
  l4: 'L4',
  electrical: 'Elektrik',
  mechanical: 'Mekanik',
  air_to_air: 'Havadan Havaya',
  chiller: 'Soğutucu',
  l5: 'L5',
  settings: 'Ayarlar',
  staffing: 'Organizasyon',
  constrains: 'Kısıtlar',
  asset: 'Varlık',
  integrations: 'Entegrasyonlar',
  maximo: 'Maximo',
  maintenance_plan_calendar_updated: 'Bakım planı güncellendi.',
  organization_scheme: 'Organizasyon Şeması',
  inventory_added: 'Envanter Eklendi.',
  description: 'Açıklama',
  position: 'Pozisyon',
  new: 'Yeni',
  nine_new: '7 Yeni',
  user_notification: 'Kullanıcı Bildirimi',
  alerts: 'Uyarılar',
  events: 'Olaylar',
  logs: 'Olay Günlükleri',
  notifications_1: '3 önleyici bakım zamanlanmamış.',
  notifications_1_time: 'Şimdi',
  notifications_2: 'Günlük kontroller tamamlandı.',
  notifications_2_time: '13 dakika',
  notifications_3: '2 bakımın durumu gecikmiş olarak ayarlandı.',
  notifications_3_time: '20 dakika',
  notifications_4: '1 önleyici bakım zamanlanmamış.',
  notifications_4_time: '1 saat',
  notifications_4_status: 'Acil',
  notifications_5: 'Atanmamış 2 iş yaklaşıyor.',
  notifications_5_time: 'Şimdi',
  notifications_6: '2 iş gecikti.',
  notifications_6_time: '20 dakika',
  notifications_7: 'Pazartesi için yeterli personel yok.',
  notifications_7_time: '5 saat',
  all_caught_up: '',
  no_new_logs: 'Yeni log yok.',
  transform_to_work_order: 'İş Emrine Dönüştür',
  add_work_order: 'İş Emri Ekle',
  edit_work_order: 'İş Emri Düzenle',
  order_status: 'Durum',
  save_successfully: 'Başarıyla kayıt edildi.',
  update_successfully: 'Başarıyla güncellendi.',
  delete_successfully: 'Başarıyla silindi.',
  role_qualifications: 'Rol Yetkinliği Ekle',
  schedules: 'Takvimlendirmeler',
  schedule: 'Takvimlendirme',
  schedule_name: 'Takvimlendirme Adı',
  add_schedule: 'Takvimlendirme Ekle',
  edit_schedule: 'Takvimlendirme Düzenle',
  export_excel: 'Excel\'e aktar',
  one_time: 'Bir Kez',
  daily: 'Günlük',
  weekly: 'Haftalık',
  monthly: 'Aylık',
  monthly_days: 'Ayın Günleri',
  monthly_week_days: 'Ayın Hafta Günleri',
  duration: 'Süre',
  expected_duration: 'Beklenen Süre',
  convenience: 'Uygunluk',
  schedule_duration: 'Süre (Dakika)',
  repeat_every_n_day: 'Tekrarlama (Gün)',
  repeat_every_n_week: 'Tekrarlama (Hafta)',
  day_name_1: 'Pazartesi',
  day_name_2: 'Salı',
  day_name_3: 'Çarşamba',
  day_name_4: 'Perşembe',
  day_name_5: 'Cuma',
  day_name_6: 'Cumartesi',
  day_name_7: 'Pazar',
  month_name_1: 'Ocak',
  month_name_2: 'Şubat',
  month_name_3: 'Mart',
  month_name_4: 'Nisan',
  month_name_5: 'Mayıs',
  month_name_6: 'Haziran',
  month_name_7: 'Temmuz',
  month_name_8: 'Ağustos',
  month_name_9: 'Eylül',
  month_name_10: 'Ekim',
  month_name_11: 'Kasım',
  month_name_12: 'Aralık',
  last: 'Son',
  days: 'Günler',
  weeks: 'Haftalar',
  months: 'Aylar',
  week: 'Hafta',
  ordinal_numbers_1: 'Birinci',
  ordinal_numbers_2: 'İkinci',
  ordinal_numbers_3: 'Üçüncü',
  ordinal_numbers_4: 'Dördüncü',
  type: 'Tip',
  company: 'Firma',
  procedure: 'Prosedür',
  add_procedure: 'Prosedür Ekle',
  edit_procedure: 'Prosedür Düzenle',
  file: 'Dosya',
  files: 'Dosyalar',
  please_select_product_family: 'Lütfen Ürün Ailesi Seçiniz',
  file_is_not_suitable: 'Seçtiğiniz Dosya Uygun Değil',
  max_file_size: 'Maksimum Dosya Büyüklüğü',
  no_result: 'Sonuç bulunamadı.',
  session_timeout_notification: 'Oturum Zaman Aşımı Bildirimi',
  your_session_is_about_to_expire: 'Oturumunuzun Süresi Dolmak Üzere',
  redirecting_in_seconds: '{timer} saniye içerisinde yönlendirileceksiniz.',
  stay_connected: 'Bağlı Kal',
  current_file: 'Geçerli Dosya',
  personal_information: 'Kişisel Bilgiler',
  organization_qualification: 'Organizasyon & Yetkinlikler',
  constraints: 'Kısıtlar',
  constraint: 'Kısıt',
  add_constraint: 'Kısıt Ekle',
  edit_constraint: 'Kısıt Düzenle',
  please_select_constraint_type: 'Lütfen Kısıt Türünü Seçiniz',
  select_all: 'Hepsini Seç',
  begin_date: 'Başlangıç Tarihi',
  compulsory: 'Zorunlu',
  all: 'Hepsi',
  training_material: 'Eğitim Materyali',
  training_materials: 'Eğitim Materyalleri',
  add_training_material: 'Eğitim Materyali Ekle',
  edit_training_material: 'Eğitim Materyali Düzenle',
  training_assessments: 'Eğitim Değerlendirmeleri',
  training_assessment: 'Eğitim Değerlendirme',
  add_training_assessment: 'Eğitim Değerlendirme Ekle',
  edit_training_assessment: 'Eğitim Değerlendirme Düzenle',
  certificated_training: 'Sertifikalı Eğitim',
  training_duration: 'Süre (Saat)',
  raci_matrix: 'Sorumluluk Matrisi',
  responsible: 'Sorumlu',
  accountable: 'Mesul',
  consulted: 'Danışılan',
  informed: 'Bilgilendirilen',
  procedure_type: 'Prosedür Tipi',
  remember_me: 'Beni Hatırla',
  forgot_password: 'Şifremi Unuttum',
  enter_email_reset_password: 'Şifreninizi sıfırlamak için e-posta adresinizi giriniz',
  main_work: 'Ana İş',
  report_role: 'Rapor Verdiği Rol',
  predecessor_inheritances: 'Vekalet Aldığı',
  successor_inheritances: 'Vekalet Verdiği',
  please_fill_all_required_fields: 'Lütfen Tüm Zorunlu Alanları Doldurunuz',
  add_new_work_to_work_order: 'İş Emrine Yeni Bir İş Ekle',
  accepted_file_extensions: 'Kabul Edilen Dosya Uzantıları',
  min_staff_calculation: 'Min. Personel Hesaplama',
  minimum_staff_calculation: 'Minimum Personel Hesaplama',
  min_staff_section_one_text: 'Mevcut Çalışma Saatlerini Belirle',
  min_staff_section_two_text: 'Ortalama Ciro Oranını Hesapla',
  min_staff_section_three_text: 'Kapsam Pozisyonları İçin Personel Tahmini',
  min_staff_section_four_text: 'Saatlik İşlem Kapasitesini Hesapla',
  min_staff_section_five_text: 'İş Hacmine Göre Gereken Çalışan',
  first_section_first_element: 'Tam zamalı çalışan için toplam saat (yıllık)',
  first_section_second_element: 'Ortalama tatil ve tatil izni (toplam saat)',
  first_section_third_element: 'Ortalama hastalık izni (toplam saat)',
  first_section_fourth_element: 'Ortalama kişisel izin (toplam saat)',
  first_section_fifth_element: 'Eğitime ayrılan ortalama zaman (toplam saat)',
  first_section_sixth_element: 'Ortalama askerlik, FMLA ayrılma, vb. (toplam saat)',
  first_section_seventh_element: 'Ortalama öğle yemeği ve mola süresi (toplam saat)',
  first_section_eighth_element: 'Diğer İzinler (toplantılar, özel görevler vb.)(toplam saat)',
  second_section_first_element: 'O yıl için en yüksek personel düzeyinde çalışan sayısı',
  second_section_second_element: 'Deneme süresini tamamlayamayan yeni işe alımların sayısı',
  second_section_third_element: 'Herhangi bir nedenle ayrılan deneyimli çalışan sayısı',
  third_section_first_element: 'Bu pozisyon için kapsanması gereken toplam konsol sayısı',
  third_section_second_element: 'Kapsanması gereken günlük saat sayısı',
  third_section_third_element: 'Kapsanması gereken haftada geçen gün sayısı',
  third_section_fourth_element: 'Kapsanması gereken yıllık hafta sayısı',
  fourth_section_first_element: 'Ortalama bakım süresi dakika',
  fourth_section_second_element:
        'Ortalama bakım tamamlanma süresi (dakikalar içinde bu, veri girişi için zaman, adres doğrulama, vb. içerir)',
  total_hours_needing_coverage: 'Kapsam ihtiyacı toplam saat',
  average_processing_time: 'Ortalama işlem süresi',
  average_hourly_processing_capability: 'Ortalama Saatlik İşleme Yeteneği',
  is_qualified: 'Yetkinlik',
  is_trained: 'Eğitim',
  is_suitable_role: 'Rol',
  is_suitable_shift: 'Vardiya',
  total_unavailable_time: 'Toplam çalışalamayan zaman',
  net_available_work_hours: 'Net çalışma saati',
  please_select_year: 'Lütfen yıl seçiniz',
  turnover_rate: 'Personel Değişim Oranı',
  average_turnover: 'Ortalama ersonel Değişim Hacmi',
  full_time_equivalent_base_estimate: 'Tam Zamanlı Eşdeğer',
  full_time_equivalent_required_to_accommodate_turnover:
        'Personel değişimini karşılamak için gereken Tam Zamanlı Eşdeğeri',
  retention_rate: 'Muhafaza edilen personel Oranı',
  fifth_section_first_element: 'Toplam Bakım Sayısı',
  fifth_section_second_element: 'Ortalama İşlem Süresi',
  hourly_processing_capability: 'Saatlik İşleme Kapasitesi',
  workload_in_hours: 'Saatlik İş Tükü',
  agent_occupancy_rate: 'Kişi Doluluk Oranı',
  availability_per_person: 'Kişi başı Gerçek Uygunluk',
  change_password: 'Parola Değiştir',
  current_password: 'Mevcut Parola',
  new_password: 'Yeni Parola',
  new_password_repeat: 'Yeni Parola Tekrar',
  password_changed: 'Parolanız değiştirildi.',
  there_is_no_work_order: '{date} tarihinde hiç iş emri yok.',
  there_is_n_work_order: '{date} tarihinde {count} iş emri var.',
  you_will_be_redirected: 'Yönlendirileceksiniz.',
  lower_than_n_work_order: '{min} ve daha az iş emri var.',
  upper_than_n_work_order: '{max} ve daha fazla iş emri var.',
  inner_than_n_work_order: '{down} ile {up} arasında emri var.',
  amount: 'Miktar',
  work_order_density: 'İş Emri Yoğunluğu',
  shift_staff_amount: 'Vardiya Personel Sayısı',
  work_order_statuses: 'İş Emri Durumları',
  work_order_status: 'İş Emri Durumu',
  password_reset_email_sent: 'Parola sıfırlama e-postası gönderildi',
  training_assessment_types: 'Eğitim Değerlendirme Tipleri',
  training_assessment_type: 'Eğitim Değerlendirme Tipi',
  add_training_assessment_type: 'Eğitim Değerlendirme Tipi Ekle',
  edit_training_assessment_type: 'Eğitim Değerlendirme Tipi Düzenle',
  evaluation_score: 'Değerlenirme Puanı',
  passing_score: 'Geçme Puanı',
  content: 'İçerik',
  purpose: 'Amaç',
  material_name: 'Materyal İsmi',
  please_wait_for_uploads: 'Lütfen dosya yüklemelerinin bitmesini bekleyiniz.',
  please_select_url_or_file: 'Lütfen URL Veya Dosya Giriniz.',
  please_select_current_url: 'Lütfen Geçerli bir URL Giriniz.',
  training_instructor_role: 'Eğitimi Veren Rol',
  training_method: 'Eğitim Metodu',
  user_training_operations: 'Kullanıcı Eğitim İşlemleri',
  user_assessment_operations: 'Kullanıcı Değerlendirme İşlemleri',
  training_and_assessment_operations: 'Eğitim ve Değerlendirme İşlemleri',
  user_trainings: 'Kullanıcı Eğitimleri',
  user_training: 'Kullanıcı Eğitimi',
  user_not_found: 'Herhangi Bir Kullanıcı Bulunamadı',
  edit_user_training: 'Eğitim Düzenle',
  add_user_training: 'Eğitim Ekle',
  user_assessment: 'Kullanıcı Değerlendirmesi',
  user_assessments: 'Kullanıcı Değerlendirmeleri',
  edit_user_assessment: 'Değerlendirme Düzenle',
  add_user_assessment: 'Değerlendirme Ekle',
  no_qualifications: 'Bu Rolde Yetkinlik Bulunmamakta',
  qualifications_from_role: 'Rol Yetkinlikleri',
  deleted_successfully: 'Başarıyla Silindi',
  please_select_operation: 'Lütfen Aksiyon Seçiniz',
  add_assessment: 'Değerlendirme Ekle',
  assessment_score: 'Değerlendirme Skoru',
  assessment_date: 'Değerlendirme Tarihi',
  assessment: 'Değerlendirme',
  work_groups: 'İş Grupları',
  work_group: 'İş Grubu',
  add_work_group: 'İş Grubu Ekle',
  edit_work_group: 'İş Grubu Düzenle',
  infrastructure_groups: 'Altyapı Grupları',
  infrastructure_group: 'Altyapı Grubu',
  edit_infrastructure_group: 'Altyapı Grubu Düzenle',
  add_infrastructure_group: 'Altyapı Grubu Ekle',
  please_select_infrastructure_group: 'Lütfen Altyapı Grubu Seçiniz',
  explanation: 'Açıklama',
  please_select_work_group: 'Lütfen İş Grubu Seçiniz',
  date_of_employment: 'İşe Giriş Tarihi',
  overdue: 'Geçmiş',
  upcoming: 'Yaklaşan',
  my_work_orders: 'İş Emirlerim',
  my_daily_work_orders: 'Günlük İş Emirlerim',
  my_work_order: 'İş Emrim',
  minutes: 'dakika',
  start: 'Başlangıç',
  end: 'Bitiş',
  role_trainings: 'Rol Eğitimleri',
  you_have_unsaved_changes_do_you_want_to_leave: 'Ayrılmak istiyor musun? Kaydedilmemiş değişiklikleriniz mevcut!',
  year: 'Yıl',
  training_tracking: 'Eğitim Takibi',
  training_tracking_by_training: 'Eğitim Bazlı Eğitim Takibi',
  user_training_tracking: 'Kullanıcı Eğitim Takip',
  user_training_trackings: 'Kullanıcı Eğitim Takipleri',
  no_expire_date: 'Bitiş süresi tanımlanmamış',
  remaining_days: 'Kalan Gün',
  month: 'Ay',
  repetition_frequency: 'Tekrarlama Sıklığı',
  job_description: 'Görev Tanımı',
  change_status: 'Durum Değiştir',
  order_sub_status: 'Durum Detayı',
  current_order_status: 'Aktif Durum',
  current_order_sub_status: 'Aktif Durum Detayı',
  sub_work_orders: 'Alt İş Emirleri',
  name_surname: 'Ad Soyad',
  master: 'İşi Yapan',
  masters: 'İşi Yapan Kişiler',
  observer: 'Refakatçi',
  observers: 'Refakatçiler',
  show_on_calendar: 'Takvimde Göster',
  hide: 'Gizle',
  abbreviation_minute: 'min',
  empty: 'Boş',
  status: 'Durum',
  no_current_sub_status: 'Aktif Durum Detayı Yok',
  security_items: 'Güvenlik Maddeleri',
  security_item: 'Güvenlik Maddesi',
  add_security_item: 'Güvenlik Maddesi Ekle',
  edit_security_item: 'Güvenlik Maddesi Düzenle',
  send_activation_mail: 'Aktivasyon Maili At',
  reset_password: 'Şifre Sıfırlama',
  ui_item: 'Arayüz Nesnesi',
  items: 'Nesneler',
  authorize: 'Yetki',
  security_role_items: 'Güvenlik Rol Maddeleri',
  security_role_item: 'Güvenlik Rol Maddesi',
  will_be_deleted_permanently: 'Kalıcı olarak silinecektir. Emin misiniz?',
  work_order_time_conflict_title: 'İşi atamak istediğinize emin misiniz ?',
  work_order_time_conflict_description: 'Seçilen zaman diliminde kullanıcıya tanimlı bir iş vardır.',
  display_error_404: 'OOPS! Bir şeyler yanlış gitti.',
  display_error_401: 'Yetkisiz Erişim.',
  redirect_to_home: 'Ana Sayfa',
  security_item_type: 'Güvenlik Maddesi Türü',
  is_certificated_training: 'Sertifikalı Eğitim',
  experience: 'Deneyim',
  empty_topologic_view: 'Seçilen bina için topolojik cihaz bilgisi bulunmamaktadır.',
  instructor: 'Eğitmen',
  work_order_duration: 'Süresi (Dakika)',
  work_constraints: 'İş Kısıtları',
  device_constraints: 'Ciihazı Kısıtları',
  room_constraints: 'Oda Kısıtları',
  day: 'Gün',
  hour: 'Saat',
  minute: 'Dakika',
  total_effective_capacity: 'Toplam Efektif Kapasite',
  altitude_meter: 'Rakım(m)',
  relative_humidity: 'Bağıl Nem',
  please_select_model: 'Lütfen Model Seçiniz',
  component: 'Komponent',
  add_component: 'Komponent Ekle',
  add_new_record: 'Yeni Kayıt Ekle',
  edit_component: 'Komponent Düzenle',
  remove_component: 'Komponent Sil',
  edit_record: 'Kaydı Düzenle',
  remove_record: 'Kaydı Sil',
  record_name: 'Kayıt İsmi',
  selected_maintenance_periods: 'Seçili Bakım Periyotları',
  reference_library: 'Referans Kütüphanesi',
  reference_library_item: 'Referans Kütüphanesi Maddesi',
  add_reference_library_item: 'Referans Kütüphanesi Maddesi Ekle',
  edit_reference_library_item: 'Referans Kütüphanesi Maddesi Düzenle',
  dry_bulb_temperature_min_max: 'Kuru Termometre Sıcaklığı Asgari Azami',
  wet_bulb_temperature_min_max: 'Yaş Termometre Sıcaklığı Asgari Azami',
  document_status: 'Doküman Durumu',
  validity_status: 'Geçerlilik Durumu',
  version: 'Versiyon',
  control_frequency: 'Kontrol Sıklığı',
  last_check_date: 'Son Kontrol Tarihi',
  add_description: 'Açıklama Ekle',
  edit_description: 'Açıklama Düzenle',
  text: 'Metin',
  related_device: 'İlişkili Cihaz',
  supply_day_count: 'Tedarik Gün Sayısı',
  critical_stock_count: 'Kritik Stok Sayısı',
  critical_stock_level: 'Kritik Stok Seviyesi',
  spare_parts_stock_price: 'Yedek Parça Stok Bedeli',
  sp_stock_price: 'Ort. Birim Fiyatı',
  parts_replacement_price: 'Parça Değişim Bedeli',
  required_man_hour_price: 'Adam/Saat Gereksinim Bedeli',
  annual_contract_price: 'Yıllık Sözleşme Bedeli',
  contract_duration: 'Garanti Süresi (Ay)',
  contract_start_date: 'Garanti Başlangıç Tarihi',
  service_provider: 'Servis Sağlayıcı',
  date_of_warranty_start: 'Garanti Başlangıç Tarihi',
  warranty_duration: 'Garanti Süresi (Ay)',
  expected_life_end_date: 'Tahmini Ömür Bitiş Tarihi',
  suggested_run_hour: 'Önerilen Çalışma Süresi (Saat)',
  mean_time_to_failure_theoretical_hour: 'Ortalama Teorik Arıza Süresi (Saat)',
  mean_time_to_failure_statistical_hour: 'Ortalama İstatistiksel Arıza Süresi (Saat)',
  mean_time_between_failure_theoretical_hour: 'İki Arıza Arası Ortalama Teorik Süre (Saat)',
  mean_time_between_failure_statistical_hour: 'İki Arıza Arası Ortalama İstatistiksel Süre (Saat)',
  mean_time_to_repair_theoretical_hour: 'Onarıma Kadar Geçen Ortalama Teorik Süre (Saat)',
  mean_time_to_repair_statistical_hour: 'Onarıma Kadar Geçen Ortalama İstatistiksel Süre (Saat)',
  sla: 'SLA (Saat)',
  service_level_agreement: 'Hizmet Seviyesi Sözleşmesi  (SLA)',
  show_on_organization_chart: 'Organizasyon Şemasında Göster',
  number_of_trainings_to_be_taken_less_than_n_days: '{0} Gün İçinde Süresi Dolan Veya Eksik Eğitimlerin Sayısı',
  number_of_users_need_to_be_get_training_less_than_n_days: '{0} Gün İçinde Eğitim Alması Gereken Personel Sayısı',
  training_demand: 'Gereken Eğitim',
  please_select_service_provider: 'Lütfen Servis Sağlayıcısı Seçiniz',
  scheduled_date: 'Planlanan Tarih',
  scheduled_hour: 'Planlanan Saat',
  scheduled_duration: 'Planlanan Süre',
  scheduled_end_time: 'Planlanan Bitiş Zamanı',
  invalid_answer:'Geçersiz Cevap Sayısı',
  number_of_feedback:'Geri Bildirim Sayısı',
  mass_work_order: 'Toplu Bakım',
  mass_work_orders: 'Toplu Bakımlar',
  add_mass_work_order: 'Toplu Bakım Ekle',
  edit_mass_work_order: 'Toplu Bakım Düzenle',
  total_plan: 'Toplam Plan',
  total_duration: 'Toplam Süre',
  maintenance_group: 'Bakım Grubu',
  maintenance_plan_of_device_name: '{deviceName} ekipmanının bakım planı',
  selected: 'Seçilen',
  you_must_select_work_for_all_checked_equipments: 'Seçilen tüm ekipmanlar için iş seçimi yapınız.',
  you_must_select_at_least_one_equipment: 'En az bir ekipman seçmelisiniz.',
  last_maintenance_date: 'Son Bakım Tarihi',
  all_selected_data_will_disappear: 'Seçilen veriler kaybolacak.',
  you_must_select_at_least_one_work: 'En az bir iş seçmelisiniz.',
  will_remain_as_maintenance_plan: 'Bakım planı olarak kalacaktır.',
  please_enter_a_cancellation_description: 'Lütfen iptal açıklaması giriniz.',
  training_not_being_enrolled: 'Eğitim alınmamıştır.',
  work_orders_pending_approval: 'Onay Bekleyen İş Emirleri',
  approve: 'Onayla',
  approved: 'Onaylandı',
  decline: 'Reddet',
  declined: 'Reddedildi',
  showing_count: '{2} kayıttan {0} - {1} arasındaki kayıtlar gösteriliyor',
  first: 'İlk',
  previous: 'Önceki',
  forms: 'Formlar',
  form: 'Form',
  add_form: 'Form Ekle',
  edit_form: 'Formu Düzenle',
  image: 'Görüntü',
  definition: 'Tanım',
  questions: 'Sorular',
  question: 'Soru',
  add_question: 'Soru Ekle',
  edit_question: 'Soru Düzenle',
  lifetime: 'Yaşam Ömrü',
  topology: 'Topoloji',
  financial: 'Finansal',
  edit_description_item: 'Açıklama Düzenle',
  add_description_item: 'Açıklama Ekle',
  edit_service_provider_item: 'Servis Sağlayıcısı Maddesi Düzenleme',
  add_service_provider_item: 'Servis Sağlayıcısı Maddesi Ekleme',
  edit_financial_item: 'Finansal Düzenleme',
  add_financial_item: 'Finansal Ekleme',
  confirm: 'Onayla',
  deny: 'Reddet',
  work_order_status_descriptions: 'İş Emri Durum Açıklamaları',
  work_order_status_description: 'İş Emri Durum Açıklaması',
  add_work_order_status_description: 'İş Emri Durum Açıklaması Ekle',
  edit_work_order_status_description: 'İş Emri Durum Açıklaması Düzenle',
  you_will_be_redirected_to_date: '{0} tarihine yönlendirileceksiniz.',
  you_will_be_redirected_to_page: '{0} sayfasına yönlendirileceksiniz.',
  form_status: 'Form Durumu',
  please_hover_for_further_information: 'Daha fazla bilgi için lütfen üzerine gelin.',
  add_new: 'Yeni',
  apply_all: 'Hepsine Uygula',
  print: 'Yazdır',
  clone: 'Klon',
  started: 'Başladı',
  not_started: 'Başlamadı',
  form_started: 'Form Başlatıldı',
  form_did_not_start: 'Başlatılmadı',
  previous_month: 'Önceki Ay',
  next_month: 'Sonraki Ay',
  previous_week: 'Geçen Hafta',
  previous_two_week: 'Önceki İki Hafta',
  next_two_week: 'Sonraki İki Hafta',
  zoom_in: 'Yakınlaştır',
  zoom_reset: 'Yakınlaştırmayı Sıfırla',
  zoom_out: 'Uzaklaştır',
  collapse: 'Daralt',
  expand: 'Genişlet',
  find_next: 'Sonrakini Bul',
  find_previous: 'Öncekini Bul',
  assign_staff: 'Personel Ata',
  assigned_staff: 'Atanmış Personel',
  assigned_staffs: 'Atanmış Personeller',
  note: 'Not',
  vendor_runtime:'Vendor Çalışma Zamanı',
  vendor_approval:'Onaya gönderilmeden önce Vendor çalışma zamanları boş olamaz!',
  vendor_runtime_empty:'Vendor çalışma zamanları boş olamaz!',
  vendor_end_date:'Bitiş tarihi başlangıç tarihinden küçük olamaz. Lütfen tarihleri kontrol ediniz.',
  start_date_is_required:'Başlangıç tarihi zorunludur!',
  vendor_recorded:'Vendor çalışma zamanı kaydedilmiştir.',
  vendor_disabled:'Onaya gönderilmiş, onaylanmış veya iptal edilmiş iş emirlerine vendor çalışma zamanları girilemez!',
  none: 'Yok',
  form_answers: 'Cevaplanan Formlar',
  last_modified_date: 'Son Değiştirilme Tarihi',
  last_modified_user: 'Son Değişiklik Yapan',
  estimated_duration: 'Tahmini Süre',
  assigned: 'Atanan',
  upload: 'Yükleme',
  drag_drop: 'Sürükle Bırak',
  accepted_extensions: 'Kabul Edilen Uzantılar',
  click_here_to_browse: 'Göz atmak için buraya tıklayın',
  upload_file: 'Dosya yükle',
  form_name: 'Form Adı',
  form_code: 'Form Kodu',
  form_type: 'Form Tipi',
  max_version: 'En Yüksek Versiyon',
  question_name: 'Soru Adı',
  question_group_name: 'Soru Grubu Adı',
  required: 'Zorunlu',
  answer_group: 'Soru Gurubu',
  answer_type: 'Soru Tipi',
  option: 'Seçenek',
  please_answer_the_questions: 'Lütfen soruları cevaplayınız.',
  started_by: 'Başlatan',
  started_at: 'Başlatıldığı Tarih',
  form_not_started: 'Form Başlatılmadı',
  save_finish: 'Kaydet ve Bitir',
  save_next: 'Kaydet ve Sonraki',
  feedback: 'Geri Bildirim',
  feedbacks: 'Geri Bildirimler',
  add_feedback: 'Geri Bildirim Ekle',
  answered_at: 'Cevaplandığı Tarih',
  answered_by: 'Cevaplayan',
  answers: 'Cevaplar',
  signatures: 'İmzalar',
  signature: 'İmza',
  prepared_by: 'Hazırlayan',
  confirmed_by: 'Onaylayan',
  show: 'Göster',
  upload_form: 'Form Yükle',
  print_empty_form: 'Boş Form Yazdır',
  remaining_day: 'Kalan gün',
  revising_date_approaching: 'Revize tarihi yaklaşıyor.',
  revising_date_has_passed: 'Revize tarihi geçti.',
  sent_by: 'Gönderen',
  sent_date: 'Gönderilen Tarih',
  events_only: 'Sadece etkinlikler',
  previous_question: 'Önceki Soru',
  create_new_version: 'Yeni versiyon oluştur.',
  there_is_no_work_order_in_this_status: 'Bu durumda iş emri yoktur.',
  form_process_status: 'Form İşlem Durumu',
  show_only_has_feedback_ones: 'Sadece geri bildirimi olanları göster',
  version_upgraded: 'Versiyon yükseltildi.',
  please_answer_all_required_questions: 'Lütfen tüm zorunlu soruları cevaplayınız.',
  wait_until_upload_process_done: 'Aktif dosya yüklemesi var. Bitmesini bekleyin veya iptal edin.',
  read_only: 'Salt okunur',
  hide_time_ranges: 'Zaman aralıkları gizle',
  work_order_count: 'İş Emri Sayısı',
  shift_count: 'Vardiya Sayısı',
  unable_to_delete_shift: 'Geçmişe ait vardiya silinemez',
  error: 'Hata',
  tool: 'Araç',
  add_tool: 'Araç Ekle',
  edit_tool: 'Araç Düzenle',
  tool_type: 'Araç Tipi',
  tool_log_type: 'İşlem Tipi',
  tool_types: 'Araç Tipleri',
  add_tool_type: 'Araç Tipi Ekle',
  edit_tool_type: 'Araç Tipi Düzenle',
  tool_sub_type: 'Araç Alt Tipi',
  tool_sub_types: 'Araç Alt Tipleri',
  add_tool_sub_type: 'Araç Alt Tipi Ekle',
  edit_tool_sub_type: 'Araç Alt Tipi Düzenle',
  tool_brand: 'Araç Markası',
  tool_brands: 'Araç Markaları',
  add_tool_brand: 'Araç Markası Ekle',
  edit_tool_brand: 'Araç Markası Düzenle',
  tool_model: 'Araç Modeli',
  tool_models: 'Araç Modelleri',
  add_tool_model: 'Araç Modeli Ekle',
  edit_tool_model: 'Araç Modelini Düzenle',
  warehouse: 'Depo',
  warehouses: 'Depolar',
  add_warehouse: 'Depo Ekle',
  edit_warehouse: 'Depo Düzenle',
  please_type_name: 'Lütfen isim giriniz',
  add_to_selected: 'Seçilene ekle',
  please_select_storage: 'Bir depoya tıklayınız',
  please_type_storage_name: 'Depo adı giriniz',
  toggle_view: 'Görünümü Değiştir',
  add_child_to_item: 'Altına Ekle',
  edit_item: 'Düzenle',
  delete_item: 'Sil',
  please_select_warehouse: 'Lütfen depo seçiniz',
  selected_warehouse: 'Seçilen Depo',
  quantity: 'Miktar',
  add: 'Ekle',
  spare_part_detail: 'Yedek Parça Detay',
  would_you_like_to_detail_entries: 'Kayıtları detaylandırmak ister misiniz?',
  calibration: 'Kalibrasyon',
  undefined_error: 'Tanımsız Hata!',
  tool_calendar: 'Araç Takvimi',
  related_work_orders: 'İlgili İş Emirleri',
  history: 'Tarihçe',
  reservation: 'Rezervasyon',
  next_calibration_date: 'Sonraki kalibrasyon tarihi',
  next_maintenance_date: 'Sonraki bakım tarihi',
  this_field_is_required: 'Bu alanın doldurulması zorunludur.',
  invalid_date_time: 'Geçersiz tarih ve saat.',
  reserved: 'Rezerve Edilmiş',
  lended: 'Ödünç Verilmiş',
  returned: 'İade Edilmiş',
  reserve: 'Rezerve Et',
  lend: 'Ödünç Ver',
  return: 'İade Al',
  tool_status: 'Araç durumu',
  estimated_return_date: 'Tahmini getirme tarihi',
  estimated_return_time: 'Tahmini getirme zamanı',
  training_duration_empty: 'Eğitim Süresi',
  deselect_label: 'Silmek için tıklayınız.',
  selected_label: 'Seçildi',
  no_option: 'Liste boş.',
  select_label: 'Seçmek için tıklayın.',
  no_rows_to_display: 'Görüntülecek Satır Yok',
  item_saved_successfully: '{0} kayıt edildi.',
  item_deleted_successfully: '{0} isimli kayıt silindi.',
  selected_only_save: 'Sadece Seçilen {0} Kayıt Kaydedilecektir.',
  actor: 'Aktör',
  actors: 'Aktörler',
  points: 'Noktalar',
  point: 'Nokta',
  add_point: 'Nokta Ekle',
  clone_point: 'Nokta Klon',
  edit_point: 'Nokta Düzenle',
  point_name: 'Nokta İsmi',
  point_description: 'Nokta Açıklaması',
  point_definition: 'Nokta Tanımı',
  point_definitions: 'Nokta Tanımları',
  add_point_definition: 'Nokta Tanımı Ekle',
  edit_point_definition: 'Nokta Tanımı Düzenle',
  point_type: 'Nokta Tipi',
  point_clone_type: 'Klon Etmek Istediğiniz Yer',
  number_input: 'Sayısal Değer Girişi',
  decimal_input: 'Ondalık Değer Girişi',
  add_question_group: 'Soru Grubu Ekle',
  edit_question_group: 'Soru Grubu Düzenle',
  selected_point: 'Seçilen Nokta',
  expected_value: 'Beklenen Değer',
  delete_question: 'Soruyu Sil',
  delete_question_group: 'Soru Grubunu Sil',
  add_point_linked_question: 'Noktaya Bağlı Soru Ekle',
  updated: 'Güncellendi',
  created: 'Oluşturuldu',
  created_date: 'Oluşturulma Tarihi',
  add_comment:'Yorum Ekle',
  spare_part_process_type:'İşlem Tipi',
  sp_process:'Yapılan İşlem',
  work_order_history_created: 'İş Emri {2} statüsünde, {0} için {1} dakika sürecek şekilde oluşturulmuştur.',
  scheduled_time_history_updated: 'Planlanmış zaman {0}\'dan {1}\'a alınmıştır.',
  duration_history_updated: 'Süre {0}\'dan {1} olarak güncelleştirilmiştir.',
  status_history_updated: 'İş emri {0} statüsünden {1} olarak güncelleştirilmiştir.',
  inventory_information: 'Envanter Bilgileri',
  staff_information: 'Personel Bilgileri',
  actual_date: 'Gerçekleşen Tarih',
  actual_time: 'Gerçekleşen Saat',
  actual_duration: 'Gerçek Süre',
  attachments: 'Ek Dosyalar',
  comments: 'Yorumlar',
  number_of_flag: 'Bayrak Sayısı',
  flags: 'Bayraklar',
  number_of_abnormal_answers: 'Abnormal Cevap Sayısı',
  attached_pic: 'Ekteki Fotoğraf',
  preparations: 'Ön Hazırlıklar',
  users_who_need_the_most_trainings: 'En Çok Eğitime İhtiyaç Duyan Personeller',
  most_needed_trainings: 'En Çok İhtiyaç Duyulan Eğitimler',
  training_count: 'Eğitim Sayısı',
  add_rule: 'Kural Ekle',
  add_group: 'Grup Ekle',
  minimum: 'Asgari',
  maximum: 'Azami',
  person_who_needed: 'İhtiyaç Duyan Personel',
  options_selected: 'seçenek seçildi',
  feedback_saved_successfully: 'Geri bildirim kaydedildi.',
  feedback_deleted_successfully: 'Geri bildirim silindi.',
  invalid_answer_count: 'Geçersiz Cevap Sayısı',
  show_only_has_invalid_answer_ones: 'Sadece geçersiz cevabı olanları göster',
  authorization: 'Yetkilendirme',
  method_authorization: 'Metot Yetkilendirme',
  edit_authorization: 'Yetkilendirme Güncelle',
  add_authorization: 'Yetkilendirme Ekle',
  action: 'Action',
  action_group: 'Action Grup',
  add_action: 'Action Ekle',
  add_action_group: 'Action Group Ekle',
  undefined_user: 'Tanımlanamayan Kullanıcı',
  deleted_user: 'Silinmiş Kullanıcı',
  device_model: 'Cihaz Model',
  device_models: 'Cihaz Modelleri',
  add_device_model: 'Cihaz Modeli Ekle',
  edit_device_model: 'Cihaz Modelini Düzenle',
  target: 'Hedef',
  message: 'Mesaj',
  you_will_not_be_able_to_copy_for_some_targets: 'Bazı Oda, cihaz veya kapasite elemanları için kopyalama işlemi yapamayacaksınız.',
  copied_points: 'Kopyalanan Noktalar',
  point_removed: 'Nokta kaldırıldı.',
  edit_contract: 'Sözleşme Düzenle',
  add_contract: 'Sözleşme Düzenle',
  contract_definition: 'Sözleşme Tanımla',
  contract_name: 'Sözleşme İsmi',
  contract_number: 'Sözleşme No',
  contract_start__date: 'Sözleşme Başlagıç Tarihi',
  contract_finish_date: 'Sözleşme Bitiş Tarihi',
  contract_price: 'Sözleşme Bedeli',
  contract_section: 'Sözleşme Bölümü',
  financial_information: 'Finansal Bilgiler',
  vendor_information: 'Vendor Bilgileri',
  vendor_name: 'Vendor İsmi',
  authorized_staff: 'Yetkili Personel',
  username_password_is_required: 'Kullanıcı adı ve Parola alanları zorunludur.',
  username_is_required: 'Kullanıcı adı alanı zorunludur.',
  password_is_required: 'Parola alanı zorunludur.',
  n_of_n_saved: '{1} adet kayıttan {0} adeti kaydedildi',
  remove_added_points: 'Kayıt edilmiş noktaları kaldır.',
  spare_parts: 'Yedek Parçalar',
  spare_part: 'Yedek Parça',
  sp:'YP',
  stock_count: 'Miktar',
  spare_part_name: 'Yedek Parça Adı',
  sp_name:'YP Adı',
  spare_part_kind: 'Yedek Parça Türü',
  sp_kind:'YP Türü',
  edit_spare_part_kind: 'Yedek Parça Türü Düzenle',
  add_spare_part_kind: 'Yedek Parça Türü Ekle',
  spare_part_type: 'Yedek Parça Tipi',
  sp_type:'YP Tipi',
  edit_spare_part_type: 'Yedek Parça Tipi Düzenle',
  add_spare_part_type: 'Yedek Parça Tipi Ekle',
  spare_part_category: 'Yedek Parça Kategori',
  sp_category:'YP Kategori',
  edit_spare_part_category: 'Yedek Parça Kategori Düzenle',
  add_spare_part_category: 'Yedek Parça Kategori Ekle',
  spare_part_sub_category: 'Yedek Parça Alt Kategori',
  sp_sub_category:'YP Alt Kategori',
  edit_spare_part_sub_category: 'Yedek Parça Alt Kategori Düzenle',
  add_spare_part_sub_category: 'Yedek Parça Alt Kategori Ekle',
  add_spare_part: 'Yedek Parça Ekle',
  edit_spare_part: 'Yedek Parça Düzenle',
  contracts: 'Sözleşmeler',
  contract_list: 'Sözleşme Listesi',
  end_date_cannot_be_before_start_date: 'Bitiş tarihi Başlangıç tarihinden önce olamaz.',
  contract_save_successfully: 'Sözleşme başarıyla kaydedildi.',
  start_date_and_end_date_cannot_be_same: 'Başlangıç tarihi ve bitiş tarihi aynı olamaz.',
  customize_working_hours: 'Çalışma Saatlerini Özelleştir',
  weekdays_only: 'Haftaiçi Günleri',
  seven_twenty_four: '7/24',
  including_public_holidays: 'Resmi Tatiller Dahil',
  add_file: 'Dosya Ekle',
  rounds_which_is_not_invalid_most_configuration: 'En Çok Konfigurasyona Uymayan Roundlar',
  capacity_equipments_which_is_not_invalid_most_configuration: 'En Çok Konfigurasyona Uymayan Kapasite Ekipmanları',
  work_order_count_by_categories: 'Kategorilere Göre İş Emri Sayısı',
  number_of_capacity_equipment_without_scp_form: 'SCP Formu Olmayan Kapasite Ekipmanı Sayısı',
  most_repetitive_problem_inventory_and_point: 'En Çok Tekrar Eden Envanter Sorunları ve Noktaları',
  count: 'Sayı',
  generate_gap_report: 'Gap Raporu Hazırla',
  staff_who_spends_most_time_with_round_in_n: '{0} Ayında En Çok Round Yapan Kullanıcılar',
  work_order_number: 'İş Emri No',
  start_and_end_dates_are_required: 'Başlangıç Ve Bitiş Tarihi Girmek Zorunludur.',
  edit_file: 'Dosya Düzenle',
  select_capacity_eq: 'Kapasite Ekipmanı Seç',
  select: 'Seç',
  add_preventive_maintenance: 'Bakım Planı Ekle',
  edit_preventive_maintenance: 'Bakım Planı Düzenle',
  preventive_maintenance_deferment_condition_first_part: 'İş planlanan tarihten sonraki ',
  preventive_maintenance_deferment_condition_second_part: 'gün içinde bitmezse ya da reddedilirse  gecikmiş olarak kabul edilir.',
  point_save_successfully: 'Nokta başarıyla kaydedildi.',
  add_sla: 'Sla Ekle',
  edit_sla: 'Sla Düzenle',
  condition: 'Şart',
  contact_person: 'Ulaşılacak Kişi',
  criticality_level: 'Kritiklik Seviyesi',
  reaction_time: 'Tepkime Süresi (Saat)',
  work_around_time: 'Geçici Çözüm Süresi (Saat)',
  permanent_solution_time: 'Kalıcı Çözüm Süresi (Saat)',
  contact_person_phone_number: 'Ulaşılacak Kişi Telefon Numarası',
  contact_person_mail: 'Ulaşılacak Kişi Mail',
  reference_type: 'Referans Tipi',
  reference_date: 'Referans Tarihi',
  total_count: 'Toplam Adet',
  remained_count: 'Kalan Adet',
  tolerance: 'Tolerans',
  last_maintenance: 'Son Bakım',
  next_maintenance: 'Sonraki Bakım',
  create_plan: 'Plan Oluştur',
  remained_count_cannot_be_greater_than_total_count: 'Kalan Adet Toplam Adetten Büyük Olamaz',
  pm_save_successfully: 'Bakım planı başarıyla kayıt edildi.',
  file_saved_successfully: ' adlı dosya başarıyla kaydedildi',
  add_section: ' Bölüm Ekle',
  edit_section: 'Bölüm Düzenle',
  section_name: 'Bölüm Adı',
  contract_attachments: 'Dokümanlar',
  add_attachment: 'Doküman Ekle',
  edit_attachment: 'Doküman Düzenle',
  edit_plan: 'Plan Düzenle',
  min_value_validation: 'Lütfen {0} değerine eşit ya da daha büyük bir değer giriniz.',
  contract_price_can_not_be_less_than_zero: 'Sözleşme bedeli 0\'dan küçük olamaz.',
  start_time_cannot_be_after_end_time: 'Başlangıç zamanı Bitiş zamanından sonra olamaz.',
  working_hours: 'Çalışma Saatleri',
  at_least_one_character_must_be_entered: 'En az bir karakter girilmelidir.',
  only_decimal_and_integer_must_be_entered: 'Sadece ondalık ve tam sayı girilmelidir.',
  send_back: 'Geri Gönder',
  sent_back: 'Geri Gönderildi',
  pending_approval_work_order: 'Onay bekleyen {0} iş emrini',
  are_you_sure_you_want_to_send_it_back: 'Geri göndermek istediğine emin misin?',
  sent_back_successfully: '{0} iş emri başarıyla geri gönderildi',
  ask_status_update_from_to: '{0} iş emri {1} statüsünden {2} olarak güncellensin mi?',
  status_update_work_order_successfully: ' {0} iş emri {1} statüsünden {2} olarak güncelleştirilmiştir.',
  no_forms_to_display: 'Görüntülenecek form yok',
  search_user: 'Kullanıcı Ara',
  do_you_want_to_continue_answering_the_form: 'Form cevaplamaya devam etmek ister misiniz?',
  pending_approval: 'Onaya Gönderildi',
  sent_by_for_approval: 'Onaya Gönderen',
  onHold: 'Beklemede',
  onProgress: 'İşlemde',
  cancelled: 'İptal Edildi',
  all_procedures: 'Tüm Prosedürler',
  update: 'Güncelle',
  are_you_sure_you_want_to_leave_the_page: 'Sayfadan ayrılmak istediğinize emin misiniz?',
  you_have_unsaved_changes: 'Kaydedilmemiş değişiklikleriniz var.',
  save_and_exit: 'Kaydet Çık',
  available: 'Uygun',
  busy: 'Meşgul',
  master_training: 'İş Yapanın Eğitimi',
  observer_training: 'Refakatçinin Eğitimi',
  number_of_total_capacity_equipment: 'Toplam Kapasite Ekipmanı Sayısı',
  number_of_total_capacity_equipment_by_infrastructure: 'Toplam Kapasite Ekipmanı Sayısı Altyapıya Göre',
  reports: 'Raporlar',
  work_order_report: 'İş Emri Raporu',
  staff_report: 'Personel Raporu',
  weekly_round_report: 'Haftalık Round Raporu',
  date: 'Tarih',
  round_name: 'Round İsmi',
  device_health_report: 'Cihaz Sağlık Raporu',
  staff_performance_report: 'Personel Performans Raporu',
  vendor_performance_report: 'Vendor Performans Raporu',
  how_many_times_check: 'Kaç kez kontrol edildi',
  how_many_times: 'Kaç Kere?',
  how_many_flags_have_been_added: 'Kaç Bayrak Eklendi?',
  number_of_invalid_answers_deviating_from_normal: 'Normalden Sapma Gösteren Geçersiz Cevap Sayısı',
  number_of_points_deviating_from_normal: 'Normalden Sapma Gösteren Nokta Sayısı',
  name_of_the_point_with_the_most_deviation_from_normal: 'En Çok Normalden Sapma Gösteren Nokta Adı',
  number_of_deviations_from_normal_found: 'Bulduğu Normalden Sapma Sayısı',
  number_of_warning_flags_added: 'Eklediği Uyarı Bayrağı Sayısı',
  round_information: 'Round Bilgileri',
  how_many_times_abnormal_answer_received: 'Kaç Kez Abnormal Cevap Geldi (Nokta)',
  total: 'Toplam',
  between_to: '{0} ile {1} Arası ',
  main_work_order: 'Ana İş Emri',
  main_work_order_number: 'Ana İş Emri No',
  end_of_maintenance_contract: 'Bakım Sözleşme Bitiş',
  end_of_guarantee_time: 'Garanti Süresi Bitiş',
  comment: 'Yorum',
  commented_by: 'Yorum Yapan',
  commented_at: 'Yorum Hakkında',
  min: 'dk',
  user_assigned: 'Personel atama işlemi yapıldı.',
  scheduled_time: 'Planlanan Zaman',
  you_dont_have_permission_to_home_page: 'Ana sayfayı görmek için yetkiniz yok.',
  close: 'Kapat',
  exit: 'Çık',
  work_description: 'İş Açıklaması',
  work_order_description: 'İş Emri Açıklaması',
  shifts: 'Vardiyalar',
  pending_maintenance_plans_for_assign: 'Atanma Bekleyen Bakım Planları',
  all_forms: 'Tüm formlar',
  please_select_your_download_request: 'Lütfen İndirmek İstediğinizi Seçiniz',
  deleted_entry: 'Silinmiş kayıt.',
  is_domain_user: 'Domain Kullanıcısı',
  active: 'Aktif',
  inActive:"İnaktif",
  username: 'Domain Kullanıcı Adı',
  email_username: 'Email / Kullanıcı Adı',
  priority: 'Öncelik',
  planned: 'Planlanmış',
  incident: 'Olay',
  type_name: 'İsim Giriniz',
  please_select_work_order_category: 'Lütfen Olay Kategorisi Seçiniz',
  work_order_category: 'Olay Kategorisi',
  please_select_priority: 'Lütfen Öncelik Seçiniz',
  add_incident: 'Olay Ekle',
  incidents: 'Olaylar',
  id_no: 'No',
  daily_tasks: 'Günlük Görevler',
  my_daily_tasks: 'Günlük Görevlerim',
  tasks: 'Görevler',
  my_tasks: 'Görevlerim',
  task: 'Görev',
  detail:'Detay',
  task_detail: 'Görev Detay',
  create_task: 'Görev Ekle',
  task_no: 'Görev No',
  task_category: 'Kategori',
  status_note: 'Durum Notu',
  add_task: 'Görev Ekle',
  edit_task: 'Görev Güncelle',
  you_dont_have_permission: 'Bu işlemi yapmak için yetkiniz yok.',
  deferment_condition: 'Gecikme Zamanı',
  is_delayed: 'Gecikme',
  time_elapsed_since_last_maintenance: 'Son Bakımdan Bu Yana Geçen Süre',
  planned_maintenance_date: 'Planlanan Bakım Tarihi',
  all_asset: 'Tüm Varlık Raporu',
  performed_maintenance_date: 'Gerçekleştirilen Bakım Tarihi',
  periodic_maintenance_report: 'Periyodik Bakım Raporu',
  send_work_order_notification_to_vendor: 'Vendor\'a bildir',
  notify_before_days: 'Önceden bildir (Gün)',
  incident_report: 'Olay Raporu',
  historical_record: 'Geçmişe dönük kayıt',
  captcha: 'Captcha',
  id:'Id',
  decrease_stock:'Stok Düşür',
  stock_count_transfered:'Transfer Edilecek Miktar',
  input_text_validation_comment:'Yorum 200 karakteri geçmemeli',
  successfully_update_stock:'Stok Güncelleme Başarılı',
  successfully_transfer_stock:'Transfer İşlemi Başarılı',
  successfully_add_comment: 'Yorum Ekleme Başarılı',
  task_id:'Görev Id',
  add_stock:'Stok Ekle',
  transfer_spare_part:'Yedek Parça Transferi',
  in_use_date: 'Kullanıma Alındığı Tarih',
  staff_who_input_most_invalid_answers_in_n: '{0} Ayında En Çok Beklenmedik Değer Giren Kullanıcılar',
  staff_who_made_most_round_in_n: '{0} Ayında En Çok Round Yapan Kullanıcılar',
  round_count_in_n: '{0} Ayı Round Sayısı',
  preview: 'Ön İzleme',
  required_total_training: 'Gerekli Eğitim',
  not_exists_file: 'Ek Dosya Mevcut Değil',
  discard_product: 'Iskarta Ürün',
  discard_products: 'Iskarta Ürünler',
  shift_calendar_error_earlier_3_days: '3 günden önceki vardiyaları ekleyemezsiniz!',
  restart_form_process: 'Formu Tekrar Başlat',
  form_started_date: 'Formun Kullanıma Alındığı Tarih',
  form_description: 'Açıklama',
  changes_applied_successfully: 'Değişiklikler başarıyla uygulandı',
  this_record_already_exists: 'Bu kayıt zaten var',
  qaqc: 'QA & QC',
  qaqc_work_order_info: 'Bazı QA&QC maddelerini işaretlemediniz yine de bu iş emrini onaylamak ister misiniz?',
  training_delete_warning: "Seçilen eğitimi silerseniz, bu eğitime bağlı tüm kayıtlar ve raporlar da kalıcı olarak silinecektir. Bu işlem geri alınamaz. Yine de silme işlemine devam etmek istediğinizden emin misiniz?",
  grid: {
    EmptyRecord: 'Görüntülecek kayıt yok.',
    GroupDropArea: 'Sütununuzu gruplandırmak için bir sütun başlığını buraya sürükleyin',
    UnGroup: 'Grubu bozmak için tıklayın',
    Item: 'Kayıt',
    Items: 'Kayıt',
  },
  pager: {
    currentPageInfo: '{0} / {1} Sayfa',
    totalItemsInfo: '({0} Kayıt)',
    firstPageTooltip: 'İlk Sayfa',
    lastPageTooltip: 'Son Sayfa',
    nextPageTooltip: 'Sonraki Sayfa',
    previousPageTooltip: 'Önceki Sayfa',
    nextPagerTooltip: 'Sonraki Sayfa',
    previousPagerTooltip: 'Önceki Sayfa',
    pagerDropDown: 'Sayfa Başına Kayıt',
  },
  excelFilter: {
    sortNoSmaller: 'Küçükten Büyüğe Sırala',
    sortNoLarger: 'Daha Büyük Sırala',
    sortTextAscending: 'Alfabetik Sırala',
    sortTextDescending: 'Alfabetik Ters Sırala',
    sortDateOldest: 'Eskiye Göre Sırala',
    sortDateNewest: 'Yeniye Göre Sırala',
    clearFilter: 'Filtreleri Kaldır',
    dateTimeFilter: 'Zaman Filtresi',
    dateFilter: 'Zaman Filtresi',
  },
  work_order_delete_error: "Bu iş emri silinemez",
  // calendar: {
  //   May: 'Mayıs',
  // },
};
