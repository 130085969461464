import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';
import * as api from '../services/api';
import * as blockui from '../../helpers/block-ui';
import { mixinz } from '../global/mixinz';
import upload from '../../components/shared/upload';
import Questions from '../../components/partials/Questions';
import UserCard from '../../components/partials/cards/UserCard';
import * as dateFormat from '../../helpers/dateFormat';
import * as swal from '../../helpers/swal';
import { Editor } from '../global/editors';
import { compareArrayResult } from '../global/util';

export default {
  name: 'workOrderForm',
  mixins: [mixinz],
  props: {
    selectedWorkOrderFormsCount: 0,
    formId: {},
    workOrderId: {type: [Number, String]},
    workOrderStatus: {required: true, type: [Number]},
    workOrderWhoApproved: {required: false, type: [String]},
    procedureTypeId: {},
    isEditable: { required: true, type: Boolean },
    continueButton: null,
    isCurrentFormChangeable: { required: false, default: true },
  },
  components: {
    dropdown: () => import('../../components/shared/MyWorkOrderDropdown'),
    upload,
    questions: Questions,
    'user-card': UserCard,
  },
  data() {
    return {
      modalId: 'form_modal',
      allFeedBacks: null,
      feedBackEditor: null,
      hasFormFlag: false,
      tooltipEditor: null,
      formFeedBacks: null,
      files: {},
      fileType: 'Image',
      validator: {},
      selectedForm: {},
      formDevices: [],
      formTemplate: null,
      showQuestions: false,
      showQuestionModal: false,
      fileStatuses: {
        uploading: 1,
        uploaded: 2,
        canceled: 3,
        error: 4,
        deleted: 5,
      },
      goingBack: false,
      selectedFormSectionId: null,
      companyConfigCheckRequired: true,
      emptyObject: {},
      showErrors: false,
      selectedQuestionId: 0,
      selectedQuestionGroupId: null,
      comparisonOperators: [],
      operandTypes: [],
      continueFormButton: this.continueButton,
      currentFormId: this.formId,
      userArrows: false,

      saveProcessQuestionIds: [],
      invalidAnswerCount: 0,
    };
  },
  async mounted() {
    const self = this;
    blockui.blockElementPromise(self, `#${self.modalId}>  div> div.modal-content`);
    await self.init();
    blockui.unBlockElement(`#${self.modalId}> div> div.modal-content`);
    self.$nextTick(() => {
      self.$el.removeEventListener('click', self.handleTooltip);
      self.$el.addEventListener('click', self.handleTooltip);
      document.getElementById('form_modal').querySelector('.modal-content').addEventListener('scroll', (e) => {
        self.handleScroll(e);
      });
    });
    this.onCloseModal(`#${self.modalId}`);
    $(document).off('focusin.modal');
    $(`#${self.modalId}`).off('hidden.bs.modal');
    $(`#${self.modalId}`).on('hidden.bs.modal', () => {
      self.$eventHub.$emit('flagCount', { workOrderId: self.workOrderId });
      self.$emit('close');
      self.$emit('changeWorkOrderStatus', self.workOrderId);
    });
  },
  beforeDestroy() {
    Editor(null, this.feedBackEditor);
    Editor(null, this.tooltipEditor);
    this.$el.removeEventListener('click', this.handleTooltip);
  },
  computed: {
    getFormSectionsWithQuestions() {
      const self = this;
      if (!self.selectedForm || !self.selectedForm.QuestionGroups) return [];
      return self.formSectionList.sort((x, y) => x.Order - y.Order).map((formSection) => ({
        ...formSection,
        QuestionGroups: self.selectedForm.QuestionGroups.filter((questionGroup) => questionGroup.FormSectionId == formSection.Id).sort((x, y) => x.Order - y.Order),
      }));
    },
    currentOrderedIndex() {
      const self = this;
      if (!self.selectedQuestion) {
        return -1;
      }
      return self.orderedQuestions.findIndex((question) => question.Id == self.selectedQuestion.Id);
    },
    orderedQuestions() {
      const self = this;
      const allQuestions = [];
      self.formSectionList.forEach((formSection) => {
        self.selectedForm.QuestionGroups.filter((questionGroup) => questionGroup.FormSectionId == formSection.Id).forEach((questionGroup) => {
          questionGroup.Questions.forEach((question) => {
            allQuestions.push(question);
          });
        });
      });
      return allQuestions;
    },
    isNextQuestionExist() {
      if (!this.selectedQuestion || this.currentOrderedIndex < 0) {
        return false;
      }
      return this.orderedQuestions.length - 1 > this.currentOrderedIndex;
    },
    isPreviousQuestionExist() {
      if (!this.selectedQuestion || this.currentOrderedIndex < 0) {
        return false;
      }
      return this.currentOrderedIndex > 0;
    },
    // tickControl
    isSectionCompleted() {
      const self = this;
      if (self.selectedForm && self.selectedForm.QuestionGroups && self.selectedForm.QuestionGroups.length > 0) {
        const isSectionCompleted = {};

        for (const formSection of self.formSectionList) {
          isSectionCompleted[formSection.Id] = false;
          for (const questionGroup of self.selectedForm.QuestionGroups.filter((qg) => qg.FormSectionId == formSection.Id)) {
            if (!(questionGroup.Questions.filter((question) => question.IsRequired && self.isInvalid(question)).length > 0)) {
              isSectionCompleted[formSection.Id] = true;
            }
          }
        }
        return isSectionCompleted;
      }
      return {};
    },
    selectedQuestion() {
      return this.getQuestionById(this.selectedQuestionId);
    },
    isSelectedQuestionAnswerChanged() {
      return this.selectedQuestion && this.selectedQuestion.AnswerChanged;
    },
    getSelectedQuestionFormSection() {
      const self = this;
      return self.formSectionList.find((formSection) => self.getQuestionGroupByQuestionId(self.selectedQuestionId) && (formSection.Id == self.getQuestionGroupByQuestionId(self.selectedQuestionId).FormSectionId));
    },
    currentSectionQuestionGroups: {
      get() {
        const self = this;
        if (self.selectedForm && self.selectedForm.QuestionGroups) {
          return self.selectedForm.QuestionGroups
            .filter((questionGroup) => questionGroup.FormSectionId == self.selectedFormSectionId)
            .sort((x, y) => x.Order - y.Order);
        }
        return [];
      },
      set(newVal) {
        const self = this;
        newVal.forEach((questionGroup, index) => {
          questionGroup.Order = index + 1;
        });
        newVal.forEach((questionGroup, index) => {
          Vue.set(self.selectedForm.QuestionGroups, self.selectedForm.QuestionGroups.findIndex((x) => x.Id === questionGroup.Id), questionGroup);
        });
      },
    },
    formProcess() {
      const self = this;
      if (self.selectedForm && self.selectedForm.FormProcesses && self.selectedForm.FormProcesses.length > 0) {
        return self.selectedForm.FormProcesses[0];
      }
      return null;
    },
    isStarted() {
      return !!(this.formProcess && this.formProcess.FormProcessStatusId == this.formProcessStatus.started);
    },
    isCompleted() {
      return !!(this.formProcess && !this.IsEditable && this.formProcess.FormProcessStatusId == this.formProcessStatus.completed);
    },
    isStartable() {
      if (!this.formProcess) {
        return true;
      } if (this.formProcess.FormProcessStatusId != this.formProcessStatus.started) {
        return true;
            }
      return false;
    },
    isFinishable() {
      return this.formProcess && this.formProcess.FormProcessStatusId == this.formProcessStatus.started;
    },
    isAnswersChanged() {
      return this.selectedForm && this.selectedForm.QuestionGroups && this.selectedForm.QuestionGroups.length > 0
                && this.selectedForm.QuestionGroups.some((questionGroup) => questionGroup && questionGroup.Questions
                    && questionGroup.Questions.some((question) => question && question.AnswerChanged));
    },
    getSelectedFormSection() {
      const self = this;
      return self.formSectionList.find((x) => x.Id == self.selectedFormSectionId);
    },
    sectionQuestionCounts() {
      const self = this;
      if (self.selectedForm && self.selectedForm.QuestionGroups && self.selectedForm.QuestionGroups.length > 0) {
        const counts = self.selectedForm.QuestionGroups.reduce((p, c) => {
          const formSectionId = c.FormSectionId;
          if (!p.hasOwnProperty(formSectionId)) {
            p[formSectionId] = { questionCount: 0, answerCount: 0 };
          }
          if (c.Questions && c.Questions.length > 0) {
            p[formSectionId].questionCount += c.Questions.length;
            p[formSectionId].answerCount += c.Questions.map((question) => !self.isInvalid(question)).filter((x) => x).length;
          }
          return p;
        }, {});
        return counts;
      }
      return {};
    },
    isUploadProcessExist() {
      const self = this;
      return self.selectedForm
                && self.selectedForm.QuestionGroups
                && self.selectedForm.QuestionGroups.some((questionGroup) => questionGroup
                    && questionGroup.Questions.some((question) => question.isUploadBusy));
    },
    selectedProcedureTypeId() {
      return this.selectedForm ? this.selectedForm.ProcedureTypeId : null;
    },
    invalidQuestions() {
      const self = this;
      if (!(self.selectedForm && self.selectedForm.QuestionGroups)) {
        return [];
      }

      const invalidQuestions = [];

      self.selectedForm.QuestionGroups.forEach((questionGroup) => {
        if (questionGroup && questionGroup.Questions) {
          questionGroup.Questions.forEach((question) => {
            if (question.IsRequired && self.isInvalid(question)) {
              invalidQuestions.push(question);
            }
          });
        }
      });

      return invalidQuestions;
    },
    ...mapState({
      formQuestionType: (state) => state.enums.formQuestionType,
      formSectionList: (state) => state.form.formSectionList,
      formProcessStatus: (state) => state.enums.formProcessStatus,
      procedureTypeEnum: (state) => state.enums.procedureType,
      workOrderStatusEnum: (state) => state.enums.workOrderStatus,
    }),
    isProcedureTypeScp() {
      return this.procedureTypeEnum.scp == this.selectedProcedureTypeId;
    },
    isSaveProcessing() {
      return !this.saveProcessQuestionIds || (this.saveProcessQuestionIds && this.saveProcessQuestionIds.length > 0);
    },
  },
  methods: {
    handleScroll(e) {
      /* if(e.target.scrollTop>111) {
                $('.dropdown-menu') &&  $('.dropdown-menu').first().stop(true, true).slideUp();
            }else{
                $('.dropdown-menu').hasClass('show') &&  $('.dropdown-menu').removeClass('show');
            } */
      if ($('.dropdown-menu').is(':visible')) {
        $('.dropdown-menu.show').first().stop(true, true).slideUp();
      } else if (e.target.scrollTop === 0) {
        $('.dropdown-menu.show').first().stop(true, true).slideDown();
      }
    },
    answeredQuestionCount(questionGroup) {
      return questionGroup.Questions ? questionGroup.Questions.map((question) => !this.isInvalid(question)).filter((x) => x).length : 0;
    },
    multipleSelectionChanged(questionTypeId) {
      if (questionTypeId == this.formQuestionType.multipleChoice) {
        this.nextQuestion();
      }
    },
    handleTooltip(event) {
      const self = this;
      if (!event.target.classList.contains('i-tooltip')) return false;
      self.tooltipEditor = Editor({
        type: 'toolTip',
        refElement: event.target,
        onPaint: async (source) => {
          const id = source.refElement.id.split('_')[1];
          await api.getWorkOrderHtmlContent(id).then((response) => {
            if (response.ok && response.body) {
              if (localStorage.getItem('userId') == (response.body.CreatedUser && response.body.CreatedUser.Id)) source.tools.delete.element.style.display = 'block';
              const html = response.body.HtmlContent && response.body.HtmlContent.Content || '';
              const date = response.body.CreatedDate || '';
              const user = response.body.CreatedUser && response.body.CreatedUser.NameSurname || '';
              source.html = html;
              source.title = `${user} ${moment(date).format('DD.MM.YYYY HH:mm')}`;
            }
          });
        },
        callback: async (source) => {
          const id = source.refElement.id.split('_')[1];
          if (source.element) {
            await api.deleteWorkOrderHtmlContent(id).then((res) => {
              if (res.body.IsSuccess) {
                self.allFeedBacks = self.allFeedBacks.filter((x) => x.Id != id);
                self.formFeedBacks = self.formFeedBacks.filter((x) => x.Id != id);
                toastr.success(self.translate('feedback_deleted_successfully'));
                source.close();
              } else {
                toastr.error(res.body.Message);
              }
            });
          }
        },
      }, self.tooltipEditor);
    },
    saveQuestionFeedBack(feedback) {
      this.allFeedBacks.push(feedback);
    },
    openEditor({ target }) {
      const self = this;
      self.feedBackEditor = Editor({
        type: 'popupHtml',
        refElement: target,
        value: '',
        callback: async (value) => {
          if (!value.length) return;
          await self.$globalApiClient.workOrderHtmlContent.workOrderHtmlContentSave({ 
            workOrderId: self.workOrderId,
            formProcessId: self.formProcess.Id
          }, {
            content: value,
          }).then((res) => {
            if (res.data.Data) {
              const feedback = {
                Id: res.data.Data.Id,
                HtmlContentId: res.data.Data.HtmlContentId,
                CreatedDate: res.data.Data.CreatedDate,
                WorkOrderId: self.workOrderId,
                FormProcessId: self.formProcessId,
                CreatedUser: { NameSurName: localStorage.getItem('userName') },
              };
              self.allFeedBacks.push(feedback);
              self.formFeedBacks.push(feedback);
              toastr.success(self.translate('feedback_saved_successfully'));
              self.feedBackEditor.close();
            } else {
              toastr.error(res.data.Message);
            }
          });
        },
      }, self.feedBackEditor);
    },
    scrollToSelectedQuestionContainer() {
      const self = this;
      self.$nextTick(() => {
        if ($(`#${self.modalId} .modal-content`).length > 0 && $('#selectedQuestionContainer').length > 0) {
          $('#selectedQuestionContainer').focus();
          $(`#${self.modalId} .modal-content`).first().animate({
            scrollTop: $('#selectedQuestionContainer').offset().top - $(`#${self.modalId} .modal-content form`).first().offset().top,
          }, 500);
        }
      });
    },
    selectFirstFormSection() {
      const self = this;
      // select first section
      if (self.formSectionList && self.formSectionList.length > 0) {
        const minOrderedFormSection = self.formSectionList.reduce((min, selection) => (min.Order < selection.Order ? min : selection), self.formSectionList[0]);
                self.selectedFormSectionId = minOrderedFormSection.Id;

        // select first section's question group
        if (self.selectedForm && self.selectedForm.QuestionGroups && self.selectedForm.QuestionGroups.length > 0) {
          const selectedFormSectionQuestionGroups = self.selectedForm.QuestionGroups.filter((questionGroup) => questionGroup.FormSectionId == self.selectedFormSectionId);
          if (selectedFormSectionQuestionGroups && selectedFormSectionQuestionGroups.length > 0) {
            const minOrderedQuestionGroup = selectedFormSectionQuestionGroups.reduce((min, selection) => (min.Order < selection.Order ? min : selection), selectedFormSectionQuestionGroups[0]);
            self.selectedQuestionGroupId = minOrderedQuestionGroup.Id;
          }
        }
      }
    },
    initSelectedQuestionIndex() {
      const self = this;
      for (const question of self.orderedQuestions) {
        if (self.isInvalid(question)) {
          self.setSelectedQuestion(question.Id);
          return;
        }
      }
      self.selectFirstFormSection();
    },
    isInvalid(question) {
      const self = this;
      const currentSelection = self.getQuestionsCurrentSelection(question.Id);
      if (question.QuestionTypeId == self.formQuestionType.multipleChoice) {
        return !(currentSelection > 0);
      } if (question.QuestionTypeId == self.formQuestionType.checkBox) {
        return !(currentSelection && currentSelection.length > 0);
      } if (question.QuestionTypeId == self.formQuestionType.shortText) {
        return !currentSelection || currentSelection.trim() === '';
      } if (question.QuestionTypeId == self.formQuestionType.longText) {
        return !currentSelection || currentSelection.trim() === '';
      } if (question.QuestionTypeId == self.formQuestionType.number) {
        return !self.isInt(currentSelection) && !self.validateQuestionType(currentSelection, question.QuestionTypeId);
      } if (question.QuestionTypeId == self.formQuestionType.decimal) {
        return !self.isNumeric(currentSelection) && !self.validateQuestionType(currentSelection, question.QuestionTypeId);
      } if (question.QuestionTypeId == self.formQuestionType.shortDate) {
        const momentObj = moment(currentSelection, 'DD.MM.YYYY');
        return !momentObj.isValid();
      } if (question.QuestionTypeId == self.formQuestionType.longDate) {
        const momentObj = moment(currentSelection, 'DD.MM.YYYY HH:mm');
        return !momentObj.isValid();
      } if (question.QuestionTypeId == self.formQuestionType.hour) {
        const momentObj = moment(currentSelection, 'HH:mm');
        return !momentObj.isValid();
      } if (question.QuestionTypeId == self.formQuestionType.imageUpload) {
        return !(currentSelection && currentSelection.fileId > 0);
      } if (question.QuestionTypeId == self.formQuestionType.fileUpload) {
        return !(currentSelection && currentSelection.fileId > 0);
      }
    },
    isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    isInt(value) {
      return !isNaN(value)
                && parseInt(Number(value)) == value
                && !isNaN(parseInt(value, 10));
    },
    validateQuestionType(value, questionTypeId) {
      const regExps = {
        [this.formQuestionType.decimal]: /[+-]?([0-9]*[.])?[0-9]+/,
        [this.formQuestionType.number]: /[+-]?[0-9]+/,
      };
      const currentRegExp = regExps[questionTypeId];
      if (currentRegExp) {
        return this.validateRegExp(currentRegExp, value);
      }
    },
    validateRegExp(regExp, string) {
      return regExp.test(String(string).toLowerCase());
    },
    getQuestionsCurrentProcess(currentQuestion) {
      const self = this;
      if (currentQuestion && currentQuestion.QuestionProcess && currentQuestion.QuestionProcess.length > 0) {
        return currentQuestion.QuestionProcess[0];
      }
      return null;
    },
    getQuestionsFeedBacks(id) {
      return this.allFeedBacks.filter((x) => x.QuestionId === id);
    },
    getSectionsFeedBacksCount(id) {
      const self = this;
      let sectionFeedBacks = 0;
      const section = self.getFormSectionsWithQuestions.find((x) => x.Id === id);
      if (self.allFeedBacks && section) {
        section.QuestionGroups.forEach((group) => {
          group.Questions.forEach((question) => {
            sectionFeedBacks += self.allFeedBacks.filter((x) => x.QuestionId === question.Id).length;
          });
        });
      }
      return sectionFeedBacks;
    },
    setQuestionsCurrentSelection(id, newVal) {
      const self = this;
      const currentQuestion = self.getQuestionById(id);
      if (currentQuestion) {
        const temp = currentQuestion;
        if (Array.isArray(currentQuestion.Answer)) {
          temp.AnswerChanged = !compareArrayResult(currentQuestion.Answer, newVal);
        } else {
          temp.AnswerChanged = newVal != currentQuestion.Answer;
        }
        temp.NewAnswer = newVal;
        self.setQuestionById(id, temp);
        return newVal;
      }
      return null;
    },
    getQuestionCurrentAnswerProcessById(id) {
      const self = this;
      const currentQuestion = self.getQuestionById(id);
      if (currentQuestion && currentQuestion.QuestionProcess && currentQuestion.QuestionProcess.length > 0 && currentQuestion.QuestionProcess[0].AnswerProcesses && currentQuestion.QuestionProcess[0].AnswerProcesses.length > 0) {
        const currentAnswerProcesses = currentQuestion.QuestionProcess[0].AnswerProcesses;
        return currentAnswerProcesses.reduce((max, current) => ((moment(max.CreatedDate).isAfter(current.CreatedDate)) ? max : current));
      }
      return null;
    },
    getQuestionGroupByQuestionId(id) {
      const self = this;
      if (self.selectedForm && self.selectedForm.QuestionGroups) {
        return self.selectedForm.QuestionGroups.find((questionGroup) => questionGroup && questionGroup.Questions && questionGroup.Questions.some((question) => question.Id == id));
      }
      return null;
    },
    setQuestionById(id, newValue) {
      const self = this;
      if (self.selectedForm && self.selectedForm.QuestionGroups) {
        self.selectedForm.QuestionGroups.forEach((questionGroup) => {
          if (questionGroup && questionGroup.Questions) {
            questionGroup.Questions.forEach((question, index) => {
              if (question.Id == id) {
                Vue.set(questionGroup.Questions, index, newValue);
              }
            });
          }
        });
      }
    },
    getQuestionById(id) {
      const self = this;
      if (self.selectedForm && self.selectedForm.QuestionGroups) {
        for (const questionGroup of self.selectedForm.QuestionGroups) {
          if (questionGroup && questionGroup.Questions) {
            for (const question of questionGroup.Questions) {
              if (question.Id == id) {
                return question;
              }
            }
          }
        }
      }
      return null;
    },
    getQuestionsCurrentSelection(id) {
      const self = this;
      const currentQuestion = self.getQuestionById(id);
      if (currentQuestion) {
        if (currentQuestion.AnswerChanged) {
          return currentQuestion.NewAnswer;
        } if (currentQuestion.AnswerExist) {
          return currentQuestion.Answer;
        } if (self.getQuestionsCurrentProcess(currentQuestion) && self.getQuestionsCurrentProcess(currentQuestion).AnswerProcesses) {
          const currentAnswerProcesses = self.getQuestionsCurrentProcess(currentQuestion).AnswerProcesses;
          if (currentQuestion.QuestionTypeId == self.formQuestionType.multipleChoice) {
            const currentAnswer = currentAnswerProcesses[currentAnswerProcesses.length - 1];
            if (currentAnswer) {
              currentQuestion.Answer = currentAnswer.SelectionId;
              currentQuestion.AnswerExist = true;
              return currentAnswer.SelectionId;
            }
          } else if (currentQuestion.QuestionTypeId == self.formQuestionType.checkBox) {
            var lastAnswer = currentAnswerProcesses.sort((a,b) => new Date(b.CreatedDate).getTime() - new Date(a.CreatedDate).getTime())[0];
            var newArray = currentAnswerProcesses.filter(function (el) {
              return el.CreatedDate === lastAnswer.CreatedDate
            });
            const currentAnswer = newArray.map((x) => x.SelectionId);
            currentQuestion.Answer = currentAnswer;
            currentQuestion.AnswerExist = true;
            return currentAnswer;
          } else if (currentQuestion.QuestionTypeId == self.formQuestionType.imageUpload || currentQuestion.QuestionTypeId == self.formQuestionType.fileUpload) {
            let currentAnswer = currentAnswerProcesses.find((x) => x.FileId > 0);
            if (currentAnswer) {
              currentAnswer = currentAnswer.File;
              const file = {
                fileId: currentAnswer.Id,
                title: currentAnswer.Name,
                type: currentAnswer.ContentType,
                isActive: true,
                status: self.fileStatuses.uploaded,
                isLocal: false,
              };
              currentQuestion.Answer = file;
              currentQuestion.AnswerExist = true;
              return file;
            }
          } else {
            // const currentAnswer = currentAnswerProcesses.find((x) => x.Value);
            const currentAnswer = currentAnswerProcesses[currentAnswerProcesses.length - 1];
            if (currentAnswer) {
              currentQuestion.Answer = currentAnswer.Value;
              currentQuestion.AnswerExist = true;
              return currentAnswer.Value;
            }
          }
        } else {
          if (currentQuestion.QuestionTypeId == self.formQuestionType.multipleChoice) {
            return self.emptyObject;
          } if (currentQuestion.QuestionTypeId == self.formQuestionType.checkBox) {
            return [];
          } if (currentQuestion.QuestionTypeId == self.formQuestionType.imageUpload || currentQuestion.QuestionTypeId == self.formQuestionType.fileUpload) {
            return self.emptyObject;
          }
          return '';
        }
      }
      return null;
    },
    getQuestionsIndexInQuestionGroup(id) {
      const self = this;
      const questionGroup = self.getQuestionGroupByQuestionId(id);
      if (questionGroup) {
        return questionGroup.Questions.findIndex((question) => question.Id == id);
      }
      return 0;
    },
    async updateFormProcess() {
      const self = this;
      await api.getFormProcess(self.currentFormId, self.workOrderId).then((response) => {
        if (response.body && response.body.Data && self.selectedForm && self.selectedForm) {
          Vue.set(self.selectedForm, 'FormProcesses', [response.body.Data]);
        }
      });
    },
    getChangedAnswers() {
      const self = this;
      const massQuestionProcesses = [];

      self.selectedForm.QuestionGroups.forEach((questionGroup) => {
        questionGroup.Questions.forEach((question) => {
          if (question.AnswerChanged) {
            const formData = {};
            // formData.formProcessId = formProcessId;
            formData.questionId = question.Id;
            if (question.QuestionTypeId == self.formQuestionType.multipleChoice) {
              formData.selectedSelectionIds = [];
              formData.selectedSelectionIds.push(self.getQuestionsCurrentSelection(question.Id));
            } else if (question.QuestionTypeId == self.formQuestionType.checkBox) {
              formData.selectedSelectionIds = self.getQuestionsCurrentSelection(question.Id);
            } else if (question.QuestionTypeId == self.formQuestionType.imageUpload || question.QuestionTypeId == self.formQuestionType.fileUpload) {
              const currentSelection = self.getQuestionsCurrentSelection(question.Id);
              formData.fileId = currentSelection ? currentSelection.fileId : null;
            } else {
              formData.value = self.getQuestionsCurrentSelection(question.Id);
            }
            massQuestionProcesses.push(formData);
          }
        });
      });

      return massQuestionProcesses;
    },
    getMassQuestionProcess(questionIdList) {
      const self = this;
      const massQuestionProcesses = [];

      self.selectedForm.QuestionGroups.forEach((questionGroup) => {
        questionGroup.Questions.forEach((question) => {
          if (questionIdList.includes(question.Id) && question.AnswerChanged) {
            const formData = {};
            // formData.formProcessId = formProcessId;
            formData.questionId = question.Id;
            if (question.QuestionTypeId == self.formQuestionType.multipleChoice) {
              formData.selectedSelectionIds = [];
              formData.selectedSelectionIds.push(self.getQuestionsCurrentSelection(question.Id));
            } else if (question.QuestionTypeId == self.formQuestionType.checkBox) {
              formData.selectedSelectionIds = self.getQuestionsCurrentSelection(question.Id);
            } else if (question.QuestionTypeId == self.formQuestionType.imageUpload || question.QuestionTypeId == self.formQuestionType.fileUpload) {
              const currentSelection = self.getQuestionsCurrentSelection(question.Id);
              formData.fileId = currentSelection ? currentSelection.fileId : null;
            } else {
              formData.value = self.getQuestionsCurrentSelection(question.Id);
            }
            massQuestionProcesses.push(formData);
          }
        });
      });

      return massQuestionProcesses;
    },
    previousQuestion(e) {
      const self = this;
      const currentQuestionIndex = self.orderedQuestions.findIndex((question) => question.Id == self.selectedQuestionId);
      if (currentQuestionIndex <= 0) {
        return false;
      }
      self.saveAndSetSelectedQuestion(self.orderedQuestions[currentQuestionIndex - 1].Id);
    },
    async nextQuestion(e) {
      const self = this;
      await self.onSaveAnswers();
      const currentQuestionIndex = self.orderedQuestions.findIndex((question) => question.Id == self.selectedQuestionId);
      if (currentQuestionIndex < self.orderedQuestions.length - 1) {
        self.setSelectedQuestion(self.orderedQuestions[currentQuestionIndex + 1].Id);
      }
    },
    async init() {
      const self = this;
      this.showErrors = false;
      const promises = [
        api.getFormWithProcess({
          workOrderId: self.workOrderId,
          formId: self.currentFormId,
          isIncludeAnswerCondition: !self.isEditable,
          isIncludePoint: !self.isEditable,
        }),
        this.$store.dispatch('getFormSectionList', self.procedureTypeId),
        api.getComparisonOperatorList(),
        api.getOperandTypeList(),
      ];
      const responses = await Promise.all(promises);
      self.selectedForm = responses[0].body;
      //   self.continueFormButton = self.continueButton;
      self.allFeedBacks = self.formProcess && self.formProcess.WorkOrderHtmlContents ? self.formProcess.WorkOrderHtmlContents : [];
      self.hasFormFlag = self.allFeedBacks?.filter((feedack) => {
        return feedack.Question == null
      }).length > 0 ? true : false;
      self.formFeedBacks = self.allFeedBacks.filter((x) => !x.QuestionId);
      self.invalidAnswerCount = self.selectedForm && self.formProcess ? self.formProcess.InvalidAnswerCount : 0;

      this.comparisonOperators = responses[2].body;
      this.operandTypes = responses[3].body;

      if (self.isEditable && !self.isProcedureTypeScp) {
        self.initSelectedQuestionIndex();
      } else {
        self.selectFirstFormSection();
      }
      self.files = self.selectedForm.FormFiles.map((x) => ({
        fileId: x.FileId,
        title: x.File.Name,
        type: x.File.ContentType,
        isActive: true,
        status: self.fileStatuses.uploaded,
        isLocal: false,
      }));
    },
    async reloadFormWithProcess() {
      const self = this;
      console.log(self);
      const promises = [
        api.getFormWithProcess({
          workOrderId: self.workOrderId,
          formId: self.currentFormId,
          isIncludeAnswerCondition: !self.isEditable,
          isIncludePoint: !self.isEditable,
        }),
      ];
      const responses = await Promise.all(promises);
      self.selectedForm = responses[0].body;
    },

    onSelectedQuestionKeyDown(e) {
      if (this.userArrows) {
        switch (e.keyCode) {
          case 37:
            this.previousQuestion();
            break;
          case 38:
            this.previousQuestion();
            break;
          case 39:
            this.nextQuestion();
            break;
          case 40:
            this.nextQuestion();
            break;
          default:
            return;
        }
        e.preventDefault();
      }
    },
    async printItem() {
      const self = this;
      /* let beforeCallbackResult = await self.beforePrint();
            if (!beforeCallbackResult) {
                return false;
            } */
      const approvalsInformation = (await this.$globalApiClient.workOrder.workOrderGetWorkOrderApprovalsInformation({formId: this.formId, workOrderId: this.workOrderId})).data.Data;
       
      
      const pdfTable = (params) => {
        const tableBody = [];
        let header = [];
        if(params.header){
          if(params.header.length > 0){
            header = params.header.map((i) => ({
              fillColor: '#eeeeee',
              text: i.title,
              style: 'tableHeader',
              alignment: 'center',
            }));
  
            tableBody.push(header);
          }
        }

        if(params.body.length > 0){
          let bodyData = [];
          params.body.map((i) => {
            i.map((j) => {
              bodyData.push(j.value)
            }
              
            );
            tableBody.push(bodyData);
            bodyData = [];
          });
        }

        return [
          {
            style: 'table',
            table: {
              headerRows: 1,
              widths: params.widths,
              body: tableBody
            },
            layout: tableLayout,
          },
        ];
      };

      const pdfFormInformation = () => {
        return [
          {
            style: 'table',
            table: {
              widths: [50, 214, 214],
              body: [
                [
                  {
                    style: 'tableField',
                    rowSpan: 3,
                    innerHeight: 50,
                    stack: [
                      {
                        image: campusLogoResponse.data.Data,
                        fit: [50, 50],
                        style: 'questionImg',
                      },
                    ],
                  },
                  {
                    text: `${this.translate('form_type')}: ${self.selectedForm.ProcedureType.Name}`,
                  },
                  {
                    text: `${this.translate('version')}: ${self.selectedForm.Version}`,
                  },
                ],
                [
                  '',
                  {
                    text: `${this.translate('form_code')}: ${self.selectedForm.Code}`,
                  },
                  {
                    text: `${this.translate('name')}: ${self.selectedForm.Name}`,
                  },
                ],
                [
                  '',
                  {
                    //text: `${this.translate('form_description')}: ${self.selectedForm.Description === '' ? '-' : self.selectedForm.Description}`,
                    text: `${this.translate('form_description')}: ${self.selectedForm.Description ?? "-"}`,
                  },
                  {
                    text: `${self.translate('form_started_date')}: ${self.selectedForm != null && self.selectedForm.ModifiedDate != null ? 
                      dateFormat.viewDateTimeFormat(self.selectedForm.ModifiedDate) : 
                      (self.selectedForm != null && self.selectedForm.ModifiedDate == null && self.selectedForm.RevisionDate != null) ? 
                        dateFormat.viewDateTimeFormat(self.selectedForm.RevisionDate) : 
                        ((self.selectedForm != null && self.selectedForm.ModifiedDate == null && self.selectedForm.RevisionDate == null && self.selectedForm.CreatedDate != null) ? 
                          dateFormat.viewDateTimeFormat(self.selectedForm.CreatedDate) : 
                          '-')}`,
                    // text: `${self.translate('form_started_date')}: ${(self.formProcess != null && self.formProcess.CreatedDate != undefined) ? 
                    //   dateFormat.viewDateTimeFormat(self.formProcess.CreatedDate) : 
                    //   '-'}`,
                  },
                ],
                [
                  {
                    text: `${this.translate('work_order_number')}: ${this.workOrderId}`
                  },
                  {
                    text: `${this.translate('number_of_flag')}: ${self.allFeedBacks.length}`,
                  },
                  {
                    text: `${this.translate('number_of_abnormal_answers')}: ${self.invalidAnswerCount}`
                  },
                ],
              ]
            },
          },
        ];
      };

      const pdfStaffVendorInformation = () => {
        if (this.workOrderStatus == this.workOrderStatusEnum.approved || this.workOrderStatus == this.workOrderStatusEnum.pendingApproval){
          let widths = [];
          let body = [];
          const colCount = 2 + approvalsInformation.ApprovalVendors.length;
          const colWidth = 460 / colCount;

          for (let i = 0; i < colCount; i++) {
            widths.push(colWidth);
          }
          console.log(widths);

          // header row
          let headerRow = [];
          // first row
          let nameSurnameRoleNameRow = [];
          
          // second row
          let dateRow = [];

          // third row
          let signatureRow = [];

          // first column
          headerRow.push('İşi Yapan Kişi (Staff)');
          nameSurnameRoleNameRow.push({
            text: `${approvalsInformation.ApprovalStaff.NameSurname} - ${approvalsInformation.ApprovalStaff.RoleName}`,
          });
          dateRow.push({
            text: (approvalsInformation.ApprovalStaff.ApprovalDate != null ? `${dateFormat.viewDateTimeFormat(approvalsInformation.ApprovalStaff.ApprovalDate)}` : `-`),
          });
          signatureRow.push('');
          
          // middle columns
          
          approvalsInformation.ApprovalVendors.forEach((val, index)=>{
            headerRow.push(`İşi Yapan Kişi (Vendor) (${index + 1})`);
            nameSurnameRoleNameRow.push({
              text: `${val.NameSurname} - ${val.VendorName}`,
            });
            dateRow.push({
              text: (val.ApprovalDate != null ? `${dateFormat.viewDateTimeFormat(val.ApprovalDate)}` : `-`),
            });
            signatureRow.push({
              innerHeight: 100,
              border: [true, true, true, true],
              stack: (val.VendorLogo != "" ? 
                [
                  {
                    image: val.VendorLogo,
                    fit: [50, 100],
                    style: 'questionImg',
                  },
                  {
                    text: '',
                    style: 'emptySpace',
                  },
                ] : [
                  
                  {
                    text: '',
                    style: 'emptySpace2x',
                  },
              ]
            )});
          });

          // last column
          headerRow.push('İşi Onaylayan Kişi (Staff)');
          nameSurnameRoleNameRow.push({
            text: `${approvalsInformation.ApprovedStaff.NameSurname} - ${approvalsInformation.ApprovedStaff.RoleName}`,
          });
          dateRow.push({
            text: (approvalsInformation.ApprovedStaff.ApprovalDate != null ? `${dateFormat.viewDateTimeFormat(approvalsInformation.ApprovedStaff.ApprovalDate)}` : `-`),
          });
          signatureRow.push('');
          
          body.push(headerRow);
          body.push(nameSurnameRoleNameRow);
          body.push(dateRow);
          body.push(signatureRow);
          return[
            {
              id: 'signatureSection',
              style: 'table',
              table: {
                widths: widths,
                body: body
              },
            },
          ];
        }

        return [];
      };
      
      const pdfTitle = (title, className, hasFlag) => [
        {
          text: [ `${title}` + ' ',
            hasFlag ?
            {
              text: '' + ' ',
              style: className || 'symbol10'
            } :
            {
              text: '',
              style: '' //className || 'header'
            }
          ],
          style: className || 'header',
        },
        // {
        //   text: title,
        //   style: className || 'header',
        // },
        {
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 5,
              x2: 585 - 2 * 40,
              y2: 5,
              lineWidth: 1,
              lineColor: '#576472',
            },
          ],
        },
        {
          text: '',
          style: 'emptySpace',
        },
      ];
      const pdfQuestionBr = () => [
        {
          canvas: [
            {
              type: 'line',
              x1: 10,
              y1: 5,
              x2: 585 - 2 * 40,
              y2: 5,
              lineWidth: 1,
              lineColor: '#ddd',
            },
          ],
        },
        {
          text: '',
          style: 'emptySpace',
        },
      ];
      const pdfQuestionTitle = (title, className, isExpectedValue) => [
        {
          text: title,
          style: className || 'questionTitle',
          decoration: isExpectedValue ? 'underline' : '',
        },

      ];
      let tableLayout = {
        hLineWidth(i, node) {
          return i === 0 || i === node.table.body.length ? 2 : 1;
        },
        vLineWidth(i, node) {
          return i === 0 || i === node.table.widths.length ? 2 : 1;
        },
        hLineColor(i, node) {
          return i === 0 || i === node.table.body.length
            ? '#576472'
            : '#576472';
        },
        vLineColor(i, node) {
          return i === 0 || i === node.table.widths.length
            ? '#576472'
            : '#576472';
        },
      };

      const _questionGroups = self.selectedForm.QuestionGroups;
      //console.log(_questionGroups);
      // files
      await Promise.all(_questionGroups.map(async (i) => {
        await Promise.all(i.Questions.map(async (i) => {
          if (i.Answer && i.Answer.fileId) {
            const img = await api.getFile({ id: i.Answer.fileId, isContainsData: true });
            i.Answer.fileSrc = `data:${img.body.Data.ContentType};base64,${img.body.Data.Base64}`;
          }
          if (i.ImageId) {
            const img = await api.getFile({ id: i.ImageId, isContainsData: true });
            i.imgSrc = `data:${img.body.Data.ContentType};base64,${img.body.Data.Base64}`;
          }
          return i;
        }));
      }));
      const formSectionNodes = [];
      this.formSectionList.forEach((formSection, index) => {
        const formSectionNode = [];
        formSectionNode.push(pdfTitle(formSection.Name, 'header'));
        const questionGroups = _questionGroups.filter((questionGroup) => questionGroup.FormSectionId == formSection.Id).sort((v1, v2) => v1.Order - v2.Order).map((v) => v);
        const questionGroupNodes = [];
        questionGroups.forEach((i, index) => {
          const questionGroupNode = [];
          const questions = i.Questions.sort((v1, v2) => v1.Order - v2.Order).map((v) => v);

          questionGroupNode.push({
            text: '',
            style: 'emptySpace',
          });
          questionGroupNode.push(pdfTitle(i.Name, 'subHeader'));

          const questionNodes = [];
          questions.forEach((question, index) => {
            const questionNode = {
              id: 'question',
              style: 'question',
              stack: [],
            };
            // region selections
            const selections = [];
            if (question.Selections && question.Selections.length > 0) {
              selections.push({
                text: '',
                style: 'emptySpace',
              });
            }

            question.Selections.map((selection) => {
              selections.push({
                columns: [
                  {
                    width: 20,
                    alignment: 'center',
                    stack: [
                      {
                        canvas: [selection.Id === question.Answer || (typeof question.Answer === 'object'
                                                    && question.Answer.includes(selection.Id)) ? {
                            type: 'ellipse',
                            x: 24,
                            y: 4,
                            color: '#576472',
                            fillOpacity: 1,
                            r1: 6,
                            r2: 6,
                          } : {
                            type: 'rect',
                            x: 20,
                            y: 0,
                            w: 10,
                            h: 10,
                            lineWidth: 1,
                            lineColor: '#9e9e9e',
                            r: 100,
                          }],
                      },
                    ],
                  },
                  {
                    width: '*',
                    stack: [
                      ...pdfQuestionTitle(selection.Name, 'questionOption', selection.IsExpectedValue),
                    ],
                  },
                ],
              });
              selections.push({
                text: '',
                style: 'emptySpace',
              });
            });
            // endregion selections

            // region question content
            if (question.ImageId) {
              questionNode.stack.push({
                columns: [
                  {
                    width: '*',
                    stack: [
                      {
                        style: 'questionAnswer',
                        // QuestionText
                        text: [`${index + 1}.${question.Name}   `,
                          // MandatoryField Icon
                          question.IsRequired ? {
                            text: '' + '  ',
                            style: 'customSymbol',
                          } : '',
                          // Flag Icon
                          self.allFeedBacks.filter((feedBack) => {
                            return (feedBack.Question?.Id ?? 0) == question.Id; // feedBack.Question.Id == question.Id;
                          }).length > 0 ?
                          {
                            text: ' ' + '' + ' ',
                            style: 'symbol'
                          } : '',
                          // Correct or Wrong Icon
                          question.QuestionProcess && question.QuestionProcess.length > 0 ? question.QuestionProcess && question.QuestionProcess.length > 0 && (question.QuestionProcess[0] && question.QuestionProcess.length > 0 && question.QuestionProcess[0].IsValid) ? 
                          {
                            text: '',
                            style: 'symbol',
                          } : 
                          { 
                            text: '', 
                            style: 'symbol' 
                          } : '',
                        ],
                      },
                      ...pdfQuestionTitle(question.Description, 'questionDescription'),
                      ...selections,
                    ],
                  },
                  {
                    width: 200,
                    alignment: 'center',
                    stack: [
                      {
                        image: question.imgSrc,
                        fit: [100, 100],
                        style: 'questionImg',
                      },
                      {
                        text: '',
                        style: 'emptySpace',
                      },
                    ],
                  },
                ],
              });
            } else {
              questionNode.stack.push({
                columns: [
                  {
                    stack: [
                      {
                        style: 'questionAnswer',
                        // QuestionText
                        text: [`${index + 1}.${question.Name}   `,
                          // MandatoryField Icon
                          question.IsRequired ? {
                            text: '' + '  ',
                            style: 'customSymbol',
                          } : '',
                          // Flag Icon
                          self.allFeedBacks.filter((feedBack) => {
                            return (feedBack.Question?.Id ?? 0) == question.Id; // feedBack.Question.Id == question.Id;
                          }).length > 0 ?
                          {
                            text: ' ' + '' + ' ',
                            style: 'symbol'
                          } : '',
                          // Correct or Wrong Icon
                          question.QuestionProcess && question.QuestionProcess.length > 0 && (question.QuestionProcess[0] && question.QuestionProcess.length > 0
                          && question.QuestionProcess[0].IsValid !== undefined) ? (question.QuestionProcess[0].IsValid === true ? {
                            text: '',
                            style: 'symbol',
                          } : question.QuestionProcess[0].IsValid === false ? {
                            text: '',
                            style: 'symbol',
                          } : '') : '',
                        ],
                      },
                    ],
                  }],
              });
              questionNode.stack.push(pdfQuestionTitle(question.Description, 'questionDescription'));
              questionNode.stack.push(selections);
            }
            // endregion question content

            // region answer
            if (question.Answer) {
              if (question.Answer.fileId) {
                questionNode.stack.push({
                  image: question.Answer.fileSrc,
                  fit: [100, 100],
                  style: 'questionImg',
                });
              }
              questionNode.stack.push(pdfQuestionTitle(`${question.Answer.title ? question.Answer.title : ''}`, 'questionAnswer'));
              questionNode.stack.push({
                text: '',
                style: 'emptySpace',
              });
              questionNode.stack.push(pdfQuestionTitle(`${typeof question.Answer !== 'object' && typeof question.Answer !== 'number' ? question.Answer : ''}`, 'questionAnswer'));
              questionNode.stack.push({
                text: '',
                style: 'emptySpace',
              });

              questionNode.stack.push(pdfQuestionTitle(question.AnswerCondition ? this.answerConditionRecursive(question.AnswerCondition, 0) : '', 'questionAnswer'));
            } else if (question.QuestionTypeId != self.formQuestionType.multipleChoice && question.QuestionTypeId != self.formQuestionType.checkBox) {
              questionNode.stack.push({
                text: '',
                style: 'emptySpace',
              });

              questionNode.stack.push(pdfQuestionTitle(question.AnswerCondition ? this.answerConditionRecursive(question.AnswerCondition, 0) : '', 'questionAnswer'));
            }

            // endregion answer

            // region question tag
            if (question.QuestionProcess && question.QuestionProcess.length > 0) {
              questionNode.stack.push({
                text: '',
                style: 'emptySpace',
              });

              const createDate = question.QuestionProcess[question.QuestionProcess.length - 1].CreatedDate;
              const createdUser = question.QuestionProcess[question.QuestionProcess.length - 1].CreatedUser;

              // Add Label
              questionNode.stack.push(pdfQuestionTitle(createDate ? `Date: ${dateFormat.viewDateTimeFormat(createDate)}` : '', 'questionAnswer'));
              questionNode.stack.push(pdfQuestionTitle(createdUser ? `Fullname: ${createdUser.NameSurname}` : '', 'questionAnswer'));
            }
            // endregion question tag

            questionNode.stack.push(pdfQuestionBr());

            questionNodes.push(questionNode);
          });

          questionGroupNode.push({
            /* id: 'question',
                        style: 'question', */
            stack: [
              questionNodes,
            ],
          });

          questionGroupNode.push({
            text: '',
            style: 'emptySpace',
          });

          questionGroupNodes.push({
            id: 'questionGroup',
            style: 'questionGroup',
            stack: [
              ...questionGroupNode,
            ],
          });
        });
        formSectionNode.push(questionGroupNodes);
        formSectionNodes.push({
          id: 'formSection',
          style: 'formSection',
          stack: formSectionNode,
        });
      });
      
      const campusLogoResponse = await this.$globalApiClient.campus.campusGetCampusLogo();
      
      const printObj = {
        
        content: [
          ...pdfTitle(self.selectedForm.Name, null, self.hasFormFlag),

          // Form Information Table
          /*...pdfTable({
            widths: [100, 180, 180],
            header: [{
              title: `${this.translate('code')}`,
            }, {
              title: `${this.translate('form_type')}`,
            }, {
              title: `${this.translate('version')}`,
            }, {
              title: `${this.translate('number_of_flag')}`,
            }, {
              title: `${this.translate('number_of_abnormal_answers')}`,
            }],
            
            ],
          }),*/

          ...pdfFormInformation(),
          
          ...formSectionNodes,

          // Staff - Vendor Information Table
          ...pdfStaffVendorInformation(),
        ],
        /*   footer: {
                       columns: [
                           {
                               text: 'Vendor',
                           },
                           {width: 1, canvas: [{type: 'line', x1: 0, y1: 0, x2: 0, y2: 40, lineWidth: 1}]},
                           {
                               text: 'Master'
                           },
                           {width: 1, canvas: [{type: 'line', x1: 0, y1: 0, x2: 0, y2: 40, lineWidth: 1}]},
                           {
                               text: 'Observer'
                           }
                       ],
                       margin: [20, 0, 20, 30],
                   }, */
        header(currentPage, pageCount) {
          return {
            text: `${currentPage.toString()} / ${pageCount}`,
            alignment: 'right',
            margin: [0, 20, 20, 0],
            fontSize: 7,
          };
        },
        styles: {
          header: {
            fontSize: 10,
            bold: true,
            margin: [0, 0, 0, 2],
            color: '#243951',
          },
          questionTitle: {
            fontSize: 8,
            bold: true,
            margin: [10, 0, 0, 2],
            color: '#243951',
          },
          questionDescription: {
            fontSize: 6,
            bold: false,
            margin: [10, 0, 0, 2],
            color: '#243951',
          },
          questionOption: {
            fontSize: 6,
            bold: false,
            margin: [13, 0, 0, 2],
            color: '#243951',
          },
          questionAnswer: {
            fontSize: 7,
            bold: false,
            margin: [10, 0, 0, 2],
            color: '#243951',
          },
          attachments: {
            fontSize: 7,
            bold: true,
            margin: [0, 6, 0, 0],
            color: '#243951',
          },
          questionGroup: {
            margin: [10, 0, 0, 0],
          },
          subHeader: {
            fontSize: 10,
            bold: true,
            margin: [0, 0, 0, 5],
            color: '#243951',
          },
          emptySpace: {
            margin: [0, 0, 0, 2],
          },
          emptySpace2x: {
            margin: [20, 0, 0, 20],
          },
          emptySpace3x: {
            margin: [30, 0, 0, 30],
          },
          emptySpace4x: {
            margin: [40, 0, 0, 40],
          },
          emptySpace5x: {
            margin: [50, 0, 0, 50],
          },
          table: {
            margin: [0, 5, 0, 15],
            fontSize: 7
          },
          tableHeader: {
            bold: true,
            fontSize: 7,
            color: '#243951',
          },
          tableField: {
            bold: false,
            fontSize: 6,
            color: '#576472',
          },
          timelineStatus: {
            bold: true,
            fontSize: 6,
            color: '#fff',
          },
          timelineStatusDate: {
            bold: true,
            fontSize: 6,
            color: '#fff',
          },
          questionImg: {
            margin: [0, 0, 0, 0],
          },
          symbol: {
            font: 'FontAwesome',
            fontSize: 6 ,
          },
          symbol10: {
            font: 'FontAwesome',
            fontSize: 10 ,
          },
          customSymbol: {
            font: 'FontAwesome',
            fontSize: 6,
          },
          symbolRegular: {
            font: 'FontAwesomeRegular',
          },
        },
        
        pageSize: 'A4',
        pageBreakBefore(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
          if (currentNode.id === 'question' /* || currentNode.id === 'formSection' */) {
            return currentNode.pageNumbers.length > 1;
          } if (currentNode.id === 'questionGroup') {
            const question = followingNodesOnPage.find((node) => node.id == 'question');
            return question ? (question.pageNumbers.length > 1) : false;
          }
          if (currentNode.id === 'signatureSection') {
            return currentNode.pageNumbers.length > 1;
          }
          return false;
        },
      };
      
      pdfMake.createPdf(printObj).print();
      return false;
    },
    answerConditionRecursive(answerCondition, index) {
      const operandType = this.operandTypes.find((operandType) => operandType.Id === answerCondition.OperandTypeId);
      const array = [];
      if (answerCondition && answerCondition.AnswerConditionRules && answerCondition.AnswerConditionRules.length > 0) {
        for (const answerConditionRule of answerCondition.AnswerConditionRules) {
          if (answerConditionRule.ComparisonOperator.IsSecondValueNeeded) {
            array.push(`${answerConditionRule.ComparisonOperator.Name} ${answerConditionRule.FirstValue}, ${answerConditionRule.SecondValue}`);
          } else {
            array.push(`${answerConditionRule.ComparisonOperator.Name} ${answerConditionRule.FirstValue}`);
          }
        }
      }
      if (answerCondition && answerCondition.Children && answerCondition.Children.length > 0) {
        for (const child of answerCondition.Children) {
          array.push(this.answerConditionRecursive(child, index + 1));
        }
      }

      let str = `\n${' '.repeat(index)}(`;
      str += array.join(` ${operandType.Name} `);
      return index - 1 > -1 ? `${str})` + `\n${' '.repeat(index - 1)}` : `${str})`;
    },

    // region handlers
    onPrintClick() {
      this.printItem();
    },
    async onSubmitFormProcess(e) {
      const self = this;
      blockui.blockElement(self, self.$el);
      await api.startFormProcess({
        workOrderId: self.workOrderId,
        formId: self.currentFormId,
      }).then(async (response) => {
        if (response.body.IsSuccess) {
          await self.updateFormProcess();
          toastr.success(self.translate('form_started'));
        } else {
          toastr.error(response.body.Message);
        }
        blockui.unBlockElement(self.$el);
      });
    },
    async saveAndSetSelectedQuestion(questionId) {
      await this.onSaveAnswers();
      return this.setSelectedQuestion(questionId);
    },
    setSelectedQuestion(questionId) {
      const self = this;
      if (!self.isEditable) {
        return false;
      }
      self.selectedQuestionId = questionId;
      const questionGroup = self.getQuestionGroupByQuestionId(questionId);
      self.selectedQuestionGroupId = questionGroup.Id;
      self.selectedFormSectionId = questionGroup.FormSectionId;
      if (!this.isProcedureTypeScp) {
        self.scrollToSelectedQuestionContainer();
      }
    },
    async saveAndSetSelectedQuestionGroup(questionGroupId) {
      await this.onSaveAnswers();
      return this.setSelectedQuestionGroup(questionGroupId);
    },
    setSelectedQuestionGroup(questionGroupId) {
      const self = this;
      this.selectedQuestionGroupId = this.selectedQuestionGroupId == questionGroupId ? null : questionGroupId;
    },
    async saveAndSetSelectedFormSection(formSectionId) {
      await this.onSaveAnswers();
      return this.setSelectedFormSection(formSectionId);
    },
    setSelectedFormSection(formSectionId) {
      const self = this;
      if (this.selectedFormSectionId == formSectionId) {
        return true;
      }
      if (this.isUploadProcessExist) {
        toastr.warning('There is upload processing. Wait until process done or abort process.');
        return false;
      }
      this.selectedFormSectionId = formSectionId;

      const sectionQuestions = [];
      this.selectedForm.QuestionGroups.filter((questionGroup) => questionGroup.FormSectionId == formSectionId).forEach((questionGroup) => {
        questionGroup.Questions.forEach((question) => {
          sectionQuestions.push(question);
        });
      });

      if (this.isEditable && !this.isProcedureTypeScp) {
        // if invalid (unanswered) question exist in section select it
        for (const sectionQuestion of sectionQuestions) {
          if (self.isInvalid(sectionQuestion)) {
            self.setSelectedQuestion(sectionQuestion.Id);
            return true;
          }
        }

        // if all questions are answered select first one
        if (sectionQuestions && sectionQuestions.length > 0) {
          self.setSelectedQuestion(sectionQuestions[0].Id);
        }

        return true;
      } if (sectionQuestions && sectionQuestions.length > 0) {
        this.selectedQuestionGroupId = sectionQuestions[0].QuestionGroupId;
      }
    },
    onMultipleSelectionChanged(questionTypeId) {
      if (questionTypeId == this.formQuestionType.multipleChoice) {
        this.nextQuestion();
      }
    },
    onQuestionKeyDown() {
    },
    async onQuestionClicked(e, clickedQuestionId) {
      if (!this.isEditable) {
        return;
      }
      if (!this.isProcedureTypeScp) {
        this.saveAndSetSelectedQuestion(clickedQuestionId);
      } else {
        if (this.isUploadProcessExist) {
          toastr.warning('There is upload processing. Wait until process done or abort process.');
          return null;
        }
        if (this.selectedQuestionId > 0 && this.selectedQuestionId != clickedQuestionId) {
          const { saveProcessQuestionIds } = this;
          const { selectedQuestionId } = this;

          let index = saveProcessQuestionIds.findIndex((x) => x == selectedQuestionId);
          if (index < 0) {
            this.saveProcessQuestionIds.push(selectedQuestionId);
          } else {
            return;
          }
          this.saveCurrentQuestion(this.selectedQuestionId).then(() => {
            index = saveProcessQuestionIds.findIndex((x) => x == selectedQuestionId);
            if (index >= 0) {
              this.saveProcessQuestionIds.splice(index, 1);
            }
          });
        }
        if (this.selectedQuestionId != clickedQuestionId) {
          this.setSelectedQuestion(clickedQuestionId);
        }
      }
    },
    // endregion

    // region Save
    async onFinishFormProcess(e) {
      const self = this;
      // todo company config'e göre isvalid mi kontrol et
      blockui.blockElement(self, self.$el);
      if (self.companyConfigCheckRequired && self.invalidQuestions.length > 0) {
        self.showErrors = true;

        const firstInvalidQuestionOfCurrentSection = self.invalidQuestions.find((question) => self.getQuestionGroupByQuestionId(question.Id).FormSectionId == self.selectedFormSectionId);

        const question = firstInvalidQuestionOfCurrentSection || self.invalidQuestions[0];
        self.selectedFormSectionId = self.getQuestionGroupByQuestionId(question.Id).FormSectionId;
        self.selectedQuestionGroupId = question.QuestionGroupId;
        self.$nextTick(() => {
          $(self.$refs[`question-${question.Id}`]).focus();
        });

        toastr.warning(self.translate('please_answer_all_required_questions'));
        blockui.unBlockElement(self.$el);
        return null;
      }

      if (this.isUploadProcessExist) {
        toastr.warning(self.translate('wait_until_upload_process_done'));
        blockui.unBlockElement(self.$el);
        return null;
      }

      const massQuestionProcesses = self.getChangedAnswers();
      try {
        const response = await api.endFormProcess({
          massQuestionProcesses,
          workOrderId: self.workOrderId,
          formId: self.currentFormId,
          // feedback: self.feedback,
        });

        if (response.body.AffectedCount > 0) {
          self.resetQuestionsWithNewQuestionProcesses(response.body.GeneratedEntities);
        }

        if (self.selectedWorkOrderFormsCount > 1) {
          swal.check(self, self.translate('are_you_sure'), self.translate('do_you_want_to_continue_answering_the_form')).then(async (e) => {
            if (e.value) {
              // TODO: Bitince forma devam etmek için eklendi. Bütün formların durumları tamamlandı ise onaya gönderilsin mi diye sorulacak ileride ki sprintde yapılacak.
              await self.$store.dispatch('getWorkOrderFormsByWorkOrderIdAndFormId', {
                workOrderId: self.workOrderId,
                formId: self.currentFormId,
              });
              const promises = [
                api.getNextForm(self.workOrderId, self.currentFormId),
              ];
              const
                responses = await Promise.all(promises);
              $(`#${self.modalId} .modal-content`).first().animate({
                scrollTop: $('#selectedQuestionContainer').offset().top,
              }, 500);
              if (responses[0].body) {
                responses[0].body.Id && Vue.set(self, 'currentFormId', responses[0].body.Id);
                blockui.blockModal(self);
                await self.init();
                self.continueFormButton = true;
                blockui.unBlockModal();
              } else {
                self.$router.push({ name: 'MyWorkOrder', query: { workOrderId: self.workOrderId } });
                $(`#${self.modalId}`).modal('toggle');
              }
            } else {
              $(`#${self.modalId}`).modal('toggle');
            }
          });
        } else {
          $(`#${self.modalId}`).modal('toggle');
        }
        toastr.success(self.translate('save_successfully'));
        self.updateFormProcess();
      } catch (e) {
        toastr.error(e.message);
      } finally {
        blockui.unBlockElement(self.$el);
      }
    },
    resetQuestionsWithNewQuestionProcesses(generatedQuestionProcesses) {
      const self = this;
      for (const generatedQuestionProcess of generatedQuestionProcesses/* response.body.GeneratedEntities */) {
        const question = self.getQuestionById(generatedQuestionProcess.QuestionId);
        generatedQuestionProcess.AnswerProcesses = [{
          CreatedUser: {
            Id: generatedQuestionProcess.CreatedUserId,
            Name: localStorage.getItem('userName'),
          },
          CreatedDate: generatedQuestionProcess.CreatedDate,
        }];
        Vue.set(question, 'QuestionProcess', [generatedQuestionProcess]);
        if (question.AnswerChanged) {
          question.Answer = question.NewAnswer;
          question.AnswerExist = true;
        }
        Vue.delete(question, 'AnswerChanged');
        Vue.delete(question, 'NewAnswer');
      }
    },
    async saveAnswers(massQuestionProcesses) {
      const self = this;
      try {
        const postData = {
          MassQuestionProcesses: massQuestionProcesses,
          WorkOrderId: self.workOrderId
        }
        const response = await this.$globalApiClient.questionProcess.questionProcessSave( postData);
        if (response.data.AffectedCount > 0) {
          toastr.success(self.translate('save_successfully'));
          return response.data.GeneratedEntities;
        }
        toastr.error(response.data.Message);
        return false;
      } catch (e) {
        toastr.error(e.message);
        return false;
      }
    },
    async onSaveAnswers(e) {
      const self = this;
      if (this.isUploadProcessExist) {
        toastr.warning('There is upload processing. Wait until process done or abort process.');
        return null;
      }
      const massQuestionProcesses = self.getChangedAnswers();
      if (massQuestionProcesses.length == 0) {
        return true;
      }
      blockui.blockElement(self, self.$el);
      const result = await this.saveAnswers(massQuestionProcesses);
      if (!result) {
        blockui.unBlockElement(self.$el);
        return;
      }

      self.selectedForm.QuestionGroups.forEach((questionGroup) => {
        questionGroup.Questions.forEach((question) => {
          const newQuestionProcess = result.find((questionProcess) => questionProcess.QuestionId == question.Id);
          if (newQuestionProcess) {
            /* Vue.set(newQuestionProcess, "CreatedUser", {
                            AnswerProcesses: [{
                                CreatedUser: {
                                    Id: newQuestionProcess.CreatedUserId,
                                    Name: localStorage.getItem('userName')
                                }
                            }]
                        }); */

            newQuestionProcess.AnswerProcesses = [{
              CreatedUser: {
                Id: newQuestionProcess.CreatedUserId,
                Name: localStorage.getItem('userName'),
              },
              CreatedDate: newQuestionProcess.CreatedDate,
            }];

            Vue.set(question, 'QuestionProcess', [newQuestionProcess]);
          }
          if (question.AnswerChanged) {
            question.Answer = question.NewAnswer;
            question.AnswerExist = true;
          }
          Vue.delete(question, 'AnswerChanged');
          Vue.delete(question, 'NewAnswer');
        });
      });

      if (!self.formProcess) {
        self.updateFormProcess();
      }
      blockui.unBlockElement(self.$el);
    },
    async saveCurrentQuestion(selectedQuestionId) {
      const self = this;
      if (selectedQuestionId <= 0) {
        return false;
      }
      if (this.isUploadProcessExist) {
        toastr.warning('There is upload processing. Wait until process done or abort process.');
        return null;
      }
      const selectedQuestion = this.getQuestionById(selectedQuestionId);
      if (!selectedQuestion) {
        return null;
      }
      if (!selectedQuestion.AnswerChanged) {
        return true;
      }

      const formData = {};
      formData.questionId = selectedQuestionId;
      if (selectedQuestion.QuestionTypeId == self.formQuestionType.multipleChoice) {
        formData.selectedSelectionIds = [];
        formData.selectedSelectionIds.push(self.getQuestionsCurrentSelection(selectedQuestionId));
      } else if (selectedQuestion.QuestionTypeId == self.formQuestionType.checkBox) {
        formData.selectedSelectionIds = self.getQuestionsCurrentSelection(selectedQuestionId);
      } else if (selectedQuestion.QuestionTypeId == self.formQuestionType.imageUpload || selectedQuestion.QuestionTypeId == self.formQuestionType.fileUpload) {
        const currentSelection = self.getQuestionsCurrentSelection(selectedQuestionId);
        formData.fileId = currentSelection ? currentSelection.fileId : null;
      } else {
        formData.value = self.getQuestionsCurrentSelection(selectedQuestionId);
      }
      const questionListItem = self.$refs[`questionList-${selectedQuestionId}`];

      blockui.blockElement(self, questionListItem);
      const result = await this.saveAnswers([formData]);
      if (!result) {
        if (selectedQuestion) {
          Vue.delete(selectedQuestion, 'AnswerChanged');
          Vue.delete(selectedQuestion, 'NewAnswer');
        }
        blockui.unBlockElement(questionListItem);
        return;
      }

      for (const generatedEntity of result) {
        const question = this.getQuestionById(generatedEntity.QuestionId);
        if (question) {
          generatedEntity.AnswerProcesses = [{
            CreatedUser: {
              Id: generatedEntity.CreatedUserId,
              Name: localStorage.getItem('userName'),
            },
            CreatedDate: generatedEntity.CreatedDate,
          }];

          Vue.set(question, 'QuestionProcess', [generatedEntity]);
          if (question.AnswerChanged) {
            Vue.set(question, 'Answer', question.NewAnswer);
            Vue.set(question, 'AnswerExist', true);
          }
          Vue.delete(question, 'AnswerChanged');
          Vue.delete(question, 'NewAnswer');
        }
      }
      if (!self.formProcess) {
        self.updateFormProcess();
      }
      blockui.unBlockElement(questionListItem);
    },
    // endregion
    changeForm(e) {
      this.$emit('changeForm', e);
    },
  },
  watch: {
    formId() {
      this.currentFormId = this.formId;
      this.init();
    },
  },
};
