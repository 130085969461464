


































import { Component, Prop } from 'vue-property-decorator';
import { ClickEventArgs } from '@syncfusion/ej2-vue-navigations';
import CFMGridPage from '@/scripts/CFMGridPage'

import * as swal from '@/helpers/swal'
import * as blockui from '@/helpers/block-ui'
import * as api from '../../scripts/services/api';

import { CFMModelWorkOrderView, CFMDTOMaintenancePlan } from '@/scripts/services/ClientApiAuto'
import MaintenancePlanDialog from './MaintenancePlanDialog.vue'

import { mixinz } from '@/scripts/global/mixinz';
import moment from 'moment';
import { L10n } from '@syncfusion/ej2-base';


@Component<MaintenancePlan>({

    components: {
        'maintenance-plan-dialog' : MaintenancePlanDialog
    },
    mixins: [mixinz]
})
export default class MaintenancePlan extends CFMGridPage<CFMModelWorkOrderView> {
    @Prop()
    isContractPage?:boolean;
    @Prop()
    contractId?:number;
    entityType = "WorkOrder";
    sorts:{field:string,direction:string}[] = [{ field:"Id", direction:"Descending" }];
    // @ts-ignore
    filterSettings = { 
        type: 'Menu',
        // @ts-ignore
        columns: !this.isContractPage ?  [
            { field: 'StartDate', matchCase: false, operator: 'greaterthanorequal', predicate: 'and', value: moment().toISOString() },
            { field: 'EndDate', matchCase: false, operator: 'lessthanorequal', predicate: 'and', value: moment().add(7, 'days').endOf('week').toISOString() },
            { field: 'OrderSourceId', matchCase: false, operator: 'equal', predicate: 'and', value: 1}
        ] : []
    };
    
    async created(){
        if(!this.isContractPage){
            const newToolbarItems = [{
                text: this.translate('transform_to_work_order'), tooltipText: this.translate('transform_to_work_order'), prefixIcon: 'e-open-hyperlink', id: 'TransformToWorkOrder',
            }, {
                text: this.translate('show'), tooltipText: this.translate('show'), prefixIcon: 'e-preview', id: 'Show',
            }];
            this.toolbar.push(...newToolbarItems);
        }else {
            this.toolbar = []
        }
      L10n.load({
            "tr": {
                "grid": {
                    "FilterButton": "Filtrele",
                    "ClearButton": "Temizle"
                }
            }
        });
        if(this.isContractPage){
            await this.getContractPageRecords()
        }
    }

    async getContractPageRecords() {
        // @ts-ignore
        api.getDeviceListByContractId(this.contractId).then((res) => {
                // @ts-ignore
                let newFilter = res.body.map((capacityEquipment) => {
                    // @ts-ignore
                    return { field: 'DeviceName', matchCase: false, operator: 'equal', predicate: 'or', value: capacityEquipment.Text}
                })
                this.filterSettings = {
                    type: "Menu",
                    columns: newFilter
                };
        })
    }

    async mounted(){
        if(!this.isContractPage){
            this.dataStateChange(this.state);
        }else{
            await this.getContractPageRecords();
            this.dataStateChange(this.state);
        }

    }

    refreshGrid(){
        this.dataStateChange(this.state);
    }

    customiseCell(args: any) { // Added status column
        if(args.column.field === 'OrderStatusName') {
            args.cell.classList.add('bg-color');
        }
    }

    queryCellInfo(args: any) { // Added status column
        if(args.column) {
            if(args.column.field === 'OrderStatusName') {
                let value = args.data["ColorCode"];
                args.cell.style.backgroundColor = value;
                return;
            }
        }
    }

    recordDoubleClick(args:any){
        if(this.isContractPage){
            return;
        }
        this.edit(args.rowData.Id);
    }

    async delete(data: CFMDTOMaintenancePlan){
        // @ts-ignore
        const e = await swal.check(this, data.Id);
        if (e.value) {
            // @ts-ignore
            if(data.IsDeletable){
                blockui.pageBlock(this);
                const response = await this.apiClient.maintenancePlan.maintenancePlanDelete({id: data.Id});
                if (response.data.IsSuccess) {
                    toastr.success(this.translate('delete_successfully'));
                    this.refreshGrid();
                } else {
                    const errorMessage = response.data.Message ? response.data.Message : "Error";
                    toastr.error(errorMessage);
                }
                blockui.unBlockPage();
            }else{
                toastr.error(this.translate("work_order_delete_error"));
            }
            
        }
    }

    async edit(id: number){
        this.selectedId = id;
        this.isDialogActive = true;
        await this.$nextTick();
        (<MaintenancePlanDialog>this.$refs.maintenancePlanDialog).showDialog();
    }

    async add(){
        this.selectedId = null;
        this.isDialogActive = true;
        await this.$nextTick();
        (<MaintenancePlanDialog>this.$refs.maintenancePlanDialog).showDialog();
    }


    async transformToWorkOrder(data: CFMDTOMaintenancePlan){
        const e = await swal.check(this, this.translate('transform_to_work_order'), data.Id);
        if (e.value) {
            blockui.pageBlock(this);
            const response = await this.apiClient.maintenancePlan.maintenancePlanTransFormToWorkOrder(<number>data.Id);
            if (response.data.IsSuccess) {
                blockui.unBlockPage();
                this.$router.push({ name: 'AddWorkOrder', query: { 'workOrderId': data.Id ? data.Id.toString() : '' } });
            } else {
                const errorMessage = response.data.Message ? response.data.Message : "Error";
                toastr.error(errorMessage);
                blockui.unBlockPage();
            }
            blockui.unBlockPage();
        }
    }

    async show(data: CFMDTOMaintenancePlan){
        this.$router.push({ name: 'MyWorkOrder', query: { from: 'WorkOrderList', 'workOrderId': data.Id ? data.Id.toString() : '' } });
    }

    async toolbarClick(args: ClickEventArgs){
        var selectedRecords = (<any>this.$refs.grid).getSelectedRecords();
        if (args.item.id === 'Edit') {
            if(selectedRecords.length > 0){
                this.edit(selectedRecords[0].Id);
            }
        }
        else if(args.item.id === 'Add'){
               this.add();
        }
        else if(args.item.id === 'Delete'){
            if(selectedRecords.length > 0){
                this.delete(selectedRecords[0]);
            }
        }
        else if(args.item.id === 'TransformToWorkOrder'){
            if(selectedRecords.length > 0){
                this.transformToWorkOrder(selectedRecords[0]);
            }
        }
        else if(args.item.id === 'Show'){
            if(selectedRecords.length > 0){
                this.show(selectedRecords[0]);
            }
        }
        this.toolbarClickBase(args);
    }
}
