














































































































































































































































import CFMDialog from "@/scripts/CFMDialog";
import {Component, Vue} from 'vue-property-decorator';

import * as blockui from "@/helpers/block-ui";
import { required } from "vuelidate/lib/validators";
import CFMMultiselect from '@/components/partials/CFMMultiselect.vue'
import { mixinz } from '@/scripts/global/mixinz';


import { CFMDTOSparePartType, CFMDTOSparePartDevice } from "@/scripts/services/ClientApiAuto";

@Component<SparePartTypeDialog>({
  mixins: [mixinz],
  components: {
        'cfm-multiselect': CFMMultiselect,
  },
  validations() {
    return {
      sparePartType: {
        Name: { required },
        KindId: { required },
        CategoryId: { required },
        SubCategoryId: { required }
      },
    };
  }
})
export default class SparePartTypeDialog extends CFMDialog {
  drag: boolean = false;
  sparePartType: CFMDTOSparePartType = { Name: "" };
  sparePartCategoryList: { Name: String; Id: Number }[] = [];
  sparePartSubCategoryList: { Name: String; Id: Number }[] = [];
  sparePartKindList: { Name: String; Id: Number }[] = [];
  capacityEquipmentList: { Name: String; Id: Number }[] = [];

  multiSelectProps = {
        "close-on-select": false,
        "clear-on-select": false,
        "preserve-search": true,
    }

  get selectOptions() {
        return {
            searchable: true,
            placeholder: this.translate('please_select'),
            "select-label": this.translate('select_label'),
            "deselect-label": this.translate('deselect_label'),
            "selected-label": this.translate('selected_label'),
            "show-no-results": false,
        }
  }

  get selectedCapacityEquipments() {
      if (this.sparePartType.SparePartDevices?.length == 0) {
          return null;
      } else {
          var a = this.sparePartType.SparePartDevices?.map(x => {
              let selectedObj = this.capacityEquipmentList.find(y => x.DeviceId == y.Id);
              if (selectedObj) {
                  return selectedObj;
              } else {
                  return {};
              }
          });
          return a;
      }
    }

  async created() {
    blockui.blockModal(this);
    if (this.isUpdate) {
      const res = await this.apiClient.sparePartType.sparePartTypeGetById({
        id: <number>this.id
      });
      this.sparePartType = res.data;
    }
    const promises = [this.getSparePartKinds(),this.getCapacityEquipments()];
    blockui.unBlockModal();
  }

  async getSparePartKinds(){
    const response = await this.apiClient.sparePartKind.sparePartKindGetKinds();
    this.sparePartKindList = response.data.map(x => ({
      Name: <string>x.Text,
      Id: <number>x.Value
    }));
  }

  async getSparePartKindsById(kindId: number){
    const response = await this.apiClient.sparePartKind.sparePartKindGetById({id:kindId});
    this.sparePartType.Kind = response.data;
    this.sparePartType.Id = this.id ? this.id : 0;
    this.sparePartType.Category = response.data.Category;
    this.sparePartType.SubCategory = response.data.SubCategory;
    this.sparePartType.CategoryId = response.data.CategoryId;
    this.sparePartType.SubCategoryId = response.data.SubCategoryId;
  }

  async getSparePartSubCategories(categoryId: number) {
    const response = await this.apiClient.sparePartSubCategory.sparePartSubCategoryGetCategories({categoryId: categoryId});
    this.sparePartSubCategoryList = response.data.map(x => ({
      Name: <string>x.Text,
      Id: <number>x.Value
    }));
  }

  async getCapacityEquipments() {
    const response = await this.apiClient.capacityEquipment.capacityEquipmentGetCapacityEquipments();
    this.capacityEquipmentList = response.data.map(x => ({
      Name: <string>x.Text,
      Id: <number>x.Value
    }));
  }

  removeCapEq(capEq: CFMDTOSparePartDevice) {
      if(!this.sparePartType.SparePartDevices) {return;}
        let capIndex = this.sparePartType.SparePartDevices.findIndex(x => x.DeviceId == capEq.DeviceId);
        this.sparePartType.SparePartDevices.splice(capIndex, 1);
  }

  selectCapEq(capEq: CFMDTOSparePartDevice) {
      if (!this.sparePartType.SparePartDevices) {
          Vue.set(this.sparePartType, 'SparePartDevices', []);
      }
      if (!capEq.DeviceId) {
        capEq.DeviceId = capEq.Id;
        // @ts-ignore
        capEq['Device'] = {Name:capEq.Name,Id:capEq.Id}
      }
      (<CFMDTOSparePartDevice[]>this.sparePartType.SparePartDevices).push(capEq);
  }

  onKindChanged(args: any){
    this.getSparePartKindsById(args.Kind.Id);
  }

  async onSubmit() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    // @ts-ignore
    for (let index = 0; index < this.sparePartType.SparePartDevices.length; index++) {
      // @ts-ignore
      const element = this.sparePartType.SparePartDevices[index];
      if (element.Id == element.DeviceId) {
      // @ts-ignore
        this.sparePartType.SparePartDevices[index].Id = 0;
      }
    }
    let formData = {
      Id: this.sparePartType.Id,
      Name: this.sparePartType.Name,
      KindId: this.sparePartType.KindId,
      CategoryId: this.sparePartType.CategoryId,
      SubCategoryId: this.sparePartType.SubCategoryId,
      SparePartDevices: this.sparePartType.SparePartDevices ? this.sparePartType.SparePartDevices  : []
    };
    blockui.blockModal(this);
    try {
      const response = await this.apiClient.sparePartType.sparePartTypeSave(formData);
      if (response.data.IsSuccess) {
        toastr.success(this.translate("save_successfully"));
        this.saveSucceeded();
        this.close();
      } else {
        const errorMessage = response.data.Message
          ? response.data.Message
          : "Error";
        toastr.error(errorMessage);
      }
    } catch (e) {
      console.log("err", e);
      toastr.error(e);
    } finally {
      blockui.unBlockModal();
    }
  }
}
