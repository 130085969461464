





























import { Component } from 'vue-property-decorator';

import * as swal from '@/helpers/swal'
import * as blockui from '@/helpers/block-ui'

import { ClickEventArgs } from '@syncfusion/ej2-vue-navigations';
import CFMGridPage from '@/scripts/CFMGridPage';
import { CFMDTOContract } from '@/scripts/services/ClientApiAuto';
import ContractDialog from './ContractDialog.vue';

@Component<ContractList>({
    components: {
        'contract-dialog': ContractDialog,
    }
})

export default class ContractList extends CFMGridPage<CFMDTOContract> {
[x: string]: any;
    entityType = "Contract";
    sorts:{field:string,direction:string}[] = [{ field:"Id", direction:"Descending" }];
    expandProperties: string[] = ["Vendor/Name"];
    headerText="";
    
    showFile:boolean = false

    commands = [
      {
        buttonOption: {iconCss:"e-icons e-preview-icon" }, title:'Göster'
      }
    ];

    async mounted(){
        this.dataStateChange(this.state);
    }
    
    commandClick(args:any)
    {
        if (args.target.children[0].classList.contains("e-preview-icon")) {
            this.showFiles(args.rowData.Id, args.rowData.Name);

        }
    }

    async showFiles(id: number, contractName: string ){
        this.selectedId = id;
        this.isDialogActive = true;
        this.showFile = true;
        this.selectedContractName = contractName;
        await this.$nextTick();
        (<ContractDialog>this.$refs.contractDialog).showDialog();
    }

    refreshGrid(){
        this.dataStateChange(this.state);
    }

    recordDoubleClick(args:any){
        this.edit(args.rowData.Id);
    }

    async delete(data: CFMDTOContract){
        const e = await swal.check(this, data.Name);
        if (e.value) {
            blockui.pageBlock(this);
            const response = await this.apiClient.contract.contractDelete({id: data.Id});
            if (response.data.IsSuccess) {
                toastr.success(this.translate('delete_successfully'));
                this.refreshGrid();
            } else {
                const errorMessage = response.data.Message ? response.data.Message : "Error";
                toastr.error(errorMessage);
            }
            blockui.unBlockPage();
        }
    }
    async edit(id: number){
        this.$router.push(
            {
                name:'Contract', 
                query:{Id: String(id)
            }
        });
    }
    async add(){
        this.$router.push(
            {
                name:'Contract',
            }
        );
    }
    async toolbarClick(args: ClickEventArgs){
        var selectedRecords = (<any>this.$refs.grid).getSelectedRecords();
        if (args.item.id === 'Edit') {
            if(selectedRecords.length > 0){
                this.edit(selectedRecords[0].Id);
            }
        }
        else if(args.item.id === 'Add'){
               this.add();
        }
        else if(args.item.id === 'Delete'){
            if(selectedRecords.length > 0){
                this.delete(selectedRecords[0]);
            }
        }
        this.toolbarClickBase(args);
    }
}
